import { type ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    Alert,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    FormLabel,
    Slide,
    Snackbar,
} from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';

import { AutocompleteMultipleInput } from '@xeris/components/forms';
import { connectionsApi } from '@xeris/pages/admin/Connections/api';

import {
    type ConnectionsTabVariantType,
    type ConnectionTableEntry,
    type UiConnectionType,
} from '../types/connectionsTypes';

import { connectionSchema, type FormData } from './common/connectionSchema';
import { getDefaultValues } from './common/getDefaultValues';
import { useGetFormData } from './common/useGetFormData';

type EditConnectionFormProps = {
    isOpen: boolean;
    handleClose: () => void;
    connection?: ConnectionTableEntry;
    connectionType: ConnectionsTabVariantType;
    uiConnectionType: UiConnectionType;
};

const EditConnectionForm = ({
    isOpen,
    handleClose,
    connection,
    connectionType,
    uiConnectionType,
}: EditConnectionFormProps): ReactElement => {
    const { t } = useTranslation('administration');

    const [isSubmitErrorOpen, setIsSubmitErrorOpen] = useState(false);

    const [updateConnection, { error, isLoading }] =
        connectionsApi.useUpdateConnectionMutation();

    const formData = useGetFormData(
        connection?.producer?.id,
        connection?.consumer?.id,
        connectionType,
        true
    );

    const { control, handleSubmit, reset } = useForm<FormData>({
        resolver: yupResolver(connectionSchema),
        defaultValues: getDefaultValues(connectionType, connection, formData),
    });

    useEffect(() => {
        if (!formData.isFetching) {
            reset(getDefaultValues(connectionType, connection, formData));
        }
    }, [connection, formData, connectionType, reset]);

    const handleSubmitConnection = async (data: FormData): Promise<void> => {
        const exportIds = data.configs.map(({ id }) => id);

        await updateConnection({
            producerId: connection?.producer.id ?? '',
            consumerId: connection?.consumer.id ?? '',
            exportIds: exportIds,
        }).unwrap();

        handleClose();
        reset();
    };

    useEffect(() => {
        if (error) {
            setIsSubmitErrorOpen(true);
        }
    }, [error, setIsSubmitErrorOpen]);

    return (
        <Drawer
            open={isOpen}
            onClose={handleClose}
            PaperProps={{
                'component': 'form',
                'aria-label': t(`connections.edit.${uiConnectionType}`),
            }}
            onSubmit={handleSubmit(handleSubmitConnection)}
        >
            <DialogTitle>
                {t(`connections.edit.${uiConnectionType}`)}
            </DialogTitle>
            <DialogContent dividers sx={{ maxWidth: '500px' }}>
                <FormLabel
                    component={'legend'}
                    error={false}
                    sx={{ paddingBottom: '20px' }}
                >
                    {t(`connections.newTradingPartner.${connectionType}`)}
                </FormLabel>
                {connectionType === 'channels' ? (
                    <AutocompleteMultipleInput
                        fieldName="configs"
                        label={t('connections.export')}
                        options={formData.exportConfigList}
                        control={control}
                        disableCloseOnSelect
                    />
                ) : (
                    <FormLabel>{t('connections.edit.noExports')}</FormLabel>
                )}
            </DialogContent>
            <DialogActions>
                {handleClose && (
                    <Button
                        variant={'text'}
                        color={'secondary'}
                        onClick={handleClose}
                    >
                        {t('common.cancel')}
                    </Button>
                )}
                {connectionType === 'channels' && (
                    <Button
                        disabled={isLoading}
                        type={'submit'}
                        variant={'contained'}
                    >
                        {t('connections.saveEditConnection')}
                    </Button>
                )}
            </DialogActions>
            <Snackbar
                open={isSubmitErrorOpen}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                TransitionComponent={Slide}
            >
                <Alert
                    severity="error"
                    elevation={2}
                    onClose={() => setIsSubmitErrorOpen(false)}
                >
                    {t('connections.error')}
                </Alert>
            </Snackbar>
        </Drawer>
    );
};

export default EditConnectionForm;
