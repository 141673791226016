import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ListSubheader, MenuItem, Select, Typography } from '@mui/material';

import { useWhoami } from '@xeris/hooks';
import { organizationsApi } from '@xeris/pages/admin/api';
import { useActiveOrganizationId } from '@xeris/pages/admin/hooks';
import { settingsActions } from '@xeris/pages/product/reducers/settingsSlice';
import { useAppDispatch } from '@xeris/reducers';
import { simpleSortBy } from '@xeris/utilities';

const OrganizationSelector = (): ReactElement | null => {
    const { t } = useTranslation('administration');
    const dispatch = useAppDispatch();

    const { data } = organizationsApi.useGetOrganizationsQuery({});

    const activeOrganizationId = useActiveOrganizationId();

    const whoami = useWhoami();

    const handleSelectOrganization = (organizationId: string): void => {
        dispatch(
            settingsActions.setImpersonatedOrganizationId(
                organizationId === whoami.organizationId ? null : organizationId
            )
        );
    };

    if (!data) {
        return null;
    }

    const sortedOrganizations = simpleSortBy(
        data.organizations,
        'name',
        'Ascending'
    );

    return (
        <Select
            data-testid="organizationSelector"
            value={
                activeOrganizationId &&
                sortedOrganizations.some(
                    (org) => org.id === activeOrganizationId
                )
                    ? activeOrganizationId
                    : ''
            }
            onChange={(organization): void => {
                handleSelectOrganization(organization.target.value);
            }}
            inputProps={{
                'aria-label': t('selectOrgToImpersonate'),
            }}
            size="small"
            sx={{
                'flexShrink': 1,
                'maxWidth': '120px',
                '.MuiOutlinedInput-notchedOutline': { borderStyle: 'none' },
                '&.MuiOutlinedInput-root:hover': {
                    backgroundColor: 'action.hover',
                },
                '&.MuiOutlinedInput-root.Mui-focused': {
                    backgroundColor: 'action.hover',
                },
            }}
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            }}
        >
            <ListSubheader sx={{ m: 0, lineHeight: '2rem' }}>
                <Typography variant={'overline'} color={'text.secondary'}>
                    {t('selectOrgToImpersonate')}
                </Typography>
            </ListSubheader>
            {sortedOrganizations.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                    {option.name}
                </MenuItem>
            ))}
        </Select>
    );
};

export default OrganizationSelector;
