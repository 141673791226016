import { type ReactElement, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Typography } from '@mui/material';

type SetupInfoProps = {
    show?: boolean;
    children: ReactNode;
};

export const Configuration = ({
    show,
    children,
}: SetupInfoProps): ReactElement | null => {
    const { t } = useTranslation('administration');

    if (!show) return null;

    return (
        <>
            <Typography variant={'h3'} sx={{ marginY: 2 }}>
                {t('exports.form.configurationSetup')}
            </Typography>
            <Typography>{t('exports.form.setupExplanation')}</Typography>
            <Divider sx={{ marginY: 2 }} />
            <Typography textAlign={'end'}>
                {t('exports.form.allowInput')}
            </Typography>
            <Box
                sx={{
                    display: 'grid',
                    gap: 1,
                    alignItems: 'center',
                    gridTemplateColumns: '1fr 42px',
                }}
            >
                {children}
            </Box>
        </>
    );
};
