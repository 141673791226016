import { baseRtkApi } from '@xeris/reducers/baseApi';

import {
    getFilteredProductsQuery,
    getFilterGroupsQuery,
    getSegmentsQuery,
} from './ecoSearchQueries';
import {
    type ApiGetFilteredProductsQuery,
    type ApiGetFilteredProductsQueryVariables,
    type ApiGetFilterGroupsQuery,
    type ApiGetFilterGroupsQueryVariables,
    type ApiGetSegmentsQuery,
    type ApiGetSegmentsQueryVariables,
} from './ecoSearchQueries.gql';

export const ecoSearchApi = baseRtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getSegments: builder.query<
            ApiGetSegmentsQuery,
            ApiGetSegmentsQueryVariables
        >({
            query: (variables) => ({
                document: getSegmentsQuery,
                variables,
            }),
        }),
        getFilters: builder.query<
            ApiGetFilterGroupsQuery,
            ApiGetFilterGroupsQueryVariables
        >({
            query: (variables) => ({
                document: getFilterGroupsQuery,
                variables,
            }),
        }),
        getFilteredProducts: builder.query<
            ApiGetFilteredProductsQuery,
            ApiGetFilteredProductsQueryVariables
        >({
            query: (variables) => ({
                document: getFilteredProductsQuery,
                variables,
            }),
        }),
    }),
});
