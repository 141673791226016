import { type TFunction } from 'i18next';
import * as yup from 'yup';

export const getOrganizationTypeList = (
    t: TFunction<'administration'>
): { id: string; label: string }[] => {
    return [
        {
            id: 'PRODUCER',
            label: t('organization.organizationType.producer'),
        },
        {
            id: 'CONSUMER',
            label: t('organization.organizationType.consumer'),
        },
        {
            id: 'PRODUCER_CONSUMER',
            label: t('organization.organizationType.producerConsumer'),
        },
    ];
};
export const DOMAIN_REGEX = /^(?!-)[a-z0-9-]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;

export const organizationSchema = yup
    .object({
        id: yup.string().required('required'),
        name: yup.string().required('required'),
        type: yup
            .string()
            .oneOf(['PRODUCER', 'CONSUMER', 'PRODUCER_CONSUMER'])
            .required('required'),
        domains: yup
            .array()
            .of(yup.string().matches(DOMAIN_REGEX).required('required')) // Using the regex to be consistent with backend
            .optional(),
        vatNumber: yup.string().optional(),
    })
    .required();

export type OrganizationFormData = yup.InferType<typeof organizationSchema>;
