export const modifyIdSet = (
    ids: string[] = [],
    toAdd?: string,
    toRemove?: string
): string[] => {
    const result = new Set(ids);

    if (toRemove) {
        result.delete(toRemove);
    }

    if (toAdd) {
        result.add(toAdd);
    }

    return [...result];
};
