import {
    type CategorizationDataset,
    type CategorizationExportConfig,
} from '@xeris/pages/exportJob/Categorization/types';
import { baseRtkApi } from '@xeris/reducers/baseApi';

import {
    categorizeProductsMutation,
    getDatasetWithCategorizedProductsQuery,
} from './categorizationQueries';
import {
    type ApiCategorizeProductsMutation,
    type ApiCategorizeProductsMutationVariables,
    type ApiGetDatasetWithCategorizedProductsQuery,
    type ApiGetDatasetWithCategorizedProductsQueryVariables,
} from './categorizationQueries.gql';
import {
    transformDatasetForCategorization,
    transformExportConfig,
} from './transformers';

export const categorizationApi = baseRtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getDatasetWithCategorizedProducts: builder.query<
            {
                dataset: CategorizationDataset | null;
                exportConfiguration: CategorizationExportConfig | null;
            },
            ApiGetDatasetWithCategorizedProductsQueryVariables
        >({
            query: (variables) => ({
                document: getDatasetWithCategorizedProductsQuery,
                variables,
            }),
            transformResponse: (
                response: ApiGetDatasetWithCategorizedProductsQuery
            ) => ({
                dataset: transformDatasetForCategorization(response.dataset),
                exportConfiguration: transformExportConfig(response.export),
            }),
            providesTags: (_, __, arg) => [{ type: 'dataset', id: arg.id }],
        }),
        categorizeProducts: builder.mutation<
            ApiCategorizeProductsMutation,
            ApiCategorizeProductsMutationVariables
        >({
            query: (variables) => ({
                document: categorizeProductsMutation,
                variables,
            }),
            invalidatesTags: () => [{ type: 'dataset' }],
        }),
    }),
});

export const { useCategorizeProductsMutation } = categorizationApi;
