import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import Tabs from './components/Tabs/Tabs';

const ProductSearchPage = (): ReactElement => {
    const { t } = useTranslation('products');

    return (
        <>
            <Typography variant={'h1'}>{t('common.products')}</Typography>
            <Tabs />
        </>
    );
};

export default ProductSearchPage;
