import type { CategorizedCardData } from '../types';

export const getNearestCategory = (
    elements: CategorizedCardData[],
    index: number
): CategorizedCardData | null => {
    let currentIndex = Math.max(0, index - 1);

    while (currentIndex >= 0) {
        if (elements[currentIndex]?.type === 'category') {
            return elements[currentIndex];
        }

        currentIndex--;
    }

    return null;
};
