import { type GenericCategoryType } from './types/EcoSearchTypes';

export const recursiveFlatter = (
    data: GenericCategoryType[],
    parentId: string
): GenericCategoryType[] => {
    return data.flatMap((it: GenericCategoryType): GenericCategoryType[] => {
        if (!it.children) {
            return [{ ...it, parentId: it.parentId }];
        }
        return [{ ...it, parentId }, ...recursiveFlatter(it.children, it.id)];
    });
};
