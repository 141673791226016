import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { settingsActions } from '@xeris/pages/product/reducers/settingsSlice';
import { useAppDispatch } from '@xeris/reducers';
import { simpleSortBy } from '@xeris/utilities';

const productKey = '__products';

type GroupProps = {
    brandId: string;
    groupTypeId: string | null;
    groupTypes: { id: string; name: string }[];
};

export const Group = ({
    groupTypeId,
    brandId,
    groupTypes,
}: GroupProps): ReactElement => {
    const { t } = useTranslation('products');
    const dispatch = useAppDispatch();

    return (
        <FormControl
            size={'small'}
            sx={{ marginBlock: 1, flexBasis: '200px', flexShrink: 1 }}
        >
            <InputLabel>{t('brand.groupBy')}</InputLabel>
            <Select
                key={brandId}
                label={t('brand.groupBy')}
                inputProps={{
                    'aria-label': t('brand.groupBy'),
                }}
                value={groupTypeId ?? productKey}
                onChange={(event) => {
                    const value = event.target.value;
                    const groupType = value === productKey ? null : value;

                    dispatch(
                        settingsActions.setGroupType({
                            brandId,
                            groupType,
                        })
                    );
                }}
            >
                {simpleSortBy(groupTypes, 'name').map((groupType) => (
                    <MenuItem key={groupType.id} value={groupType.id}>
                        {groupType.name}
                    </MenuItem>
                ))}
                <MenuItem value={productKey}>{t('brand.products')}</MenuItem>
            </Select>
        </FormControl>
    );
};
