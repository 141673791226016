import { type ReactElement, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, List } from '@mui/material';

import { type FeatureGroup } from '@xeris/pages/product/types';
import { useAppSelector } from '@xeris/reducers';

import { VariantsCounter } from '../Common';
import { useURLState } from '../hooks';
import { variantGeneratorSelectors } from '../reducer';

import { FeatureElement, FeatureGroupElement } from './Element';

type FeatureMenuProps = {
    featureGroup: FeatureGroup;
    isActive?: boolean;
};

const FeatureMenu = ({
    featureGroup,
    isActive,
}: FeatureMenuProps): ReactElement | null => {
    const { hash } = useLocation();

    const featureIds = useMemo(
        () => featureGroup.features.map(({ id }) => id),
        [featureGroup]
    );

    const { isVisible } = useAppSelector((state) =>
        variantGeneratorSelectors.featureStatuses(state, featureIds)
    );

    if (!isVisible) {
        return null;
    }

    return (
        <Box key={featureGroup.id} sx={{ paddingBottom: 2 }}>
            <FeatureGroupElement
                isActive={isActive}
                featureGroupName={featureGroup.name}
            />
            <List>
                {featureGroup.features.map((feature) => (
                    <FeatureElement
                        key={feature.id}
                        to={{ hash: feature.id }}
                        state={{
                            page: 'configure',
                            featureGroup: featureGroup.id,
                        }}
                        isActive={feature.id === hash.substring(1)}
                        feature={feature}
                    />
                ))}
            </List>
        </Box>
    );
};

type FeatureGroupMenuProps = {
    featureGroups: FeatureGroup[];
};

const FeatureGroupMenu = ({
    featureGroups,
}: FeatureGroupMenuProps): ReactElement => {
    const { featureGroupId } = useURLState();

    return (
        <List sx={{ flexGrow: 1, flexBasis: '100%' }}>
            {featureGroups.map((featureGroup) => (
                <FeatureMenu
                    key={featureGroup.id}
                    isActive={featureGroupId === featureGroup.id}
                    featureGroup={featureGroup}
                />
            ))}
        </List>
    );
};

type NavigationProps = {
    totalVariantCount: string | null;
    featureGroups: FeatureGroup[];
};

const Navigation = ({
    featureGroups,
    totalVariantCount,
}: NavigationProps): ReactElement => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '100%',
                padding: 1,
                paddingBottom: 2,
                backgroundColor: 'background.paper',
            }}
        >
            <FeatureGroupMenu featureGroups={featureGroups} />
            <VariantsCounter totalVariantCount={totalVariantCount} />
        </Box>
    );
};
export default Navigation;
