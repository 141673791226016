import { gql } from 'graphql-request';

export const searchProductsQuery = gql`
    query SearchProducts($query: String!) {
        lookup(query: $query) {
            matches {
                masterProductId: master_product_id
                isConfigurable: is_configurable
                productIds: product_ids
            }
            totalMatches: total_matches
            warnings {
                type
                message
                term
            }
        }
    }
`;

export const searchProductsDataQuery = gql`
    query GetProductSearchData(
        $masterProductIds: [String!]!
        $productIds: [String!]!
    ) {
        masterProducts: master_products(ids: $masterProductIds) {
            __typename
            id
            name
            brand {
                id
                name
            }
            lastUpdated: last_updated
            images {
                id
                objectFit
                url(sizeFormat: THUMBNAIL)
                type
                title
            }
            isConfigurable: is_configurable
        }
        products(ids: $productIds) {
            __typename
            id
            name
            lastUpdated: last_updated
            brand {
                id
                name
            }
            masterProduct: master_product {
                id
                name
            }
            images {
                id
                objectFit
                url(sizeFormat: THUMBNAIL)
                type
                title
            }
        }
    }
`;
