import type { ReactElement } from 'react';

import { Box } from '@mui/material';

import { Skeleton } from '@xeris/components';

export const DatasetPageLoader = (): ReactElement => {
    const productRowPlaceholderList = Array.from(Array(12).keys());
    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    gap: 4,
                    alignItems: 'center',
                    justifyItems: 'space-between',
                }}
            >
                <Skeleton variant={'rounded'} width={400} height={24} />
                <Skeleton variant={'rounded'} width={100} height={36} />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '12px 0',
                }}
            >
                <Skeleton variant={'rounded'} width={160} height={42} />
                <Skeleton variant={'rounded'} width={160} height={42} />
            </Box>
            <Box sx={{ width: '100%' }}>
                {productRowPlaceholderList.map((index) => (
                    <Skeleton
                        variant={'rounded'}
                        key={index}
                        height={42}
                        sx={{ margin: '16px 0' }}
                    />
                ))}
            </Box>
        </Box>
    );
};
