import { type ReactElement } from 'react';

import { Typography } from '@mui/material';

import { type SpecificationType } from '@xeris/pages/product/types';

import './index.scss';

type SpecificationsProps = {
    data: SpecificationType[];
};

const Specifications = ({
    data: specificationList,
}: SpecificationsProps): ReactElement => {
    return (
        <dl className="product-sheet-wrapper datasheet-list-col">
            {specificationList.map((specification) => (
                <div key={specification.key.join('-')}>
                    <dt>
                        <Typography
                            variant={'label'}
                            sx={{ marginBottom: 0.2 }}
                        >
                            {specification.key}
                        </Typography>
                    </dt>
                    <dd>
                        <Typography variant={'body2'}>
                            {specification.value}
                        </Typography>
                    </dd>
                </div>
            ))}
        </dl>
    );
};

export default Specifications;
