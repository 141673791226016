import { type ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';

import ClassificationSelector from './ClassificationSelector/ClassificationSelector';
import ProductBrowser from './ProductBrowser/ProductBrowser';
import { ecoSearchHooks } from './hooks';

const EcoSearchPage = (): ReactElement => {
    ecoSearchHooks.api.queries.useGetSegmentsQuery({});

    ecoSearchHooks.api.queries.useGetFiltersQuery({});

    return (
        <Routes>
            <Route
                path=":classificationId/Products"
                element={<ProductBrowser />}
            />
            <Route path={''} element={<ClassificationSelector />} />
            <Route
                path={'Classifications'}
                element={<ClassificationSelector />}
            />
        </Routes>
    );
};

export default EcoSearchPage;
