import * as yup from 'yup';

import { type ApiLanguage } from '@xeris/types';

const defaultValueString = <T extends string = string>(): yup.StringSchema<
    T | undefined
> =>
    yup.string<T>().when('required', {
        is: true,
        then: (schema) => schema.required('required'),
        otherwise: (schema) => schema.nullable(),
    });

const defaultValueNumber = (): yup.NumberSchema =>
    yup
        .number() // Empty is string is not a valid number, so transform it to a nullish value to mean not set
        .transform((val, orig) => (orig == '' ? undefined : val))
        .when('required', {
            is: true,
            then: (schema) => schema.required('required'),
            otherwise: (schema) => schema.nullable(),
        });

export const exportJobSchema = yup
    .object({
        dataset: yup.string().required('required'),
        type: yup.string().required('required'),
        options: yup
            .array(
                yup
                    .object({
                        boolean: yup
                            .object({
                                key: yup.string().required(),
                                required: yup.boolean().required(),
                                // Booleans can not be null, so there is no need to check
                                defaultValue: yup.boolean().required(),
                            })
                            .nullable(),
                        int: yup
                            .object({
                                key: yup.string().required(),
                                required: yup.boolean().required(),
                                defaultValue: defaultValueNumber(),
                            })
                            .nullable(),
                        select: yup
                            .object({
                                key: yup.string().required(),
                                required: yup.boolean().required(),
                                defaultValue: defaultValueString(),
                            })
                            .nullable(),
                        string: yup
                            .object({
                                key: yup.string().required(),
                                required: yup.boolean().required(),
                                defaultValue: defaultValueString(),
                            })
                            .nullable(),
                        language: yup
                            .object({
                                key: yup.string().required(),
                                required: yup.boolean().required(),
                                defaultValue: defaultValueString<ApiLanguage>(),
                            })
                            .nullable(),
                    })
                    .required()
            )
            .required(),
    })
    .required();

export type ExportJobFormData = yup.InferType<typeof exportJobSchema>;
