import { gql } from 'graphql-request';

export const whoamiQuery = gql`
    query Whoami {
        whoami {
            id
            name
            username
            phone
            organization {
                id
                name
            }
            language
            securityLevel
        }
    }
`;

export const updateUserLanguageMutation = gql`
    mutation UpdateUserProfile($language: String!) {
        user {
            updateUserProfile(language: $language) {
                id
                language
            }
        }
    }
`;
