import { type ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import ExportJobList from '@xeris/pages/exportJob/ExportJobList/ExportJobList';

import { ExportJobDetails } from './ExportJob/ExportJob';
import Categorization from './Categorization';

export const ExportJobPage = (): ReactElement => {
    return (
        <Routes>
            <Route
                path={'Categorization/:datasetId/:exportId'}
                element={<Categorization />}
            />
            <Route
                path={'ExportJob/:exportJobId'}
                element={<ExportJobDetails />}
            />
            <Route index element={<ExportJobList />} />
            <Route path="*" element={<Navigate to={''} replace />} />
        </Routes>
    );
};
