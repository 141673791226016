import { type ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { BasicOptionCardButtons } from '@xeris/components/variantGenerator';
import { type BasicOption } from '@xeris/pages/product/types';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';

import { selectOptions, variantGeneratorSelectors } from '../../../reducer';

type OptionButtonsProps = {
    featureId: string;
    option: BasicOption;
    handleSelectPreview: () => void;
};

export const OptionCardButtons = ({
    featureId,
    option,
    handleSelectPreview,
}: OptionButtonsProps): ReactElement => {
    const { t } = useTranslation('product');
    const dispatch = useAppDispatch();

    const { isPreviewed, isSelected, isSelectable, isPreviewable } =
        useAppSelector((state) =>
            variantGeneratorSelectors.optionStatus(state, featureId, option.id)
        );

    const handleToggleOption = useCallback(() => {
        dispatch(
            selectOptions({
                optionIds: [option.id],
                featureId: featureId,
                newValue: !isSelected,
            })
        );
    }, [dispatch, featureId, isSelected, option.id]);

    return (
        <BasicOptionCardButtons
            isPreviewed={isPreviewed}
            isSelected={isSelected}
            previewDisabled={!isPreviewable}
            selectDisabled={!isSelectable && !isSelected}
            handleSelectPreview={handleSelectPreview}
            handleSelect={handleToggleOption}
            selectDisabledTooltip={t(
                'variantGenerator.optionNotAvailableSelect'
            )}
        />
    );
};
