import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import UserList from './UserList/UserList';

const Users = (): ReactElement => {
    const { t } = useTranslation('administration');

    return (
        <>
            <Typography variant={'h1'} gutterBottom>
                {t('users.users')}
            </Typography>

            <UserList />
        </>
    );
};

export default Users;
