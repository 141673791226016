import { gql } from 'graphql-request';

export const baseProductEntityWithoutImagesFields = gql`
    fragment BaseProductEntityWithoutImagesFields on ProductData {
        __typename
        id
        name
        description
        lastUpdated: last_updated
        externalId: external_id
        brand {
            id
            name
        }
        markets {
            id
            name
            externalId: external_id
        }
        idNumbers: id_numbers {
            name
            value
            type
        }
    }
`;

export const baseProductEntityWithImagesFields = gql`
    fragment BaseProductEntityFields on ProductData {
        ...BaseProductEntityWithoutImagesFields
        images {
            id
            title
            objectFit
            url(sizeFormat: THUMBNAIL)
            type
        }
    }
    ${baseProductEntityWithoutImagesFields}
`;

export const productEntityDataFields = gql`
    fragment ProductEntityDataFields on ProductData {
        images {
            title
            type
            objectFit
            url(sizeFormat: FULLSIZE)
            id
        }
        documents {
            id
            fileName
            title
            type {
                id
                label
            }
            url
        }
        models {
            id
            fileName
            type
            title
            url
        }
        specifications {
            name
            value
            type
        }
        certifications {
            name
        }
        descriptions {
            id
            name
            value
        }
        productTexts: product_texts {
            id
            name
            text
        }
        brick {
            name
            id
            attributes {
                name
                values {
                    name
                    id
                }
            }
        }
        classifications {
            name
            value
            type
        }
        physicalMeassurements: physical_measurements {
            name
            formattedValue: formatted_value
            unit
            isConverted: converted
            value {
                __typename
                ... on SingleValue {
                    value
                }
                ... on RangeValue {
                    to
                    from
                }
            }
        }
        consumerUnit: consumer_unit {
            dimensions {
                length(unit: MM)
                height(unit: MM)
                width(unit: MM)
                weight
                volume
            }
            quantityWithUnit
        }
        distributionUnit: distribution_unit {
            dimensions {
                length(unit: MM)
                height(unit: MM)
                width(unit: MM)
                weight
                volume
            }
            quantityWithUnit
        }
        transportationUnit: transportation_unit {
            dimensions {
                length(unit: MM)
                height(unit: MM)
                width(unit: MM)
                weight
                volume
            }
            quantityWithUnit
        }
        embeddedMedia: embedded_media {
            reference
            type
        }
        epdData: epd_data {
            id
            externalId: external_id
            functionalUnit: functional_unit
            amountOfFunctionalUnit: amount_of_functional_unit
            globalWarmingPotential: global_warming_potential
            normalizedGlobalWarmingPotential: normalized_global_warming_potential
            validFromYear: valid_from_year
            validThroughYear: valid_through_year
        }
    }
`;
