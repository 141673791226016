import { gql } from 'graphql-request';

export const sharedWithFields = gql`
    fragment SharedWithFields on DatasetSharing {
        privilege
        user {
            id
            name
            username
            organization {
                id
                name
                type
            }
        }
    }
`;

export const baseDatasetFields = gql`
    fragment BaseDatasetFields on DataSet {
        id
        name
        description
        created
        lastModified: last_modified
        lastProductUpdate: last_product_update
        lastExported: last_exported
        previewImages: preview_images(limit: 1)
        brands {
            id
            name
        }
        totalCount: total_count
        missingProductCount: missing_product_count
        owner {
            id
            name
            username
            organization {
                id
                name
                type
            }
        }
        privilege
        sharedWith: shared_with {
            ...SharedWithFields
        }
    }
    ${sharedWithFields}
`;

export const baseSelectedProductDataFields = gql`
    fragment BaseSelectedProductDataFields on DataSet {
        selectedProductData: selected_product_data {
            cursor
            nodeList: nodes {
                __typename
                id
                ... on SelectedMasterProduct {
                    configurationSets: configuration_sets {
                        id
                        name
                    }
                }
            }
        }
    }
`;
