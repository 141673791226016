/*
 * Get the first and last initials from a space-delimited name string
 */
export const getInitialsFromName = (name?: string): string => {
    const trimmedName = (name || '').trim();

    const names = trimmedName.split(' ').filter(Boolean);

    if (names.length === 0) {
        return '';
    }

    if (names.length === 1) {
        return names[0].substring(0, 2).toUpperCase();
    }

    const first = names[0].charAt(0).toUpperCase();
    const last = names[names.length - 1].charAt(0).toUpperCase();

    return `${first}${last}`;
};
