import { type ReactElement, type ReactNode } from 'react';

import { StyledEngineProvider, ThemeProvider } from '@mui/material';

import { defaultTheme } from './theme';

type RootProps = {
    children?: ReactNode;
};

const Root = ({ children }: RootProps): ReactElement => {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
        </StyledEngineProvider>
    );
};

export default Root;
