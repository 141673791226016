import type {
    CategorizationMasterProduct,
    CategorizationProduct,
} from '../../types';
import type { GroupCardData, ProductCardData } from '../types';

type FormattedProducts = {
    products: ProductCardData[];
    groups: Map<string, GroupCardData>;
    categorized: { [key: string]: string[] };
};

export const formatProducts = (
    masterProducts: CategorizationMasterProduct[],
    products: CategorizationProduct[]
): FormattedProducts => {
    const groups = new Map<string, GroupCardData>();

    const categorized: { [key: string]: string[] } = {};

    const masterProductList: ProductCardData[] = masterProducts.map(
        (masterProduct) => {
            if (masterProduct.categories?.length) {
                categorized[masterProduct.id] = masterProduct.categories.map(
                    (category) => category.id
                );
            }

            const groupId = masterProduct.groups?.[0]?.id ?? 'uncategorized';
            const groupName =
                masterProduct.groups?.[0]?.name ?? 'Uncategorized';

            const brandPath = masterProduct.brand.name + masterProduct.brand.id;
            const groupPath = [brandPath, groupName + groupId].join('//b_');

            if (!groups.has(brandPath)) {
                groups.set(brandPath, {
                    type: 'group',
                    id: masterProduct.brand.id,
                    name: masterProduct.brand.name,
                    path: brandPath,
                    indentationLevel: 0,
                    productCount: 0,
                });
            }

            if (!groups.has(groupPath)) {
                groups.set(groupPath, {
                    type: 'group',
                    id: groupId,
                    name: masterProduct.groups?.[0]?.name ?? 'Uncategorized',
                    path: groupPath,
                    indentationLevel: 1,
                    productCount: 0,
                });
            }

            return {
                type: 'product',
                id: masterProduct.id,
                name: masterProduct.name,
                image: masterProduct.images?.[0],
                path: [
                    masterProduct.brand.name + masterProduct.brand.id,
                    groupName + groupId,
                    masterProduct.name + masterProduct.id,
                ].join('//b_'),
                indentationLevel: 2,
            };
        }
    );

    const productList: ProductCardData[] = products.map((product) => {
        if (product.categories?.length) {
            categorized[product.id] = product.categories.map(
                (category) => category.id
            );
        }

        const groupId = product.groups?.[0]?.id ?? 'ungrouped';
        const groupName = product.groups?.[0]?.name ?? 'Uncategorized';

        const brandPath = product.brand.name + product.brand.id;
        const groupPath = [brandPath, groupName + groupId].join('//b_');
        const masterProductPath = [
            groupPath,
            product.masterProduct.name + product.masterProduct.id,
        ].join('//b_');

        if (!groups.has(brandPath)) {
            groups.set(brandPath, {
                type: 'group',
                id: product.brand.id,
                name: product.brand.name,
                path: brandPath,
                indentationLevel: 0,
                productCount: 0,
            });
        }

        if (!groups.has(groupPath)) {
            groups.set(groupPath, {
                type: 'group',
                id: groupId,
                name: product.groups?.[0]?.name ?? 'Ungrouped',
                path: groupPath,
                indentationLevel: 1,
                productCount: 0,
            });
        }

        if (!groups.has(masterProductPath)) {
            groups.set(masterProductPath, {
                type: 'group',
                id: product.masterProduct.id,
                name: product.masterProduct.name,
                path: masterProductPath,
                indentationLevel: 2,
                productCount: 0,
            });
        }

        return {
            type: 'product',
            id: product.id,
            name: product.name,
            image: product.images?.[0],
            path: [masterProductPath, product.name + product.id].join('//a_'),
            indentationLevel: 3,
        };
    });

    return {
        products: [...masterProductList, ...productList],
        categorized: categorized,
        groups: groups,
    };
};
