import { baseRtkApi } from '@xeris/reducers/baseApi';

import {
    addOrganizationQuery,
    deleteOrganizationQuery,
    editOrganizationQuery,
    getOrganizationsQuery,
    getUserOrganizationQuery,
} from './organizationsQueries';
import {
    type ApiAddOrganizationMutation,
    type ApiAddOrganizationMutationVariables,
    type ApiDeleteOrganizationMutation,
    type ApiDeleteOrganizationMutationVariables,
    type ApiEditOrganizationMutation,
    type ApiEditOrganizationMutationVariables,
    type ApiGetOrganizationsQuery,
    type ApiGetOrganizationsQueryVariables,
    type ApiGetUserOrganizationQuery,
    type ApiGetUserOrganizationQueryVariables,
} from './organizationsQueries.gql';

export const organizationsApi = baseRtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getOrganizations: builder.query<
            ApiGetOrganizationsQuery,
            ApiGetOrganizationsQueryVariables
        >({
            query: (variables) => ({
                document: getOrganizationsQuery,
                variables,
            }),
            providesTags: ['organizations'],
        }),
        getUserOrganization: builder.query<
            ApiGetUserOrganizationQuery,
            ApiGetUserOrganizationQueryVariables
        >({
            query: (variables) => ({
                document: getUserOrganizationQuery,
                variables,
            }),
            providesTags: ['organizations'],
        }),
        addOrganization: builder.mutation<
            ApiAddOrganizationMutation,
            ApiAddOrganizationMutationVariables
        >({
            query: (variables) => ({
                document: addOrganizationQuery,
                variables: {
                    ...variables,
                    domains:
                        variables.domains?.map((domain: string): string =>
                            domain.toLowerCase()
                        ) ?? null,
                },
            }),
            invalidatesTags: ['organizations'],
        }),
        editOrganization: builder.mutation<
            ApiEditOrganizationMutation,
            ApiEditOrganizationMutationVariables
        >({
            query: (variables) => ({
                document: editOrganizationQuery,
                variables: {
                    ...variables,
                    domains:
                        variables.domains?.map((domain: string): string =>
                            domain.toLowerCase()
                        ) ?? null,
                },
            }),
            invalidatesTags: ['organizations'],
        }),
        deleteOrganization: builder.mutation<
            ApiDeleteOrganizationMutation,
            ApiDeleteOrganizationMutationVariables
        >({
            query: (variables) => ({
                document: deleteOrganizationQuery,
                variables,
            }),
            invalidatesTags: ['organizations'],
        }),
    }),
});
