import { gql } from 'graphql-request';

export const getBrandAccessPolicyQuery = gql`
    query GetBrandAccessPolicy(
        $producerId: String!
        $consumerId: String!
        $brandId: String!
    ) {
        brand(id: $brandId) {
            id
            name
            markets {
                id
                name
                externalId: external_id
            }
            groupTypes: group_types {
                id
                name
                groups {
                    id
                    name
                }
            }
            productCounts: product_counts {
                masterProducts: master_products
            }
        }
        tradingPartner: organization(id: $consumerId) {
            id
            name
        }
        brandAccessPolicy(
            producerId: $producerId
            consumerId: $consumerId
            brandId: $brandId
        ) {
            brand {
                id
            }
            productCount
            matchingProducts {
                id
                name
                products {
                    id
                    name
                    idNumbers: id_numbers {
                        value
                    }
                    markets {
                        id
                    }
                    groups {
                        id
                        type {
                            id
                        }
                    }
                    images {
                        id
                        title
                        url(sizeFormat: THUMBNAIL)
                        objectFit
                    }
                }
                idNumbers: id_numbers {
                    value
                }
                isConfigurable: is_configurable
                images {
                    id
                    title
                    url(sizeFormat: THUMBNAIL)
                    objectFit
                }
                markets {
                    id
                }
                groups {
                    id
                    type {
                        id
                    }
                }
            }
            rule {
                __typename
                id
                action
                ... on BrandAccessRule {
                    targetBrand
                }
                ... on BrandGroupMarketAccessRule {
                    targetBrand
                    filterMarkets
                    filterGroups {
                        groupType
                        groups
                    }
                }
                ... on BrandProductAccessRule {
                    targetBrand
                    filterProducts
                }
                ... on MarketAccessRule {
                    targetMarket
                }
                ... on ProductGroupAccessRule {
                    targetGroup
                }
                ... on OrganizationAccessRule {
                    targetOrganization
                }
            }
        }
    }
`;

export const getBrandAccessPoliciesQuery = gql`
    query GetBrandAccessPolicies($producerId: String!, $consumerId: String!) {
        tradingPartner: organization(id: $consumerId) {
            name
        }
        brandAccessPolicies(producerId: $producerId, consumerId: $consumerId) {
            brand {
                id
            }
            productCount
            rule {
                __typename
                id
                action
                ... on BrandAccessRule {
                    targetBrand
                }
                ... on BrandGroupMarketAccessRule {
                    targetBrand
                    filterMarkets
                    filterGroups {
                        groupType
                        groups
                    }
                }
                ... on BrandProductAccessRule {
                    targetBrand
                    filterProducts
                }
                ... on MarketAccessRule {
                    targetMarket
                }
                ... on ProductGroupAccessRule {
                    targetGroup
                }
                ... on OrganizationAccessRule {
                    targetOrganization
                }
            }
        }
    }
`;

export const setBrandAccessMutation = gql`
    mutation SetBrandAccess(
        $organizationId: String!
        $rule: InputAccessRule!
        $ruleIdsToDelete: [String!]!
        $deleteRules: Boolean!
        $addRule: Boolean!
    ) {
        delete: access @include(if: $deleteRules) {
            deleteRules(ruleIds: $ruleIdsToDelete)
        }
        create: access @include(if: $addRule) {
            addRule(organizationId: $organizationId, rule: $rule)
        }
    }
`;
