import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    Typography,
} from '@mui/material';

type NoDomainsProps = {
    isOpen: boolean;
    handleClose: () => void;
};

export const NoDomainsDrawer = ({
    isOpen,
    handleClose,
}: NoDomainsProps): ReactElement => {
    const { t } = useTranslation('administration');

    return (
        <Drawer open={isOpen} onClose={handleClose}>
            <DialogTitle>{t(`users.inviteNewUserShort`)}</DialogTitle>
            <DialogContent dividers sx={{ maxWidth: '500px' }}>
                <Typography>
                    {t('users.noDomains')}
                    <a
                        href="mailto:support@xeris.no"
                        style={{
                            color: 'blue',
                            textDecoration: 'underline',
                        }}
                    >
                        support@xeris.no
                    </a>
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color={'secondary'}>
                    {t('common.cancel')}
                </Button>
            </DialogActions>
        </Drawer>
    );
};
