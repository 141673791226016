import { type ReactElement, useCallback } from 'react';
import { DragDropContext, type DropResult } from 'react-beautiful-dnd';

import { Box } from '@mui/material';

import { categorizeProducts } from '@xeris/pages/exportJob/Categorization/reducers/categorizationSlice';
import { useAppDispatch } from '@xeris/reducers';

import CategoriesSection from './CategoriesSection/CategoriesSection';
import Header from './Header/Header';
import ProductSection from './ProductSection/ProductSection';

type CategorizationContentProps = {
    isFetching: boolean;
};

const CategorizationContent = ({
    isFetching,
}: CategorizationContentProps): ReactElement => {
    const dispatch = useAppDispatch();

    const handleDragEnd = useCallback(
        (data: DropResult) => {
            dispatch(categorizeProducts(data));
        },
        [dispatch]
    );

    return (
        <Box
            sx={{
                maxWidth: '1440px',
                margin: '0 auto',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Header />
            <Box sx={{ flexGrow: 1 }}>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            gap: ' 36px',
                            height: '100%',
                        }}
                    >
                        <ProductSection isFetching={isFetching} />
                        <CategoriesSection isFetching={isFetching} />
                    </Box>
                </DragDropContext>
            </Box>
        </Box>
    );
};

export default CategorizationContent;
