import { type ReactElement } from 'react';
import { type Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Chip } from '@mui/material';

import { CheckboxInput, SelectInput, TextInput } from '@xeris/components/forms';

import { type Option } from '../types';
import { type ExportJobFormData } from '../utilities/exportJobSchema';

type LabelProps = {
    option: Option;
};

const Label = ({ option }: LabelProps): ReactElement => {
    const { t } = useTranslation('administration');

    return (
        <Box sx={{ display: 'flex', gap: 1 }}>
            {option.label}
            {option.required && (
                <Chip
                    label={t('exports.form.required')}
                    size={'small'}
                    sx={{ height: '18px' }}
                />
            )}
        </Box>
    );
};

type OptionFieldProps = {
    option: Option;
    control: Control<ExportJobFormData>;
    index: number;
};

export const OptionField = ({
    option,
    control,
    index,
}: OptionFieldProps): ReactElement | null => {
    if (option.__typename === 'LanguageConfigurationOption') {
        return (
            <SelectInput
                key={option.key}
                fieldName={`options.${index}.language.defaultValue`}
                control={control}
                showError
                optionLabelField={'language'}
                label={<Label option={option} />}
                options={option.languages ?? []}
            />
        );
    }

    if (option.__typename === 'SelectConfigurationOption') {
        return (
            <SelectInput
                key={option.key}
                fieldName={`options.${index}.select.defaultValue`}
                control={control}
                showError
                label={<Label option={option} />}
                options={
                    option.values?.map((value) => ({
                        id: value.value,
                        label: value.label,
                    })) ?? []
                }
            />
        );
    }

    if (option.__typename === 'StringConfigurationOption') {
        return (
            <TextInput
                key={option.key}
                fieldName={`options.${index}.string.defaultValue`}
                control={control}
                showError
                label={<Label option={option} />}
                multiline
            />
        );
    }

    if (option.__typename === 'IntConfigurationOption') {
        return (
            <TextInput
                key={option.key}
                fieldName={`options.${index}.int.defaultValue`}
                control={control}
                showError
                label={<Label option={option} />}
                type={'number'}
            />
        );
    }

    if (option.__typename === 'BooleanConfigurationOption') {
        return (
            <CheckboxInput
                key={option.key}
                fieldName={`options.${index}.boolean.defaultValue`}
                control={control}
                label={<Label option={option} />}
            />
        );
    }

    return null;
};
