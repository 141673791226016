import { gql } from 'graphql-request';

export const userQueryFields = gql`
    fragment UserQueryFields on User {
        id
        username
        name
        phone
        registrationStatus
        securityLevel
        organization {
            id
        }
        language
    }
`;

export const getUsersQuery = gql`
    query GetUsers($organizationId: String!) {
        users(organization: $organizationId) {
            ...UserQueryFields
        }
    }
    ${userQueryFields}
`;

export const addUserMutation = gql`
    mutation AddUser(
        $username: String!
        $securityLevel: SecurityLevel!
        $organizationId: String!
        $language: String!
    ) {
        user {
            create(
                user: {
                    username: $username
                    securityLevel: $securityLevel
                    organizationId: $organizationId
                    language: $language
                }
            ) {
                ...UserQueryFields
            }
        }
    }
    ${userQueryFields}
`;

export const deleteUserMutation = gql`
    mutation DeleteUser($username: String!) {
        user {
            delete(username: $username) {
                ...UserQueryFields
            }
        }
    }
    ${userQueryFields}
`;
