import { type ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';

import { MutationSnackbars } from '@xeris/components';
import { FormDrawer, SelectInput, TextInput } from '@xeris/components/forms';
import { useWhoami } from '@xeris/hooks';
import { userApi, userVerificationApi } from '@xeris/pages/admin/api';
import { useActiveOrganization } from '@xeris/pages/admin/hooks';

import {
    type InviteUserFormData,
    inviteUserSchema,
} from './common/inviteUserSchema';
import {
    getDefaultLanguage,
    getRoleList,
    supportedLanguageList,
    validateDomain,
} from './common/utilities';
import { NoDomainsDrawer } from './components/NoDomainsDrawer';

type InviteUserFormProps = {
    isOpen: boolean;
    handleClose: () => void;
};

export const InviteUserForm = ({
    isOpen,
    handleClose,
}: InviteUserFormProps): ReactElement => {
    const { t, i18n } = useTranslation('administration');
    const { isSuperAdmin } = useWhoami();
    const activeOrganization = useActiveOrganization();

    const [addUserMutation, addUserResult] = userApi.useAddUserMutation();
    const [inviteUserMutation, inviteUserResult] =
        userVerificationApi.useInviteUserMutation();

    const { handleSubmit, control, reset, setError } =
        useForm<InviteUserFormData>({
            resolver: yupResolver(inviteUserSchema),
            defaultValues: {
                username: '',
                securityLevel: 'USER',
                invitationLanguage: getDefaultLanguage(i18n.language),
                message: '',
            },
        });

    const handleSubmitUser = async (
        data: InviteUserFormData
    ): Promise<void> => {
        if (!activeOrganization) {
            return;
        }

        const isValidEmail = validateDomain(
            activeOrganization.domains,
            data.username,
            isSuperAdmin
        );

        if (!isValidEmail) {
            return setError('username', {
                type: 'domainNotAllowed',
                message: activeOrganization.domains.join(', '),
            });
        }

        const user = {
            username: data.username,
            securityLevel: data.securityLevel,
            organizationId: activeOrganization.id,
            language: data.invitationLanguage,
        };

        await addUserMutation(user).unwrap();
        await inviteUserMutation({
            username: data.username,
            custom_invite_text: data.message,
        }).unwrap();

        reset();
        handleClose();
    };

    if (!(activeOrganization?.domains.length || isSuperAdmin)) {
        return <NoDomainsDrawer isOpen={isOpen} handleClose={handleClose} />;
    }

    return (
        <>
            <FormDrawer
                open={isOpen}
                onClose={handleClose}
                onSubmit={handleSubmit(handleSubmitUser)}
                title={t(`users.inviteNewUserShort`)}
                isLoading={
                    addUserResult.isLoading || inviteUserResult.isLoading
                }
                saveText={t('users.sendInvite')}
                cancelText={t('common.cancel')}
            >
                <TextInput
                    label={t('users.userEmail')}
                    fieldName={'username'}
                    control={control}
                    type={'email'}
                    required
                    showError
                    autoFocus
                    placeholder={t('users.emailPlaceholder')}
                />
                <SelectInput
                    fieldName={'securityLevel'}
                    label={t('users.role')}
                    options={getRoleList(t)}
                    control={control}
                />
                <SelectInput
                    fieldName={'invitationLanguage'}
                    label={t('users.invitationLanguage')}
                    options={supportedLanguageList}
                    control={control}
                />
                <TextInput
                    fieldName={'message'}
                    label={t('users.customInviteText')}
                    control={control}
                    placeholder={t('users.messagePlaceholder')}
                    multiline
                    minRows={'4'}
                />
            </FormDrawer>
            <MutationSnackbars
                isSuccess={
                    inviteUserResult.isSuccess || addUserResult.isSuccess
                }
                successText={t('users.createUserSuccess')}
                isError={inviteUserResult.isError || addUserResult.isError}
                errorText={t('users.errorOnInvite')}
            />
        </>
    );
};
