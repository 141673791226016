import { baseRtkApi } from '@xeris/reducers/baseApi';

import { updateUserLanguageMutation, whoamiQuery } from './userQueries';
import {
    type ApiUpdateUserProfileMutation,
    type ApiUpdateUserProfileMutationVariables,
    type ApiWhoamiQuery,
} from './userQueries.gql';

export const userApi = baseRtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getWhoami: builder.query<ApiWhoamiQuery, { username: string }>({
            query: (variables) => ({
                document: whoamiQuery,
                variables,
            }),
        }),
        updateUserLanguage: builder.mutation<
            ApiUpdateUserProfileMutation,
            ApiUpdateUserProfileMutationVariables
        >({
            query: (variables) => ({
                document: updateUserLanguageMutation,
                variables,
            }),
        }),
    }),
});
