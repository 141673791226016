import { lazy, type ReactElement, Suspense } from 'react';

import { SvgIcon, type SvgIconProps } from '@mui/material';

import HelpIcon from '@xeris/assets/icons/help.svg';

export type IconProps = SvgIconProps & {
    icon: string;
};

const Icon = ({ icon, ...otherProps }: IconProps): ReactElement => {
    const IconComponent = lazy(async () => {
        try {
            return await import(`../../../assets/icons/${icon}.svg`);
        } catch (error) {
            console.error(error);

            return HelpIcon;
        }
    });

    return (
        <SvgIcon {...otherProps}>
            <Suspense fallback={<svg />}>
                <IconComponent />
            </Suspense>
        </SvgIcon>
    );
};

export default Icon;
