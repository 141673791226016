import { type ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';

import MasterProducts from '@xeris/pages/product/MasterProducts/MasterProducts';

import MasterProduct from './MasterProduct/MasterProduct';
import PageWrapper from './PageWrapper/PageWrapper';
import Product from './Product/Product';
import ProductSearchPage from './ProductSearch/ProductSearchPage';
import VariantGenerator from './variantGenerator/VariantGenerator';

const BrandRouters = (): ReactElement => {
    return (
        <PageWrapper>
            <Routes>
                <Route
                    path="/MasterProducts/:masterProductId/Product/:productId"
                    element={<Product />}
                />
                <Route
                    path="/MasterProducts/:masterProductId/Configure/*"
                    element={
                        <Routes>
                            {/* Add a product to a dataset */}
                            <Route path={'/'} element={<VariantGenerator />} />
                            {/* Add a configuration set to a product in a dataset */}
                            <Route
                                path={'/:datasetId'}
                                element={<VariantGenerator />}
                            />
                            {/* Edit a configuration set */}
                            <Route
                                path={'/:datasetId/:configurationSetId'}
                                element={<VariantGenerator />}
                            />
                        </Routes>
                    }
                ></Route>
                <Route
                    path="/MasterProducts/:masterProductId"
                    element={<MasterProduct />}
                />
                <Route path={'/*'} element={<MasterProducts />} />
            </Routes>
        </PageWrapper>
    );
};

const ProductPage = (): ReactElement => {
    return (
        <Routes>
            <Route path=":brandId/*" element={<BrandRouters />} />
            <Route path="" element={<ProductSearchPage />} />
        </Routes>
    );
};

export default ProductPage;
