import { type ReactElement } from 'react';

import { Typography } from '@mui/material';

import { type MarketType } from '@xeris/pages/product/types';
import { marketSortFunction } from '@xeris/pages/product/utilities';

import './index.scss';

type MarketsProps = {
    data: MarketType[];
};

const Markets = ({ data: marketList }: MarketsProps): ReactElement => {
    return (
        <div className="datasheet-content-wrapper">
            <Typography variant={'body1'}>
                {[...marketList]
                    .sort(marketSortFunction)
                    .map((data) => data.name)
                    .join(', ')}
            </Typography>
        </div>
    );
};

export default Markets;
