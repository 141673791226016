import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { FormDialog } from '@xeris/components/forms';
import { datasetsApi } from '@xeris/pages/dataset/api';

type DeleteDatasetFormProps = {
    isOpen: boolean;
    dataset: {
        id: string;
        name: string;
    };
    handleClose: () => void;
};

export const DeleteDatasetForm = ({
    isOpen,
    dataset,
    handleClose,
}: DeleteDatasetFormProps): ReactElement => {
    const { t } = useTranslation('dataset');

    const [deleteDatasetMutation, { isLoading }] =
        datasetsApi.useDeleteDatasetMutation();

    return (
        <>
            <FormDialog
                open={isOpen}
                onClose={handleClose}
                onSubmit={(event): void => {
                    event.preventDefault();
                    deleteDatasetMutation({ id: dataset.id });
                    handleClose();
                }}
                title={t('datasetPopUp.deleteDateset')}
                isLoading={isLoading}
                cancelText={t('datasetPopUp.cancel')}
                saveText={t('datasetPopUp.delete')}
                buttonProps={{ color: 'error' }}
            >
                {t('datasetPopUp.deleteDatasetWarning', {
                    datasetName: dataset?.name,
                })}
            </FormDialog>
        </>
    );
};
