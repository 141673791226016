import { type ApiGetFilteredProductsQuery } from '@xeris/pages/ecoSearch/api/ecoSearchQueries.gql';

export type FilterTypesType =
    | 'show all'
    | 'segment'
    | 'family'
    | 'class'
    | 'brick';

export type CategoryTypesType =
    | 'gpc_segment'
    | 'gpc_class'
    | 'gpc_family'
    | 'gpc_brick';

export const typeLookup: Record<CategoryTypesType, string> = {
    gpc_segment: 'Segment',
    gpc_class: 'Class',
    gpc_family: 'Family',
    gpc_brick: 'Brick',
};

export type CategoryType = {
    id: string;
    name: string;
    type: CategoryTypesType;
    children?: CategoryType[] | null;
    description?: string;
    matches?: boolean;
    childrenMatches?: boolean;
};

type CommonFilterType = {
    id: string;
    name: string;
    description: string | null;
    isActive: boolean;
};

export type BooleanFilterType = CommonFilterType & {
    type: 'Boolean';
    value: boolean | null;
};

export type IntegerFilterType = CommonFilterType & {
    type: 'Int';
    value: number | null;
};

export type RangeFilterType = CommonFilterType & {
    type: 'Range';
    value: { from: number; to: number } | null;
};

export type FilterType =
    | BooleanFilterType
    | IntegerFilterType
    | RangeFilterType;

export type GenericCategoryType = {
    children?: GenericCategoryType[] | null;
    id: string;
    parentId?: string;
    name: string;
    type: CategoryTypesType;
};

export type EcoSearchSliceType = {
    gpcSegments: CategoryType[];
    items: Record<string, GenericCategoryType | undefined>;
    filteredProducts: ApiGetFilteredProductsQuery['eco']['query']['products'];
    productCounts: {
        totalProductCount: number;
        filteredProductCount: number;
    };
    filters: FilterType[];
};
