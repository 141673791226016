import { type ReactElement } from 'react';

const Logo = (): ReactElement => (
    <svg
        width="345"
        height="76"
        viewBox="0 0 345 76"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0)">
            <path
                d="M81.1377 28.0436L66.216 7.16797H73.3726L84.4139 22.8089H84.8993L96.0608 7.16797H102.975L88.174 27.9799L104.613 50.9619H97.5156L84.8377 33.0226H84.3523L71.736 50.9619H64.7598L81.1377 28.0436Z"
                fill="#1D2C48"
            />
            <path
                d="M106.857 30.5977H123.235V36.854H106.857V30.5977Z"
                fill="#1D2C48"
            />
            <path
                d="M140.766 12.4664H126.45V7.16797H160.844V12.4664H146.528V50.962H140.766V12.4664Z"
                fill="#1D2C48"
            />
            <path
                d="M166.971 7.16797H188.627C192.791 7.16797 195.986 8.29567 198.21 10.5511C200.434 12.8074 201.546 15.8291 201.546 19.6163C201.546 22.5961 200.869 25.1711 199.515 27.3412C198.209 29.4717 196.236 31.1102 193.903 32.0016L203.488 50.9618H197.058L188.019 32.8952H172.733V50.9619H166.971V7.16797ZM188.323 27.5968C190.588 27.5968 192.387 26.8944 193.721 25.4897C195.056 24.0856 195.723 22.1493 195.723 19.6808C195.723 17.3825 195.096 15.6056 193.843 14.3502C192.589 13.0953 190.749 12.4674 188.323 12.4665H172.733V27.5968H188.323Z"
                fill="#1D2C48"
            />
            <path
                d="M222.533 7.16797H229.873L246.009 50.9619H239.882L235.696 39.279H216.408L212.342 50.9619H206.399L222.533 7.16797ZM233.817 33.9806L228.964 20.3187L226.173 12.4026H225.87L223.14 20.1912L218.288 33.9805L233.817 33.9806Z"
                fill="#1D2C48"
            />
            <path
                d="M251.227 7.16797H266.634C273.307 7.16797 278.443 8.96607 282.043 12.5623C285.641 16.159 287.44 21.6597 287.441 29.0646C287.441 36.4701 285.641 41.9712 282.043 45.5676C278.442 49.1643 273.306 50.9624 266.634 50.9619H251.227V7.16797ZM266.634 45.6635C271.446 45.6635 275.116 44.3866 277.645 41.8328C280.171 39.2788 281.455 35.0654 281.496 29.1926V29.0644C281.496 23.1498 280.232 18.9046 277.705 16.329C275.177 13.754 271.487 12.4664 266.634 12.4662H256.989V45.6635H266.634Z"
                fill="#1D2C48"
            />
            <path
                d="M295.022 7.16797H327.051V12.4664H300.785V25.9364H324.382V31.2355H300.785V45.6635H327.415V50.9619H295.023L295.022 7.16797Z"
                fill="#1D2C48"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M43.3228 47.0411H34.5088L38.9148 38.084L43.3228 47.0411Z"
                fill="#FCC00E"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M43.2452 28.8484C43.2451 29.7057 42.9913 30.5437 42.5156 31.2565C42.0399 31.9693 41.3638 32.5248 40.5728 32.853C39.7818 33.1811 38.9114 33.267 38.0716 33.0998C37.2318 32.9326 36.4604 32.5199 35.8549 31.9138C35.2494 31.3077 34.8369 30.5355 34.6698 29.6947C34.5026 28.8539 34.5881 27.9824 34.9156 27.1904C35.2431 26.3983 35.7979 25.7212 36.5097 25.2448C37.2215 24.7683 38.0584 24.5139 38.9146 24.5137C40.0629 24.5138 41.1642 24.9705 41.9762 25.7833C42.7883 26.5962 43.2448 27.6987 43.2452 28.8484Z"
                fill="#FF5722"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.8364 19.5269C37.8104 19.5269 36.7839 19.5293 35.759 19.5257C34.9419 19.5222 34.5152 19.1278 34.5176 18.291C34.5205 16.197 34.5241 14.1041 34.5176 12.0102C34.517 11.1113 34.9773 10.7307 35.8461 10.7348C37.8975 10.7436 39.9495 10.7428 42.002 10.7325C42.889 10.7284 43.3052 11.1526 43.3052 12.0326C43.3017 14.0877 43.3044 16.1423 43.3135 18.1962C43.3188 19.1491 42.8326 19.5504 41.9143 19.5381C40.8896 19.5245 39.8612 19.5357 38.8363 19.5357L38.8364 19.5269Z"
                fill="#009688"
            />
            <path
                d="M29.1083 50.1173C28.6095 50.1173 28.1311 49.9189 27.7784 49.5658C27.4257 49.2126 27.2275 48.7337 27.2275 48.2343V9.63108C27.2275 9.13167 27.4257 8.65271 27.7784 8.29957C28.1311 7.94644 28.6095 7.74805 29.1083 7.74805C29.6071 7.74805 30.0854 7.94644 30.4381 8.29957C30.7909 8.65271 30.989 9.13167 30.989 9.63108V48.2343C30.989 48.7337 30.7908 49.2127 30.4381 49.5658C30.0854 49.9189 29.6071 50.1173 29.1083 50.1173Z"
                fill="#1D2C48"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.0767 26.3032L9.05127 29.9522L9.36453 19.9727L17.0767 26.3032Z"
                fill="#FCC00E"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.7581 15.1343C23.7581 15.9915 23.5043 16.8294 23.0287 17.5421C22.5532 18.2548 21.8772 18.8103 21.0863 19.1384C20.2953 19.4665 19.425 19.5523 18.5854 19.3852C17.7457 19.218 16.9744 18.8053 16.369 18.1992C15.7637 17.5932 15.3514 16.821 15.1843 15.9803C15.0172 15.1396 15.1029 14.2683 15.4305 13.4763C15.758 12.6844 16.3128 12.0075 17.0246 11.5313C17.7364 11.055 18.5732 10.8008 19.4293 10.8008C20.5773 10.801 21.6781 11.2576 22.4898 12.0702C23.3016 12.8829 23.7578 13.985 23.7581 15.1343Z"
                fill="#FF5722"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.1301 43.6122C16.1217 43.4319 15.1099 43.2504 14.1011 43.0665C13.2987 42.9191 12.9485 42.4546 13.0975 41.6319C13.4712 39.5704 13.8414 37.5096 14.2081 35.4495C14.3641 34.5637 14.8862 34.2709 15.7399 34.4299C17.7589 34.8012 19.7788 35.1635 21.7998 35.5167C22.6738 35.6699 23.0094 36.1592 22.8517 37.0278C22.4867 39.0505 22.1266 41.0732 21.7715 43.0959C21.6096 44.033 21.0587 44.3442 20.1575 44.1674C19.1504 43.9739 18.1385 43.8031 17.1284 43.6205C17.1295 43.6179 17.1301 43.6151 17.1301 43.6122Z"
                fill="#009688"
            />
            <path
                d="M29.0293 58.1297C23.2879 58.1297 17.6753 56.4251 12.9015 53.2314C8.12764 50.0377 4.40688 45.4984 2.20972 40.1874C0.0125642 34.8765 -0.562301 29.0325 0.557819 23.3945C1.67794 17.7565 4.44274 12.5776 8.50258 8.51284C12.5624 4.44805 17.735 1.67991 23.3661 0.558461C28.9973 -0.562989 34.8341 0.0126209 40.1385 2.2125C45.4429 4.41238 49.9767 8.13773 53.1665 12.9174C56.3562 17.6972 58.0587 23.3166 58.0587 29.0651C58.05 36.7709 54.9888 44.1585 49.5466 49.6073C44.1044 55.0562 36.7257 58.1211 29.0293 58.1297ZM29.0293 3.4398C23.9673 3.43973 19.0188 4.94258 14.8098 7.75832C10.6009 10.5741 7.32033 14.5762 5.38312 19.2587C3.44591 23.9411 2.93902 29.0936 3.92656 34.0645C4.9141 39.0354 7.35171 43.6014 10.9311 47.1852C14.5106 50.7691 19.071 53.2097 24.0358 54.1984C29.0007 55.1872 34.1468 54.6797 38.8236 52.7402C43.5003 50.8006 47.4976 47.5161 50.3099 43.3019C53.1222 39.0878 54.6233 34.1333 54.6232 29.0651C54.6156 22.2712 51.9166 15.7578 47.1185 10.9538C42.3204 6.14974 35.8149 3.44747 29.0293 3.4398Z"
                fill="#1D2C48"
            />
            <path
                d="M224.465 59.0265H232.114C233.285 58.9716 234.431 59.3722 235.314 60.1445C235.717 60.5225 236.035 60.9833 236.244 61.4954C236.453 62.0075 236.55 62.5588 236.527 63.1117C236.549 63.9807 236.29 64.8336 235.788 65.5432C235.322 66.2036 234.658 66.6978 233.893 66.9537V67.0514C234.781 67.2459 235.574 67.7447 236.134 68.4619C236.722 69.2808 237.015 70.275 236.966 71.2823C236.966 72.7741 236.523 73.8847 235.637 74.6141C234.751 75.3439 233.576 75.7088 232.114 75.7085H224.465V59.0265ZM231.675 66.1518C232.023 66.1673 232.37 66.1117 232.696 65.9882C233.022 65.8647 233.319 65.6761 233.569 65.4337C234.019 64.9153 234.273 64.2548 234.286 63.568C234.298 62.8811 234.069 62.2117 233.638 61.677C233.399 61.4585 233.118 61.29 232.813 61.1815C232.507 61.0729 232.183 61.0265 231.86 61.0449H226.659V66.1518L231.675 66.1518ZM232.067 73.6902C233.839 73.6902 234.725 72.7903 234.724 70.9905C234.724 69.1105 233.785 68.1704 231.905 68.1701H226.659V73.6902H232.067Z"
                fill="#1D2C48"
            />
            <path
                d="M244.683 68.8012L238.421 59.0254H241.079L245.793 66.6609H245.908L250.599 59.0254H253.118L246.879 68.8012V75.7075H244.683V68.8012Z"
                fill="#1D2C48"
            />
            <path
                d="M265.826 66.977L260.143 59.0254H262.869L267.075 64.9828H267.259L271.511 59.0254H274.145L268.508 66.9528L274.769 75.7077H272.065L267.236 68.8742H267.051L262.245 75.7077H259.587L265.826 66.977Z"
                fill="#1D2C48"
            />
            <path
                d="M276.71 59.0254H288.91V61.0438H278.904V66.175H287.894V68.1933H278.904V73.689H289.049V75.7074H276.71V59.0254Z"
                fill="#1D2C48"
            />
            <path
                d="M292.124 59.0254H300.372C301.958 59.0254 303.175 59.4549 304.024 60.3138C304.87 61.1735 305.294 62.3246 305.294 63.767C305.321 64.8015 305.053 65.8223 304.52 66.7093C304.023 67.521 303.271 68.1452 302.383 68.485L306.033 75.7074H303.584L300.141 68.8254H294.318V75.7074H292.124V59.0254ZM300.257 66.8071C300.637 66.8257 301.016 66.7639 301.37 66.6255C301.725 66.4872 302.046 66.2756 302.313 66.0045C302.848 65.396 303.122 64.6008 303.076 63.7914C303.099 63.4221 303.048 63.0518 302.925 62.7029C302.801 62.354 302.609 62.0337 302.359 61.7611C301.882 61.2831 301.182 61.044 300.257 61.0438H294.318V66.8071H300.257Z"
                fill="#1D2C48"
            />
            <path
                d="M309.106 59.0254H311.302V75.7075H309.106V59.0254Z"
                fill="#1D2C48"
            />
            <path
                d="M316.362 74.7845C315.145 73.9739 314.536 72.7092 314.536 70.9904L314.559 70.5045H316.731C316.731 70.5208 316.723 70.5773 316.709 70.6743C316.692 70.7872 316.684 70.9012 316.685 71.0153C316.664 71.46 316.761 71.9025 316.966 72.2976C317.171 72.6927 317.477 73.0263 317.852 73.2645C318.792 73.8175 319.872 74.0837 320.961 74.0304C322.408 74.0304 323.517 73.7913 324.288 73.313C324.649 73.1082 324.948 72.8085 325.151 72.4463C325.355 72.0842 325.456 71.6732 325.443 71.2578C325.451 70.9806 325.402 70.7047 325.298 70.4475C325.195 70.1903 325.039 69.9571 324.842 69.7627C324.41 69.346 323.894 69.0267 323.329 68.8263C322.72 68.5994 321.9 68.348 320.868 68.0722C319.817 67.805 318.783 67.4761 317.771 67.0873C317 66.7812 316.311 66.298 315.761 65.6767C315.183 64.9879 314.886 64.1067 314.929 63.2085C314.899 62.5496 315.043 61.8944 315.347 61.3094C315.652 60.7243 316.105 60.2303 316.662 59.8773C317.817 59.1154 319.296 58.7344 321.098 58.7344C322.901 58.7344 324.364 59.1193 325.489 59.8891C326.613 60.6597 327.175 61.8391 327.176 63.4275V63.7193H325.027V63.3547C325.036 62.9643 324.941 62.5785 324.752 62.2367C324.563 61.8949 324.287 61.6094 323.953 61.4091C323.113 60.9061 322.145 60.6608 321.168 60.7036C320.161 60.6593 319.158 60.8596 318.245 61.2873C317.911 61.4395 317.629 61.6866 317.434 61.998C317.239 62.3094 317.139 62.6713 317.148 63.0388C317.139 63.2988 317.187 63.5576 317.289 63.797C317.391 64.0364 317.544 64.2505 317.737 64.4244C318.164 64.8016 318.657 65.0946 319.192 65.288C319.77 65.507 320.575 65.7542 321.607 66.0296C322.669 66.3005 323.715 66.6335 324.739 67.027C325.524 67.3359 326.228 67.8227 326.795 68.4493C327.377 69.1268 327.678 70.0008 327.639 70.8932C327.639 72.6769 327.049 73.9739 325.87 74.7845C324.691 75.595 323.093 76.0003 321.076 76.0003C319.149 76.0003 317.578 75.595 316.362 74.7845Z"
                fill="#1D2C48"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="345" height="76" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
export default Logo;
