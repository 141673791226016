import { baseRtkApi } from '@xeris/reducers/baseApi';

import { getBrandByIdQuery, getBrandsQuery } from './brandQueries';
import type {
    ApiGetBrandByIdQuery,
    ApiGetBrandByIdQueryVariables,
    ApiGetBrandsQuery,
    ApiGetBrandsQueryVariables,
} from './brandQueries.gql';

export const brandApi = baseRtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getBrands: builder.query<ApiGetBrandsQuery, ApiGetBrandsQueryVariables>(
            {
                query: (variables) => ({
                    document: getBrandsQuery,
                    variables,
                }),
                providesTags: ['brands'],
            }
        ),
        getBrandById: builder.query<
            ApiGetBrandByIdQuery,
            ApiGetBrandByIdQueryVariables
        >({
            query: (variables) => ({
                document: getBrandByIdQuery,
                variables,
            }),
            providesTags: ['brands'],
        }),
    }),
});
