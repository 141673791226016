import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import PlatformLanguageSelector from '@xeris/containers/LanguageSelector/PlatformLanguageSelector';

const PlatformLanguage = (): ReactElement => {
    const { t } = useTranslation('user');

    return (
        <Box marginBlock={'12px'}>
            <Typography variant={'h3'} gutterBottom>
                {t('platformLanguage.language')}
            </Typography>
            <Typography gutterBottom>
                {t('platformLanguage.changesWarning')}
            </Typography>
            <Box>
                <PlatformLanguageSelector />
            </Box>
        </Box>
    );
};

export default PlatformLanguage;
