import { gql } from 'graphql-request';

export const getBrandsQuery = gql`
    query GetBrands {
        brands {
            id
            name
            theme {
                featureImage: feature_image
                logo
            }
        }
    }
`;

export const getBrandByIdQuery = gql`
    query GetBrandById($id: String!) {
        brand(id: $id) {
            id
            name
            productCounts: product_counts {
                masterProducts: master_products
            }
            theme {
                featureImage: feature_image
                logo
            }
            groupTypes: group_types {
                id
                name
                externalId: external_id
                groups {
                    id
                    name
                    description
                    externalId: external_id
                }
            }
            markets {
                id
                name
                externalId: external_id
            }
        }
    }
`;
