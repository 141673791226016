import { type MasterProductWithIds } from '@xeris/pages/product/Common';

import { type ColumnData, type ListData } from '../types';

export const createColumns = <MP extends MasterProductWithIds>(
    data: ListData<MP>[],
    columnCount: number
): ColumnData<MP>[] => {
    const rows: ColumnData<MP>[] = [];

    data.forEach((element) => {
        const prev = rows.at(-1);

        if (!prev) {
            if (element.type === 'section') {
                return rows.push(element);
            }

            return rows.push({
                ...element,
                data: [element],
            });
        }

        if (element.type === 'section') {
            if (prev.type === 'products') {
                prev.data.push(
                    ...Array.from({
                        length: columnCount - prev.data.length,
                    }).map(() => ({
                        type: 'empty' as const,
                    }))
                );
            }

            return rows.push(element);
        }

        if (prev.type === 'products') {
            if (prev.data.length === 0 || prev.data.length === columnCount) {
                return rows.push({
                    ...element,
                    data: [element],
                });
            }

            return prev.data.push(element);
        }

        return rows.push({
            ...element,
            data: [element],
        });
    });

    const last = rows.at(-1);

    if (last?.type === 'products' && last.data.length < columnCount) {
        last.data.push(
            ...Array.from({
                length: columnCount - last.data.length,
            }).map(() => ({
                type: 'empty' as const,
            }))
        );
    }

    return rows;
};
