import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    Typography,
} from '@mui/material';

import { useWhoami } from '@xeris/hooks';

import PlatformLanguageSelector from '../../LanguageSelector/PlatformLanguageSelector';

import FeatureFlags from './FeatureFlags';

type SettingsDrawerProps = {
    isOpen: boolean;
    handleClose: () => void;
};

export const SettingsDrawer = ({
    isOpen,
    handleClose,
}: SettingsDrawerProps): ReactElement => {
    const { t } = useTranslation('navigation');
    const { isSuperAdmin } = useWhoami();

    return (
        <Drawer open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('profileMenu.settings')}</DialogTitle>
            <DialogContent dividers sx={{ maxWidth: '400px', width: '100vw' }}>
                <Typography variant={'h3'} gutterBottom>
                    {t('profileMenu.language')}
                </Typography>
                <PlatformLanguageSelector sx={{ marginBottom: 3 }} />
                {isSuperAdmin && <FeatureFlags />}
            </DialogContent>
            <DialogActions>
                <Button color={'secondary'} onClick={handleClose}>
                    {t('common.close')}
                </Button>
            </DialogActions>
        </Drawer>
    );
};
