import { combineReducers } from '@reduxjs/toolkit';

import { categorizationSelectors } from '../Categorization/reducers/categorizationSelectors';
import categorizationSlice from '../Categorization/reducers/categorizationSlice';

const exportSlice = combineReducers({
    categorization: categorizationSlice,
});

export const exportSelectors = {
    categorizationSelectors,
};

export default exportSlice;
