import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Box,
    List,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Switch,
    Typography,
} from '@mui/material';

import { useAllFeatureFlags } from '@xeris/hooks';

const FeatureFlags = (): ReactElement => {
    const { t } = useTranslation('navigation');

    const {
        allFeatureFlagsVisible,
        toggleFeatureFlag,
        toggleAllFeatureFlags,
        allFeatureFlags,
    } = useAllFeatureFlags();

    return (
        <>
            <Box display={'flex'} gap={2}>
                <Typography variant={'h3'}>
                    {t('profileMenu.featureFlags')}
                </Typography>
                <Switch
                    checked={allFeatureFlagsVisible}
                    value={allFeatureFlagsVisible}
                    onClick={() =>
                        toggleAllFeatureFlags(!allFeatureFlagsVisible)
                    }
                    size="small"
                    edge="start"
                />
            </Box>
            <List>
                {allFeatureFlags.map(({ featureId, value, name }) => (
                    <MenuItem
                        key={featureId}
                        onClick={() => toggleFeatureFlag(featureId)}
                    >
                        <ListItemIcon>
                            <Switch
                                checked={value}
                                value={value}
                                size="small"
                                edge="start"
                            />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{ noWrap: true }}
                            primary={name}
                        />
                    </MenuItem>
                ))}
            </List>
        </>
    );
};

export default FeatureFlags;
