import {
    type ApiGetDataLanguageQuery,
    type ApiGetDataLanguageQueryVariables,
} from '@xeris/containers/LanguageSelector/api/languageQueries.gql';
import { baseRtkApi } from '@xeris/reducers/baseApi';

import { getDataLanguageQuery } from './languageQueries';

export const languageApi = baseRtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getDataLanguages: builder.query<
            ApiGetDataLanguageQuery,
            ApiGetDataLanguageQueryVariables
        >({
            query: (variables) => ({
                document: getDataLanguageQuery,
                variables,
            }),
        }),
    }),
});
