import { type ApiExportOptionValue } from '@xeris/types/graphql';

import { type ExportJobFormData } from './exportJobSchema';

export const formatOptionsForApi = (
    options: ExportJobFormData['options']
): ApiExportOptionValue[] =>
    options
        .map((option): ApiExportOptionValue | null => {
            if (option.int) {
                if (option.int.defaultValue === undefined) return null;

                return {
                    key: option.int.key,
                    value: option.int.defaultValue.toString(),
                };
            }
            if (option.select) {
                if (!option.select.defaultValue) return null;

                return {
                    key: option.select.key,
                    value: option.select.defaultValue,
                };
            }
            if (option.boolean) {
                if (option.boolean.defaultValue === undefined) return null;

                return {
                    key: option.boolean.key,
                    value: option.boolean.defaultValue.toString(),
                };
            }
            if (option.language) {
                if (!option.language.defaultValue) return null;

                return {
                    key: option.language.key,
                    value: option.language.defaultValue,
                };
            }
            if (option.string) {
                if (!option.string.defaultValue) return null;

                return {
                    key: option.string.key,
                    value: option.string.defaultValue,
                };
            }

            throw new Error('Invalid data');
        })
        .filter((option): option is ApiExportOptionValue => !!option);
