import { type ReactElement, type ReactNode } from 'react';
import {
    type Control,
    type FieldValues,
    type Path,
    useController,
} from 'react-hook-form';

import {
    Checkbox,
    type CheckboxProps,
    FormControl,
    FormControlLabel,
} from '@mui/material';

type TextInputProps<TForm extends FieldValues> = {
    fieldName: Path<TForm>;
    control: Control<TForm>;
    label?: ReactNode;
    ariaLabel?: string;
} & CheckboxProps;

export const CheckboxInput = <TForm extends FieldValues>({
    fieldName,
    control,
    label,
    ariaLabel,
    ...inputProps
}: TextInputProps<TForm>): ReactElement => {
    const {
        fieldState: { error },
        field,
    } = useController({
        name: fieldName,
        control,
    });

    if (label) {
        return (
            <FormControl fullWidth error={!!error}>
                <FormControlLabel
                    control={
                        <Checkbox
                            {...field}
                            {...inputProps}
                            checked={!!field.value}
                            value={!!field.value}
                        />
                    }
                    label={label}
                />
            </FormControl>
        );
    }

    return (
        <FormControl fullWidth error={!!error}>
            <Checkbox
                {...field}
                {...inputProps}
                aria-label={ariaLabel}
                checked={!!field.value}
                value={!!field.value}
            />
        </FormControl>
    );
};
