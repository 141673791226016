import { createSelector } from '@reduxjs/toolkit';

import { type StateType } from '@xeris/types';

const selectEcoSearch = (state: StateType): StateType['ecoSearch'] =>
    state.ecoSearch;

const selectGpcSegments = createSelector(
    selectEcoSearch,
    (ecoSearch) => ecoSearch.gpcSegments
);

const selectGpcElementWithId = createSelector(
    [selectEcoSearch, (state, elementId: string) => elementId],
    (ecoSearch, elementId) => ecoSearch.items[elementId]
);

const selectFilteredProducts = createSelector(
    selectEcoSearch,
    (ecoSearch) => ecoSearch.filteredProducts
);

const selectProductCounts = createSelector(
    selectEcoSearch,
    (ecoSearch) => ecoSearch.productCounts
);

const selectFilters = createSelector(
    selectEcoSearch,
    (ecoSearch) => ecoSearch.filters
);

export const ecoSearchSelectors = {
    selectGpcSegments,
    selectGpcElementWithId,
    selectFilteredProducts,
    selectProductCounts,
    selectFilters,
};
