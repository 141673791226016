import { type ReactElement } from 'react';

import { Typography } from '@mui/material';

import {
    type PhysicalMeasurementType,
    type PhysicalMeasurementValueType,
} from '@xeris/pages/product/types';

import './index.scss';

const getValueString = (
    value: PhysicalMeasurementValueType
): string | number | null => {
    if (value.__typename === 'SingleValue') {
        return value.value;
    }

    if (value.__typename === 'RangeValue') {
        return `${value.from} - ${value.to}`;
    }

    console.error('Unknown pysical measurement value type');

    return null;
};

type MeasurementsProps = {
    data: PhysicalMeasurementType[];
};

const Measurements = ({
    data: MeasurementList,
}: MeasurementsProps): ReactElement => (
    <dl className="datasheet-list-col datasheet-row">
        {MeasurementList.map((measurement) => {
            const value = getValueString(measurement.value);
            if (!value) {
                return null;
            }
            return (
                <div key={measurement.name}>
                    <dt>
                        <Typography
                            variant={'label'}
                            sx={{ marginBottom: 0.5 }}
                        >
                            {measurement.name}
                        </Typography>
                    </dt>
                    <dd>
                        <Typography variant={'body2'}>
                            {value} {measurement.unit}{' '}
                            {measurement.isConverted && '*'}
                        </Typography>
                    </dd>
                </div>
            );
        })}
    </dl>
);

export default Measurements;
