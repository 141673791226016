import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';

import * as yup from 'yup';

import { Tooltip } from '@xeris/components';
import { useAppSelector } from '@xeris/reducers';

import { exportJobApi } from '../../api/exportJobApi/exportJobApi';
import { useCategorizeProductsMutation } from '../api/categorizationApi';
import { categorizationSelectors } from '../reducers/categorizationSelectors';

const optionSchema = yup
    .array(
        yup.object({
            key: yup.string().required(),
            value: yup.string(),
        })
    )
    .required();

type ConfirmationButtonProps = {
    isDisabled?: boolean;
};

const CreateExportButton = ({
    isDisabled,
}: ConfirmationButtonProps): ReactElement => {
    const navigate = useNavigate();
    const location = useLocation();
    const { datasetId = '', exportId = '' } = useParams();

    const { t } = useTranslation('exports');

    const productCategorization = useAppSelector(
        categorizationSelectors.selectProductCategorization
    );

    const [categorizeProducts, categorizeProductsResult] =
        useCategorizeProductsMutation({
            fixedCacheKey: 'useCategorizeProductsMutation',
        });

    const [createExportJobMutation, createExportJobResult] =
        exportJobApi.useCreateExportJobMutation({
            fixedCacheKey: 'useCreateExportJobMutation',
        });

    const handleCompleteAndSend = async (): Promise<void> => {
        if (isDisabled) {
            return;
        }

        let options: { key: string; value: string }[] = [];

        try {
            options = optionSchema
                .validateSync(location.state?.optionsState ?? [])
                .map(({ key, value }) => ({ key, value: value ?? '' }));
        } catch {
            return;
        }

        await categorizeProducts({
            exportId: exportId,
            categorizations: productCategorization,
        }).unwrap();

        const { export: exportData } = await createExportJobMutation({
            exportId: exportId,
            datasetId: datasetId,
            options: options,
        }).unwrap();

        navigate(`/Export/ExportJob/${exportData.create?.id}`);
    };

    return (
        <Tooltip
            title={
                isDisabled
                    ? t('exportButton.sortAllToContinue')
                    : t('exportButton.saveAndBeginExport')
            }
        >
            <LoadingButton
                variant="contained"
                onClick={handleCompleteAndSend}
                loading={
                    createExportJobResult.isLoading ||
                    categorizeProductsResult.isLoading
                }
                disabled={isDisabled}
            >
                {t('exportButton.beginExport')}
            </LoadingButton>
        </Tooltip>
    );
};

export default CreateExportButton;
