import { type ReactElement, type ReactNode } from 'react';

import { Box, Stack } from '@mui/material';

import { Image, Typography } from '@xeris/components';
import { ensuredImageSource } from '@xeris/utilities';

type HeaderProps = {
    product: {
        name: string;
        images: { url: string }[] | null;
        brand: { name: string } | null;
    } | null;
    customContentHeader: ReactNode;
};

const Header = ({
    product,
    customContentHeader,
}: HeaderProps): ReactElement | ReactNode => {
    const productName = product?.name;

    if (customContentHeader) {
        return customContentHeader;
    }

    return (
        <Stack
            justifyContent={'flex-start'}
            alignContent={'center'}
            spacing={2}
            sx={{ paddingBottom: 3 }}
        >
            <Image
                alt={''}
                src={ensuredImageSource(product?.images?.[0]?.url)}
                objectFit={'contain'}
                style={{ width: '150px', height: '75px' }}
            />
            <Box>
                <Typography variant="subtitle2">
                    {product?.brand?.name}
                </Typography>
                <Typography variant="h3">{productName}</Typography>
            </Box>
        </Stack>
    );
};

export default Header;
