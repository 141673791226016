import { type ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ErrorPage } from '@xeris/components';
import { useAppDispatch } from '@xeris/reducers';

import { categorizationApi } from './api/categorizationApi';
import { initializeCategorization } from './reducers/categorizationSlice';
import CategorizationContent from './CategorizationContent';

const Categorization = (): ReactElement => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('exports');
    const location = useLocation();
    const navigate = useNavigate();

    const { datasetId = '', exportId = '' } = useParams();

    const { data, isLoading, isError, refetch } =
        categorizationApi.useGetDatasetWithCategorizedProductsQuery({
            id: datasetId,
            exportId: exportId,
        });

    useEffect(() => {
        if (data) {
            dispatch(initializeCategorization(data));
        }
    }, [data, dispatch]);

    useEffect(() => {
        if (!location.state?.optionsState) {
            navigate(`/Dataset/ProductList/${datasetId}`);
        }
    }, [datasetId, location.state?.optionsState, navigate]);

    if (isError) {
        return (
            <ErrorPage
                title={t('categorization.errors.failedToLoad')}
                description={t('categorization.errors.failedToLoadDescription')}
                actionText={t('categorization.errors.tryAgain')}
                onClick={(): void => {
                    refetch();
                }}
            />
        );
    }

    return <CategorizationContent isFetching={isLoading} />;
};

export default Categorization;
