import { combineReducers } from '@reduxjs/toolkit';

import variantGeneratorSlice from '@xeris/pages/product/variantGenerator/reducer/variantGeneratorSlice';

import { productsSlice } from './productsSlice';
import { settingsSlice } from './settingsSlice';

export const productSlice = combineReducers({
    products: productsSlice.reducer,
    settings: settingsSlice.reducer,
    variantGenerator: variantGeneratorSlice,
});

export const productActions = {
    settings: settingsSlice.actions,
};

export * from './productSelectors';
