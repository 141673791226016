import { createSlice } from '@reduxjs/toolkit';

import { getProductsFromEcoQuery } from '@xeris/pages/ecoSearch/transformers';

import { ecoSearchApi } from './api/ecoSearchApi';
import { type EcoSearchTypes } from './types';
import { recursiveFlatter } from './utilities';

const initialState: EcoSearchTypes.EcoSearchSliceType = {
    gpcSegments: [],
    items: {},
    filteredProducts: [],
    productCounts: { totalProductCount: 0, filteredProductCount: 0 },
    filters: [],
};

const ecoSearchSlice = createSlice({
    name: 'ecoSearch',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            ecoSearchApi.endpoints.getSegments.matchFulfilled,
            (state, { payload }) => {
                state.gpcSegments = payload.eco.categories;
                state.items = Object.fromEntries(
                    recursiveFlatter(payload.eco.categories, '').map(
                        (category) => [category.id, category]
                    )
                );
            }
        );
        builder.addMatcher(
            ecoSearchApi.endpoints.getFilteredProducts.matchFulfilled,
            (state, { payload }) => {
                state.filteredProducts = payload.eco.query
                    ? getProductsFromEcoQuery(payload.eco.query)
                    : [];
                state.productCounts.totalProductCount =
                    payload.eco.query.totalProducts;
                state.productCounts.filteredProductCount =
                    payload.eco.query.matchingProducts;
            }
        );
        builder.addMatcher(
            ecoSearchApi.endpoints.getFilters.matchFulfilled,
            (state, { payload }) => {
                state.filters = payload.eco.filterGroups
                    .flatMap((filterGroup) => filterGroup.filters)
                    .map((filter) => ({
                        ...filter,
                        isActive: false,
                        value: null,
                    }));
            }
        );
    },
});

export const ecoSearcReducer = ecoSearchSlice.reducer;
