import { productApi } from '../api';

import { useFilterProductEntityList } from './useFilterProductEntityList';
import { useFilterProductEntityListByMarket } from './useFilterProductEntityListByMarket';
import { useFilterProductEntityListBySearchTerm } from './useFilterProductEntityListBySearchTerm';

export const productHooks = {
    api: {
        queries: {
            // Master
            useGetAllMasterProductsForBrand:
                productApi.masterProduct.useGetAllMasterProductsForBrandQuery,
            useGetMasterProduct:
                productApi.masterProduct.useGetMasterProductQuery,
            useGetConfigurableMasterProduct:
                productApi.masterProduct.useGetConfigurableMasterProductQuery,
            useGetAllProductsForMasterProduct:
                productApi.masterProduct.useGetAllProductsForMasterProductQuery,
            useGetMasterProductSourceData:
                productApi.masterProduct.useGetMasterProductSourceDataQuery,
            useGetMasterProductDataErrors:
                productApi.masterProduct.useGetMasterProductDataErrorsQuery,
            useGetRelatedMasterProducts:
                productApi.masterProduct.useGetRelatedMasterProductsQuery,

            // Product
            useGetProduct: productApi.product.useGetProductQuery,
            useGetProductSourceData:
                productApi.product.useGetProductSourceDataQuery,
            useGetProductDataErrors:
                productApi.product.useGetProductDataErrorsQuery,
            useGetRelatedProducts:
                productApi.product.useGetRelatedProductsQuery,

            // Search
            useSearchProductEntityData:
                productApi.search.useSearchProductDataQuery,
            useSearchProductEntitiesData:
                productApi.search.useSearchProductsQuery,
        },
    },
    useFilterProductEntityList,
    useFilterProductEntityListByMarket,
    useFilterProductEntityListBySearchTerm,
};
