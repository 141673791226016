import { type ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';

import { MutationSnackbars } from '@xeris/components';
import {
    AutocompleteMultipleInputChips,
    FormDrawer,
    SelectInput,
    TextInput,
} from '@xeris/components/forms';
import { organizationsApi } from '@xeris/pages/admin/api';

import { type OrganizationType } from '../../types/organizationsTypes';
import {
    getOrganizationTypeList,
    type OrganizationFormData,
    organizationSchema,
} from '../utilities';

type EditOrganizationProps = {
    isOpen: boolean;
    organizationData?: OrganizationType;
    handleClose: () => void;
};

export const EditOrganization = ({
    isOpen,
    organizationData,
    handleClose,
}: EditOrganizationProps): ReactElement | null => {
    const { t } = useTranslation('administration');
    const typeList = getOrganizationTypeList(t);

    const [editOrganizationMutation, { isSuccess, isError, isLoading }] =
        organizationsApi.useEditOrganizationMutation();

    const { handleSubmit, control, reset } = useForm<OrganizationFormData>({
        resolver: yupResolver(organizationSchema),
    });

    const handleSubmitOrganization = async (
        data: OrganizationFormData
    ): Promise<void> => {
        await editOrganizationMutation({
            ...data,
            domains: data.domains ?? null,
            vatNumber: data.vatNumber ?? null,
        }).unwrap();

        reset(data);
        handleClose();
    };

    useEffect(() => {
        reset({
            id: organizationData?.id ?? '',
            name: organizationData?.name ?? '',
            type: organizationData?.type ?? 'PRODUCER',
            domains: organizationData?.domains ?? [],
            vatNumber: organizationData?.vatNumber ?? '',
        });
    }, [reset, organizationData]);

    return (
        <>
            <FormDrawer
                open={isOpen}
                onClose={handleClose}
                onSubmit={handleSubmit(handleSubmitOrganization)}
                title={t(`organization.editOrganization.editOrganization`)}
                cancelText={t('common.cancel')}
                saveText={t('brand.common.save')}
                isLoading={isLoading}
            >
                <TextInput
                    label={t('organization.id')}
                    fieldName={'id'}
                    control={control}
                    disabled
                    required
                    showError
                    placeholder={t('organization.id')}
                />
                <TextInput
                    label={t('organization.name')}
                    fieldName={'name'}
                    control={control}
                    required
                    showError
                    autoFocus
                    placeholder={t('organization.name')}
                />
                <SelectInput
                    fieldName={'type'}
                    label={t('organization.editOrganization.organizationType')}
                    options={typeList}
                    control={control}
                />
                <AutocompleteMultipleInputChips
                    fieldName={'domains'}
                    label={t(
                        'organization.addNewOrganization.organizationDomain'
                    )}
                    control={control}
                    showError
                    size={'small'}
                    options={organizationData?.domains}
                    placeholder={t(
                        'organization.addNewOrganization.organizationDomainPlaceholder'
                    )}
                />
                <TextInput
                    label={t('organization.editOrganization.vatNumberOptional')}
                    fieldName={'vatNumber'}
                    control={control}
                    required
                    showError
                    placeholder={t('organization.editOrganization.vatNumber')}
                />
            </FormDrawer>
            <MutationSnackbars
                isSuccess={isSuccess}
                successText={t('organization.editOrganization.success')}
                isError={isError}
                errorText={t('organization.somethingWentWrong')}
            />
        </>
    );
};
