import { type ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';

import { FormDrawer, TextInput } from '@xeris/components/forms';
import { datasetsApi } from '@xeris/pages/dataset/api';

import { type DatasetFormData, datasetSchema } from '../schema';

type DuplicateDatasetFormProps = {
    dataset: {
        id: string;
        name: string;
        description: string | null;
    };
    datasetNames: string[];
    isOpen: boolean;
    handleClose: () => void;
};

export const DuplicateDatasetForm = ({
    isOpen,
    dataset,
    datasetNames,
    handleClose,
}: DuplicateDatasetFormProps): ReactElement => {
    const { t } = useTranslation('dataset');

    const [duplicateDatasetMutation, { isLoading }] =
        datasetsApi.useDuplicateDatasetMutation();

    const { handleSubmit, control, setError } = useForm<DatasetFormData>({
        resolver: yupResolver(datasetSchema),
        defaultValues: {
            name: 'Copy of ' + dataset.name,
            description: dataset.description ?? undefined,
        },
    });

    return (
        <FormDrawer
            open={isOpen}
            isLoading={isLoading}
            title={t('common.duplicateDataset')}
            saveText={t('datasetPopUp.save')}
            cancelText={t('datasetPopUp.cancel')}
            onClose={handleClose}
            onSubmit={handleSubmit(async (data) => {
                if (datasetNames.includes(data.name)) {
                    return setError('name', { type: 'datasetUniqueName' });
                }

                await duplicateDatasetMutation({
                    id: dataset.id,
                    name: data.name,
                    description: data.description ?? null,
                }).unwrap();

                handleClose();
            })}
        >
            <TextInput
                id="dataset-name"
                label={t('datasetPopUp.datasetName')}
                variant="outlined"
                required
                fieldName={'name'}
                control={control}
                autoFocus
                showError
                onFocus={(event) => {
                    event.target.select();
                }}
            />
            <TextInput
                id="dataset-description"
                label={t('datasetPopUp.description')}
                multiline
                rows={4}
                fieldName={'description'}
                control={control}
            />
        </FormDrawer>
    );
};
