import { createSelector } from '@reduxjs/toolkit';

import { type ProductSliceType } from '@xeris/pages/product/types';
import { type StateType } from '@xeris/types';

const selectActiveMasterProduct = createSelector(
    (state: StateType): ProductSliceType => state.product,
    (
        product
    ): ProductSliceType['products']['activeMasterProduct'] | undefined | null =>
        product.products.activeMasterProduct
);

const selectActiveProduct = createSelector(
    (state: StateType): ProductSliceType => state.product,
    (
        product
    ): ProductSliceType['products']['activeProduct'] | undefined | null =>
        product.products.activeProduct
);

export const productSelectors = {
    selectActiveMasterProduct,
    selectActiveProduct,
};
