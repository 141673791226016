import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, type ButtonProps } from '@mui/material';

import { useAppSelector } from '@xeris/reducers';

type DownloadFileProps = Omit<ButtonProps, 'href'> & {
    href: string;
};

export const DownloadFile = ({
    href,
    ...buttonProps
}: DownloadFileProps): ReactElement => {
    const { t } = useTranslation('exports');
    const token = useAppSelector((state) => state.login.token);

    const url = new URL(href);

    url.searchParams.set('token', token ?? '');

    return (
        <Button href={url.toString()} {...buttonProps}>
            {t('exportStatusContent.download')}
        </Button>
    );
};
