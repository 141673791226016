import * as yup from 'yup';

import { type SharingPrivilege } from '../../../types/datasetTypes';

export const shareSchema = yup
    .object({
        users: yup
            .array()
            .of(
                yup
                    .object({
                        id: yup.string().required('required'),
                        organizationId: yup.string().required('required'),
                    })
                    .required('required')
            )
            .required('required')
            .min(1, 'minimumOne'),
        role: yup
            .mixed<SharingPrivilege>()
            .required('required')
            .oneOf(['READ', 'WRITE'] as const),
    })
    .required();

export type ShareDatasetFormType = yup.InferType<typeof shareSchema>;
