import { baseRtkApi } from '@xeris/reducers/baseApi';

import {
    addBrandMutation,
    deleteBrandMutation,
    editBrandMutation,
    editBrandThemeMutation,
    getAdminBrandsForOrganizationQuery,
    getAdminBrandsQuery,
} from './brandsQueries';
import {
    type ApiAddBrandMutation,
    type ApiAddBrandMutationVariables,
    type ApiDeleteBrandMutation,
    type ApiDeleteBrandMutationVariables,
    type ApiEditBrandMutation,
    type ApiEditBrandMutationVariables,
    type ApiEditBrandThemeMutation,
    type ApiEditBrandThemeMutationVariables,
    type ApiGetAdminBrandsForOrganizationQuery,
    type ApiGetAdminBrandsForOrganizationQueryVariables,
    type ApiGetAdminBrandsQuery,
    type ApiGetAdminBrandsQueryVariables,
} from './brandsQueries.gql';

export const brandApi = baseRtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getAdminBrands: builder.query<
            ApiGetAdminBrandsQuery,
            ApiGetAdminBrandsQueryVariables
        >({
            query: (variables) => ({
                document: getAdminBrandsQuery,
                variables,
            }),
            providesTags: ['brands'],
        }),
        getAdminBrandsForOrganization: builder.query<
            ApiGetAdminBrandsForOrganizationQuery,
            ApiGetAdminBrandsForOrganizationQueryVariables
        >({
            query: (variables) => ({
                document: getAdminBrandsForOrganizationQuery,
                variables,
            }),
            providesTags: ['brands'],
        }),
        addBrand: builder.mutation<
            ApiAddBrandMutation['brand']['create'],
            ApiAddBrandMutationVariables
        >({
            query: (variables) => ({
                document: addBrandMutation,
                variables,
            }),
            transformResponse: (data: ApiAddBrandMutation) => {
                return data.brand.create;
            },
            invalidatesTags: ['brands', 'organizations'],
        }),
        editBrand: builder.mutation<
            ApiEditBrandMutation['brand']['edit'],
            ApiEditBrandMutationVariables
        >({
            query: (variables) => ({
                document: editBrandMutation,
                variables,
            }),
            transformResponse: (data: ApiEditBrandMutation) => {
                return data.brand.edit;
            },
            invalidatesTags: ['brands'],
        }),
        editBrandTheme: builder.mutation<
            ApiEditBrandThemeMutation,
            ApiEditBrandThemeMutationVariables
        >({
            query: (variables) => ({
                document: editBrandThemeMutation,
                variables,
            }),
            invalidatesTags: ['brands'],
        }),
        deleteBrand: builder.mutation<
            ApiDeleteBrandMutation,
            ApiDeleteBrandMutationVariables
        >({
            query: (variables) => ({
                document: deleteBrandMutation,
                variables,
            }),
            invalidatesTags: ['brands', 'organizations'],
        }),
    }),
});
