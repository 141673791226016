import { type ReactElement } from 'react';
import { type Control, type Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Chip } from '@mui/material';

import { CheckboxInput, SelectInput, TextInput } from '@xeris/components/forms';

import { type Option } from '../types';
import { type ExportFormData } from '../utilities/exportSchema';

type LabelProps = {
    option: Option;
};

const Label = ({ option }: LabelProps): ReactElement => {
    const { t } = useTranslation('administration');

    return (
        <Box sx={{ display: 'flex', gap: 1 }}>
            {option.label}
            {option.required && (
                <Chip
                    label={t('exports.form.required')}
                    size={'small'}
                    sx={{ height: '18px' }}
                />
            )}
        </Box>
    );
};

type AskUserProps = {
    control: Control<ExportFormData>;
    fieldName: Path<ExportFormData>;
    label: string;
    noPadding?: boolean;
};

const AskUser = ({
    control,
    fieldName,
    noPadding,
    label,
}: AskUserProps): ReactElement => {
    const { t } = useTranslation('administration');

    return (
        <Box sx={{ paddingTop: noPadding ? 0 : 2 }}>
            <CheckboxInput
                fieldName={fieldName}
                control={control}
                inputProps={{
                    'aria-label': t('exports.form.allowInputForField', {
                        fieldName: label,
                    }),
                }}
            />
        </Box>
    );
};

type OptionFieldProps = {
    option: Option;
    control: Control<ExportFormData>;
    index: number;
};

export const OptionField = ({
    option,
    control,
    index,
}: OptionFieldProps): ReactElement | null => {
    if (option.__typename === 'LanguageConfigurationOption') {
        return (
            <>
                <SelectInput
                    key={option.key}
                    fieldName={`options.${index}.language.defaultValue`}
                    control={control}
                    showError
                    optionLabelField={'language'}
                    label={<Label option={option} />}
                    options={option.languages ?? []}
                />
                <AskUser
                    control={control}
                    label={option.label}
                    fieldName={`options.${index}.language.askUser`}
                />
            </>
        );
    }

    if (option.__typename === 'SelectConfigurationOption') {
        return (
            <>
                <SelectInput
                    key={option.key}
                    fieldName={`options.${index}.select.defaultValue`}
                    control={control}
                    showError
                    label={<Label option={option} />}
                    options={
                        option.values?.map((value) => ({
                            id: value.value,
                            label: value.label,
                        })) ?? []
                    }
                />
                <AskUser
                    control={control}
                    label={option.label}
                    fieldName={`options.${index}.select.askUser`}
                />
            </>
        );
    }

    if (option.__typename === 'StringConfigurationOption') {
        return (
            <>
                <TextInput
                    key={option.key}
                    fieldName={`options.${index}.string.defaultValue`}
                    control={control}
                    showError
                    multiline
                    label={<Label option={option} />}
                />
                <AskUser
                    control={control}
                    label={option.label}
                    fieldName={`options.${index}.string.askUser`}
                />
            </>
        );
    }

    if (option.__typename === 'IntConfigurationOption') {
        return (
            <>
                <TextInput
                    key={option.key}
                    fieldName={`options.${index}.int.defaultValue`}
                    control={control}
                    showError
                    label={<Label option={option} />}
                    type={'number'}
                />
                <AskUser
                    control={control}
                    label={option.label}
                    fieldName={`options.${index}.int.askUser`}
                />
            </>
        );
    }

    if (option.__typename === 'BooleanConfigurationOption') {
        return (
            <>
                <CheckboxInput
                    key={option.key}
                    fieldName={`options.${index}.boolean.defaultValue`}
                    control={control}
                    label={<Label option={option} />}
                />
                <AskUser
                    control={control}
                    label={option.label}
                    fieldName={`options.${index}.boolean.askUser`}
                    noPadding
                />
            </>
        );
    }

    return null;
};
