import { gql } from 'graphql-request';

export const getExportTypesQuery = gql`
    query GetExportTypes {
        exportTypes: export_types {
            id
            name
            options {
                __typename
                key
                label
                askUser
                required
                ... on BooleanConfigurationOption {
                    defaultBoolean
                }
                ... on IntConfigurationOption {
                    defaultInt
                }
                ... on LanguageConfigurationOption {
                    defaultLanguage
                    languages {
                        id
                        language
                    }
                }
                ... on SelectConfigurationOption {
                    defaultOption
                    values {
                        label
                        value
                    }
                }
                ... on StringConfigurationOption {
                    defaultString
                }
            }
        }
    }
`;

export const createExportMutation = gql`
    mutation CreateExport($export: ExportInput!, $organizationId: String) {
        export {
            addExport(export: $export, organization: $organizationId)
        }
    }
`;

export const editExportMutation = gql`
    mutation EditExport($exportId: String!, $export: ExportInput!) {
        export {
            editExport(id: $exportId, export: $export)
        }
    }
`;

export const deleteExportMutation = gql`
    mutation DeleteExport($exportId: String!) {
        export {
            deleteExport(id: $exportId)
        }
    }
`;
