import { Dialog as MuiDialog } from '@mui/material';

export {
    // Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
} from '@mui/material';

/**
 * Component(s) for displaying and structuring a dialog
 * @component
 * @example
 * <Dialog isOpen={true} onClose={handleClose}>
 *  <DialogTitle> {...header content} </DialogTitle>
 *  <DialogContent> {...body content} </DialogContent>
 *  <DialogActions> {...footer content}  </DialogActions>
 * </Dialog>
 */

export const Dialog = MuiDialog;
