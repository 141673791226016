import { type BackendModule, type CallbackError } from 'i18next';

import { type Namespace, type Resources } from '@xeris/i18next';

export const i18nextBackend: BackendModule = {
    init() {},
    type: 'backend',
    read(
        language: string,
        namespace: Namespace,
        callback: (
            errorValue: CallbackError,
            translations: null | Resources
        ) => void
    ): void {
        import(`../assets/locales/${language}/${namespace}.json`)
            .then((resources) => {
                callback(null, resources);
            })
            .catch((error) => {
                callback(error, null);
            });
    },
};
