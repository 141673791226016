import { baseRtkApi } from '@xeris/reducers/baseApi';

import {
    getBrandAccessPoliciesQuery,
    getBrandAccessPolicyQuery,
    setBrandAccessMutation,
} from './accessQueries';
import type {
    ApiGetBrandAccessPoliciesQuery,
    ApiGetBrandAccessPoliciesQueryVariables,
    ApiGetBrandAccessPolicyQuery,
    ApiGetBrandAccessPolicyQueryVariables,
    ApiSetBrandAccessMutation,
    ApiSetBrandAccessMutationVariables,
} from './accessQueries.gql';

export const accessApi = baseRtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getBrandAccessPolicy: builder.query<
            ApiGetBrandAccessPolicyQuery,
            ApiGetBrandAccessPolicyQueryVariables
        >({
            query: (variables) => ({
                document: getBrandAccessPolicyQuery,
                variables,
            }),
            providesTags: () => [{ type: 'brands' }],
        }),
        getBrandAccessPolicies: builder.query<
            ApiGetBrandAccessPoliciesQuery,
            ApiGetBrandAccessPoliciesQueryVariables
        >({
            query: (variables) => ({
                document: getBrandAccessPoliciesQuery,
                variables,
            }),
            providesTags: () => [{ type: 'brands' }],
        }),
        setBrandAccess: builder.mutation<
            ApiSetBrandAccessMutation,
            ApiSetBrandAccessMutationVariables
        >({
            query: (variables) => ({
                document: setBrandAccessMutation,
                variables,
            }),
            invalidatesTags: () => ['brands'],
        }),
    }),
});
