import {
    type ApiGetImportBatchesQuery,
    type ApiGetImportBatchesQueryVariables,
    type ApiGetImportsQuery,
    type ApiGetImportsQueryVariables,
} from '@xeris/pages/admin/Import/api/importQueries.gql';
import { baseRtkApi } from '@xeris/reducers/baseApi';

import { getImportBatchesQuery, getImportsQuery } from './importQueries';

export const importApi = baseRtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getImportBatches: builder.query<
            ApiGetImportBatchesQuery,
            ApiGetImportBatchesQueryVariables
        >({
            query: (variables) => ({
                document: getImportBatchesQuery,
                variables,
            }),
        }),
        getImports: builder.query<
            ApiGetImportsQuery,
            ApiGetImportsQueryVariables
        >({
            query: (variables) => ({
                document: getImportsQuery,
                variables,
            }),
        }),
    }),
});
