import { baseRtkApi } from '@xeris/reducers/baseApi';

import {
    createConnection,
    deleteConnection,
    getConnectionFormData,
    getConnections,
    updateConnection,
} from './connectionsQueries';
import type {
    ApiCreateConnectionMutation,
    ApiCreateConnectionMutationVariables,
    ApiDeleteConnectionMutation,
    ApiDeleteConnectionMutationVariables,
    ApiGetConnectionFormDataQuery,
    ApiGetConnectionFormDataQueryVariables,
    ApiGetConnectionsQuery,
    ApiGetConnectionsQueryVariables,
    ApiUpdateConnectionMutation,
    ApiUpdateConnectionMutationVariables,
} from './connectionsQueries.gql';

export const connectionsApi = baseRtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getConnections: builder.query<
            ApiGetConnectionsQuery['organization'],
            ApiGetConnectionsQueryVariables
        >({
            query: (variables) => ({
                document: getConnections,
                variables,
            }),
            transformResponse(data: ApiGetConnectionsQuery) {
                return data.organization ?? null;
            },
            providesTags: ['connections'],
        }),
        getConnectionFormData: builder.query<
            ApiGetConnectionFormDataQuery,
            ApiGetConnectionFormDataQueryVariables
        >({
            query: (variables) => ({
                document: getConnectionFormData,
                variables,
            }),
            providesTags: ['connections'],
        }),
        createConnection: builder.mutation<
            ApiCreateConnectionMutation,
            ApiCreateConnectionMutationVariables
        >({
            query: (variables) => ({
                document: createConnection,
                variables,
            }),
            invalidatesTags: ['connections', 'brands'],
        }),
        updateConnection: builder.mutation<
            ApiUpdateConnectionMutation,
            ApiUpdateConnectionMutationVariables
        >({
            query: (variables) => ({
                document: updateConnection,
                variables,
            }),
            invalidatesTags: ['connections', 'brands'],
        }),
        deleteConnection: builder.mutation<
            ApiDeleteConnectionMutation,
            ApiDeleteConnectionMutationVariables
        >({
            query: (variables) => ({
                document: deleteConnection,
                variables,
            }),
            invalidatesTags: ['connections', 'brands'],
        }),
    }),
});
