import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { i18nextBackend } from './i18nextBackend';

//NB: Don't add any other code beside what is inside these nested function
// If you update the supported languages, go to the user profile page and update the list there as well

i18n.use(i18nextBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        lowerCaseLng: true,
        ns: [
            'common',
            'dataset',
            'exports',
            'login',
            'navigation',
            'product',
            'products',
            'user',
        ],
        defaultNS: 'common',
        detection: {
            lookupLocalStorage: 'i18nextLanguage',
        },
        fallbackLng: {
            'de': ['en'],
            'en-us': ['en'],
            'default': ['en'],
        },
        supportedLngs: ['en', 'de'],
        nonExplicitSupportedLngs: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
