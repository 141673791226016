import { type ReactElement, useCallback } from 'react';

import { BasicOptionCard } from '@xeris/components/variantGenerator';
import { type BasicOption } from '@xeris/pages/product/types';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';

import { Price } from '../../../Common';
import { selectPreview, variantGeneratorSelectors } from '../../../reducer';

import { OptionCardButtons } from './OptionCardButtons';

type OptionCardProps = {
    featureId: string;
    option: BasicOption;
};

const OptionCard = ({
    option,
    featureId,
}: OptionCardProps): ReactElement | null => {
    const dispatch = useAppDispatch();

    const handleSelectPreview = useCallback((): void => {
        dispatch(
            selectPreview({
                optionId: option.id,
                featureId: featureId,
            })
        );
    }, [dispatch, featureId, option.id]);

    const { isPreviewable, isVisible, isPreviewed } = useAppSelector((state) =>
        variantGeneratorSelectors.optionStatus(state, featureId, option.id)
    );

    if (!isVisible) {
        return null;
    }

    return (
        <BasicOptionCard
            name={option.name}
            image={option.image}
            description={option.description}
            isPreviewable={isPreviewable}
            handleCardClick={() => {
                if (isPreviewable) {
                    handleSelectPreview();
                }
            }}
            isPreviewed={isPreviewed}
        >
            <Price price={option.price} />
            <OptionCardButtons
                featureId={featureId}
                option={option}
                handleSelectPreview={handleSelectPreview}
            />
        </BasicOptionCard>
    );
};
export default OptionCard;
