import { type FeatureSelection } from '../types/variantGeneratorSliceTypes';

export const getSelectionFromFeatures = (
    features: { id: string; options: { id: string }[] | null }[]
): FeatureSelection => {
    const selection: FeatureSelection = {};

    features.forEach((feature) => {
        feature.options?.forEach((option) => {
            if (!(feature.id in selection)) {
                selection[feature.id] = {};
            }

            selection[feature.id][option.id] = true;
        });
    });

    return selection;
};
