import { type ReactElement, useState } from 'react';

import { Switch } from '@mui/material';

import {
    DataGrid,
    type GridPaginationModel,
    type TypeSafeColDef,
} from '@xeris/components';

type RowData = {
    id: string;
    name: string;
    isVisible: boolean;
};

const getColumns = (
    handleSetVisibility: (ids: string[], newValue: boolean) => void
): TypeSafeColDef<RowData>[] => [
    {
        field: 'isVisible',
        description:
            'Determines if the brand is visible on your X-Trade main page. This setting does not affect anybody else.',
        headerName: 'Visibility',
        width: 100,
        renderCell: (params): ReactElement => {
            const brand = params.row;
            const handler = (): void => {
                handleSetVisibility([brand.id], !brand.isVisible);
            };

            return (
                <Switch
                    checked={brand.isVisible}
                    onClick={handler}
                    color="primary"
                    size={'small'}
                />
            );
        },
        sortComparator: (v1, v2): number => {
            if (v1) return 1;
            if (v2) return -1;
            return 0;
        },
    },
    {
        field: 'name',
        headerName: 'Name',
        flex: 1,
    },
];

type BrandVisibilityContentProps = {
    brands: RowData[];
    handleSetVisibility: (ids: string[], newValue: boolean) => void;
};

const BrandVisibilityContent = ({
    brands,
    handleSetVisibility,
}: BrandVisibilityContentProps): ReactElement => {
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
        {
            pageSize: 25,
            page: 0,
        }
    );

    return (
        <DataGrid
            initialState={{
                sorting: {
                    sortModel: [{ field: 'name', sort: 'asc' }],
                },
            }}
            columns={getColumns(handleSetVisibility)}
            paginationModel={paginationModel}
            pagination
            onPaginationModelChange={(newModel): void =>
                setPaginationModel(newModel)
            }
            rows={brands}
            autoHeight
            disableRowSelectionOnClick
        />
    );
};

export default BrandVisibilityContent;
