import { type ReactElement, type ReactNode } from 'react';

import { Dialog, DialogContent } from '@xeris/components';

import Header from './Header';

type DialogWrapperProps = {
    children?: ReactNode;
    isDialogOpen?: boolean;
    handleClose: () => void;
    footer?: ReactNode;
};

export const DialogWrapper = ({
    children,
    isDialogOpen,
    handleClose,
    footer,
}: DialogWrapperProps): ReactElement => {
    return (
        <Dialog
            open={isDialogOpen ?? false}
            onClose={handleClose}
            fullWidth
            maxWidth={'xl'}
            onClick={(event) => event.stopPropagation()}
        >
            <Header handleClose={handleClose} />
            <DialogContent
                dividers
                sx={{ 'padding': 0, '& > div': { maxHeight: '65vh' } }}
            >
                {children}
            </DialogContent>
            {footer}
        </Dialog>
    );
};
