import { type ReactElement } from 'react';

import { BasicFeatureOptionList } from '@xeris/components/variantGenerator';
import { type BasicFeature } from '@xeris/pages/product/types';

import OptionCard from './OptionCard';

type FeatureOptionsProps = {
    feature: BasicFeature;
};

const FeatureOptionList = ({ feature }: FeatureOptionsProps): ReactElement => {
    return (
        <BasicFeatureOptionList>
            {feature.options?.map((option) => (
                <OptionCard
                    key={option.id}
                    featureId={feature.id}
                    option={option}
                />
            ))}
        </BasicFeatureOptionList>
    );
};

export default FeatureOptionList;
