import { type ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Box } from '@mui/material';

import type { TFunction } from 'i18next';

import {
    CenteredPage,
    ErrorPage,
    Skeleton,
    type TabProviderTabType,
    TabsProvider,
} from '@xeris/components';

import { exportJobApi } from '../api/exportJobApi/exportJobApi';
import { DownloadFile } from '../components/DownloadFile';
import { type ExportJob, type ProductEntity, type TabType } from '../types';

import Header from './components/Header';
import ProductEntityStatuses from './components/ProductEntityStatuses';

const SkeletonLoad = (): ReactElement => {
    const exportPlaceholderList = Array.from(Array(10).keys());

    return (
        <CenteredPage>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '16px',
                }}
            >
                <Skeleton width={160} height={24} />
                <Skeleton width={160} height={24} />
            </Box>
            <Skeleton height={100} />
            <Box>
                {exportPlaceholderList.map((index) => (
                    <Skeleton
                        key={index}
                        height={42}
                        sx={{ margin: '12px 0' }}
                    />
                ))}
            </Box>
        </CenteredPage>
    );
};
export type TabTypesType = 'processing' | 'exported' | 'failed' | 'skipped';

type TabValue = Omit<TabProviderTabType<TabTypesType>, 'id'> & {
    id: TabTypesType;
};

type GroupedProducts = {
    processing: ProductEntity[];
    failed: ProductEntity[];
    succeeded: ProductEntity[];
    skipped: ProductEntity[];
} | null;

const getTabs = (
    t: TFunction<'exports'>,
    progress: GroupedProducts
): TabProviderTabType<TabType>[] => {
    if (!progress) {
        return [];
    }

    const tabList: TabValue[] = [];

    const tabLookup: Record<TabType, TabValue> = {
        processing: {
            id: 'processing',
            Label: <>{t('common.processing')}</>,
            Component: (
                <ProductEntityStatuses
                    productEntityList={progress.processing}
                />
            ),
            count: progress.processing.length,
        },
        exported: {
            id: 'exported',
            Label: <>{t('common.exported')}</>,
            Component: (
                <ProductEntityStatuses productEntityList={progress.succeeded} />
            ),
            count: progress.succeeded.length,
        },
        failed: {
            id: 'failed',
            Label: <>{t('common.failed')}</>,
            Component: (
                <ProductEntityStatuses productEntityList={progress.failed} />
            ),
            count: progress.failed.length,
        },
        skipped: {
            id: 'skipped',
            Label: <>{t('common.skipped')}</>,
            Component: (
                <ProductEntityStatuses productEntityList={progress.skipped} />
            ),
            count: progress.skipped.length,
        },
    };

    if (progress.processing.length > 0) tabList.push(tabLookup['processing']);
    if (progress.failed.length > 0) tabList.push(tabLookup['failed']);
    if (progress.succeeded.length > 0) tabList.push(tabLookup['exported']);
    if (progress.skipped.length > 0) tabList.push(tabLookup['skipped']);

    return tabList;
};

const groupProducts = (exportJob?: ExportJob | null): GroupedProducts => {
    if (!exportJob)
        return {
            failed: [],
            processing: [],
            skipped: [],
            succeeded: [],
        };

    const allProducts = [
        ...exportJob.masterProductStatus.map((product) => ({
            ...product,
            type: 'MasterProduct' as const,
        })),
        ...exportJob.productStatus.map((product) => ({
            ...product,
            type: 'Product' as const,
        })),
    ];

    return {
        failed: allProducts.filter(({ status }) => status === 'FAILED'),
        processing: allProducts.filter(({ status }) => status === 'PROCESSING'),
        skipped: allProducts.filter(({ status }) => status === 'SKIPPED'),
        succeeded: allProducts.filter(({ status }) => status === 'SUCCESSFUL'),
    };
};

export const ExportJobDetails = (): ReactElement | null => {
    const { t } = useTranslation('exports');

    const { exportJobId = '' } = useParams<{ exportJobId: string }>();

    const [isProcessing, setIsProcessing] = useState(false);

    const { data, isLoading, isError, refetch } =
        exportJobApi.useGetExportJobQuery(
            {
                id: exportJobId,
            },
            { pollingInterval: isProcessing ? 3_000 : 0 }
        );

    const tabList = useMemo(
        () => getTabs(t, groupProducts(data?.exportJob)),
        [data?.exportJob, t]
    );

    // If we are currently processing products, update the data
    useEffect(() => {
        setIsProcessing(
            !!tabList.find((tab) => tab.id === 'processing')?.count
        );
    }, [tabList]);

    if (isLoading) {
        return <SkeletonLoad />;
    }

    if (isError) {
        return (
            <ErrorPage
                title={t('exportJob.failedToLoad')}
                actionText={t('common.tryAgain')}
                onClick={() => refetch()}
            />
        );
    }

    if (!data?.exportJob) {
        return (
            <ErrorPage
                title={t('exportJob.notFound')}
                to={'/Export'}
                actionText={t('exportJob.backToExports')}
            />
        );
    }

    return (
        <CenteredPage data-testid="exportJobPage">
            <Header exportJob={data.exportJob} />
            <TabsProvider
                tabList={tabList}
                toolbar={
                    data.exportJob.downloadUri ? (
                        <DownloadFile
                            href={data.exportJob.downloadUri}
                            variant={'contained'}
                        />
                    ) : null
                }
            />
        </CenteredPage>
    );
};
