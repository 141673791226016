import { JSEvaluator } from 'js-feature-configuration';

import { type FeatureConfiguration } from '../types/featureConfiguration';

const cache = new WeakMap<FeatureConfiguration, JSEvaluator>();

// Keep the JSEvaluator around, so we don't need to re-initialize them
export const getFeatureConfigurator = (
    featureConfiguration: FeatureConfiguration
): JSEvaluator => {
    const cached = cache.get(featureConfiguration);

    if (cached) {
        return cached;
    }

    const evaluator = new JSEvaluator(featureConfiguration);

    cache.set(featureConfiguration, evaluator);

    return evaluator;
};
