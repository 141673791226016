import { type ReactElement } from 'react';

import { Typography } from '@mui/material';

import { type EpdType } from '@xeris/pages/product/types';

type TitleValueProps = {
    title: string;
    value: string;
};

const TitleValue = ({ title, value }: TitleValueProps): ReactElement => (
    <>
        <dt>
            <Typography variant={'label'}>{title}</Typography>
        </dt>
        <dd>
            <Typography
                variant={'body2'}
                sx={{ fontFeatureSettings: "'tnum' on, 'ss01' on, 'ss04' on" }}
            >
                {value}
            </Typography>
        </dd>
    </>
);

const presentGWP = (value: number): string =>
    `${Number(value).toFixed(2)} kg CO2-eqv`;

type SustainabilityProps = {
    data: EpdType[];
};

const Sustainability = ({
    data: sustainability,
}: SustainabilityProps): ReactElement => {
    return (
        <>
            {sustainability.map((epd) => (
                <dl key={epd.id} className="datasheet-list-col">
                    <TitleValue
                        title="GWP"
                        value={presentGWP(epd.globalWarmingPotential)}
                    />
                    <TitleValue
                        title="Normalized GWP"
                        value={presentGWP(epd.normalizedGlobalWarmingPotential)}
                    />
                    <TitleValue
                        title="Functional Unit"
                        value={epd.functionalUnit}
                    />
                    <TitleValue
                        title="Validity period"
                        value={`${epd.validFromYear} - ${epd.validThroughYear}`}
                    />
                </dl>
            ))}
        </>
    );
};

export default Sustainability;
