import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Typography } from '@xeris/components';

export const NoExports = (): ReactElement => {
    const { t } = useTranslation('exports');

    return (
        <Box sx={{ marginTop: '100px', textAlign: 'center' }}>
            <Typography variant="h2" gutterBottom>
                {t('exportStatusContent.nothingExported')}
            </Typography>
            <Typography>
                {t('exportStatusContent.detailsAboutExports')}
            </Typography>
        </Box>
    );
};
