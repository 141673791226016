import { useFilterProductEntityListByMarket } from './useFilterProductEntityListByMarket';
import { useFilterProductEntityListBySearchTerm } from './useFilterProductEntityListBySearchTerm';

export const useFilterProductEntityList = <
    Product extends {
        name: string;
        distinguishingFeatures?: { name: string; value: string }[] | null;
        idNumbers: { value: string }[];
        markets: { id: string }[];
        products?: {
            markets: { id: string }[];
            idNumbers: { value: string }[];
        }[];
    },
>(
    minimalProductEntityList: Product[],
    brandId: string
): Product[] => {
    const marketFilteredList = useFilterProductEntityListByMarket(
        minimalProductEntityList,
        brandId
    );

    return useFilterProductEntityListBySearchTerm(marketFilteredList);
};
