import { type ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';

import { MutationSnackbars } from '@xeris/components';
import { FormDrawer, SelectInput, TextInput } from '@xeris/components/forms';
import { userVerificationApi } from '@xeris/pages/admin/api';
import { type ApiSecurityLevel } from '@xeris/types';

import {
    type InviteUserFormData,
    inviteUserSchema,
} from './common/inviteUserSchema';
import {
    getDefaultLanguage,
    getRole,
    getRoleList,
    supportedLanguageList,
} from './common/utilities';

type ResendInviteFormProps = {
    isOpen: boolean;
    handleClose: () => void;
    user?: {
        username: string;
        securityLevel: ApiSecurityLevel | null;
        language: string;
    } | null;
};

export const ResendInviteForm = ({
    isOpen,
    handleClose,
    user,
}: ResendInviteFormProps): ReactElement => {
    const { t } = useTranslation('administration');

    const [inviteUserMutation, inviteUserResult] =
        userVerificationApi.useInviteUserMutation();

    const { handleSubmit, control, reset } = useForm<InviteUserFormData>({
        resolver: yupResolver(inviteUserSchema),
    });

    useEffect(() => {
        reset({
            username: user?.username,
            securityLevel: getRole(user?.securityLevel?.toLowerCase()),
            invitationLanguage: getDefaultLanguage(user?.language),
            message: '',
        });
    }, [reset, user, user?.language, user?.securityLevel, user?.username]);

    const handleSubmitUser = async (
        data: InviteUserFormData
    ): Promise<void> => {
        await inviteUserMutation({
            username: data.username,
            custom_invite_text: data.message,
        }).unwrap();

        reset();
        handleClose();
    };

    return (
        <>
            <FormDrawer
                open={isOpen}
                onClose={handleClose}
                onSubmit={handleSubmit(handleSubmitUser)}
                title={t(`users.resendInvite`)}
                isLoading={inviteUserResult.isLoading}
                saveText={t('users.sendInvite')}
                cancelText={t('common.cancel')}
            >
                <TextInput
                    label={t('users.userEmail')}
                    fieldName={'username'}
                    control={control}
                    type={'email'}
                    required
                    showError
                    placeholder={t('users.emailPlaceholder')}
                    disabled
                />
                <SelectInput
                    fieldName={'securityLevel'}
                    label={t('users.role')}
                    options={getRoleList(t)}
                    control={control}
                    disabled
                />
                <SelectInput
                    fieldName={'invitationLanguage'}
                    label={t('users.invitationLanguage')}
                    options={supportedLanguageList}
                    control={control}
                    disabled
                />
                <TextInput
                    fieldName={'message'}
                    label={t('users.customInviteText')}
                    control={control}
                    placeholder={t('users.messagePlaceholder')}
                    multiline
                    autoFocus
                    minRows={'4'}
                />
            </FormDrawer>
            <MutationSnackbars
                isSuccess={inviteUserResult.isSuccess}
                successText={t('users.inviteUserSuccess')}
                isError={inviteUserResult.isError}
                errorText={t('users.errorOnInvite')}
            />
        </>
    );
};
