import { type ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';

import { CenteredPage } from '@xeris/components';

import Dataset from './Dataset/Dataset';
import DatasetList from './DatasetList/DatasetList';

const DatasetPage = (): ReactElement => {
    return (
        <CenteredPage>
            <Routes>
                <Route
                    path={'ProductList/:datasetId'}
                    element={<Dataset />}
                ></Route>
                <Route path={'*'} element={<DatasetList />} />
            </Routes>
        </CenteredPage>
    );
};

export default DatasetPage;
