import { type ReactElement } from 'react';

const XTradeLogo = (): ReactElement => (
    <svg
        width="150"
        viewBox="0 0 950 169"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M235.181 81.1861L191.93 20.751H212.674L244.677 66.0317H246.084L278.437 20.751H298.479L255.576 81.0016L303.227 147.535H282.654L245.906 95.6005H244.499L207.93 147.535H187.709L235.181 81.1861Z"
            fill="#1D2C48"
        />
        <path
            d="M309.73 88.5777H357.203V106.69H309.73V88.5777Z"
            fill="#1D2C48"
        />
        <path
            d="M408.016 36.09H366.521V20.751H466.213V36.09H424.717V147.535H408.016V36.09Z"
            fill="#1D2C48"
        />
        <path
            d="M483.973 20.751H546.743C558.814 20.751 568.074 24.0157 574.522 30.5451C580.969 37.0772 584.192 45.8251 584.192 56.789C584.192 65.4157 582.229 72.8702 578.304 79.1526C574.518 85.3205 568.799 90.0639 562.037 92.6446L589.82 147.535H571.181L544.984 95.2315H500.674V147.535H483.973V20.751ZM545.863 79.8926C552.428 79.8926 557.644 77.8593 561.51 73.7926C565.379 69.7278 567.313 64.1222 567.313 56.9758C567.313 50.3221 565.496 45.1781 561.863 41.5437C558.23 37.9107 552.896 36.0929 545.863 36.0902H500.674V79.8926H545.863Z"
            fill="#1D2C48"
        />
        <path
            d="M645.025 20.751H666.3L713.07 147.535H695.31L683.178 113.713H627.268L615.485 147.535H598.258L645.025 20.751ZM677.73 98.3739L663.664 58.8225L655.575 35.9054H654.695L646.784 58.4535L632.718 98.3735L677.73 98.3739Z"
            fill="#1D2C48"
        />
        <path
            d="M728.194 20.751H772.853C792.193 20.751 807.081 25.9565 817.515 36.3676C827.944 46.78 833.159 62.7048 833.162 84.1419C833.162 105.581 827.946 121.507 817.515 131.918C807.078 142.331 792.191 147.536 772.853 147.535H728.194V20.751ZM772.853 132.196C786.801 132.196 797.439 128.499 804.767 121.106C812.09 113.712 815.811 101.514 815.93 84.5124V84.1414C815.93 67.0185 812.267 54.7287 804.941 47.2722C797.615 39.8176 786.918 36.09 772.853 36.0894H744.896V132.196H772.853Z"
            fill="#1D2C48"
        />
        <path
            d="M855.138 20.751H947.974V36.09H871.84V75.0858H940.238V90.4267H871.84V132.196H949.029V147.535H855.139L855.138 20.751Z"
            fill="#1D2C48"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M125.573 136.183H100.025L112.796 110.252L125.573 136.183Z"
            fill="#FCC00E"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M125.348 83.5169C125.348 85.9987 124.612 88.4247 123.233 90.4883C121.854 92.5518 119.895 94.1602 117.602 95.1101C115.309 96.06 112.786 96.3087 110.352 95.8247C107.918 95.3408 105.682 94.1459 103.927 92.3913C102.172 90.6366 100.976 88.401 100.492 85.9669C100.007 83.5329 100.255 81.0099 101.204 78.7168C102.154 76.4238 103.761 74.4637 105.825 73.0844C107.888 71.7051 110.314 70.9685 112.795 70.9678C116.124 70.9681 119.316 72.2903 121.67 74.6435C124.024 76.9968 125.347 80.1885 125.348 83.5169Z"
            fill="#FF5722"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M112.569 56.5314C109.595 56.5314 106.62 56.5384 103.649 56.528C101.281 56.518 100.044 55.3762 100.051 52.9536C100.059 46.8913 100.07 40.8326 100.051 34.7706C100.049 32.1684 101.383 31.0663 103.902 31.0782C109.848 31.1037 115.795 31.1015 121.745 31.0715C124.316 31.0597 125.522 32.2879 125.522 34.8353C125.512 40.785 125.52 46.7329 125.546 52.679C125.562 55.4377 124.152 56.5996 121.49 56.5639C118.52 56.5245 115.539 56.557 112.569 56.557L112.569 56.5314Z"
            fill="#009688"
        />
        <path
            d="M84.3718 145.09C82.926 145.09 81.5394 144.516 80.5171 143.493C79.4948 142.471 78.9204 141.084 78.9204 139.639V27.8819C78.9204 26.4361 79.4948 25.0495 80.5171 24.0272C81.5394 23.0049 82.926 22.4305 84.3718 22.4305C85.8176 22.4305 87.2042 23.0049 88.2265 24.0272C89.2489 25.0495 89.8232 26.4361 89.8232 27.8819V139.639C89.8231 141.084 89.2488 142.471 88.2264 143.493C87.2041 144.516 85.8176 145.09 84.3718 145.09Z"
            fill="#1D2C48"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M49.4978 76.151L26.2358 86.715L27.1438 57.824L49.4978 76.151Z"
            fill="#FCC00E"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M68.8643 43.8157C68.8644 46.2971 68.1287 48.7229 66.7502 50.7862C65.3717 52.8495 63.4123 54.4577 61.1198 55.4075C58.8273 56.3572 56.3047 56.6058 53.8709 56.1218C51.4371 55.6379 49.2015 54.4431 47.4467 52.6885C45.692 50.934 44.4969 48.6985 44.0127 46.2648C43.5285 43.831 43.7768 41.3083 44.7263 39.0157C45.6757 36.7231 47.2837 34.7636 49.3469 33.3848C51.4101 32.0061 53.8357 31.2701 56.3172 31.27C59.6445 31.2706 62.8353 32.5925 65.1882 34.9451C67.5411 37.2977 68.8633 40.4884 68.8643 43.8157Z"
            fill="#FF5722"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M49.6532 126.256C46.7302 125.734 43.7975 125.208 40.8732 124.676C38.5474 124.249 37.5324 122.905 37.9643 120.523C39.0475 114.555 40.1206 108.589 41.1835 102.625C41.6356 100.06 43.149 99.2126 45.6235 99.6731C51.4757 100.748 57.3306 101.797 63.1883 102.819C65.7219 103.263 66.6944 104.679 66.2373 107.194C65.1793 113.05 64.1357 118.905 63.1065 124.761C62.6372 127.474 61.0404 128.375 58.4281 127.863C55.509 127.303 52.5759 126.809 49.6481 126.28C49.6515 126.272 49.6532 126.264 49.6532 126.256Z"
            fill="#009688"
        />
        <path
            d="M84.143 168.286C67.5011 168.286 51.2329 163.351 37.3956 154.105C23.5584 144.86 12.7736 131.718 6.40498 116.343C0.036418 100.968 -1.62986 84.0495 1.61687 67.7273C4.86359 51.4052 12.8775 36.4123 24.6452 24.6447C36.4128 12.8772 51.4057 4.86336 67.7279 1.61675C84.0501 -1.62986 100.968 0.0365376 116.344 6.40521C131.719 12.7739 144.86 23.5588 154.106 37.3961C163.351 51.2335 168.286 67.5017 168.286 84.1436C168.261 106.452 159.388 127.839 143.613 143.614C127.839 159.388 106.451 168.261 84.143 168.286ZM84.143 9.95824C69.4703 9.95804 55.1271 14.3088 42.9271 22.4604C30.7271 30.612 21.2183 42.1983 15.6032 55.754C9.98814 69.3098 8.5189 84.2262 11.3813 98.617C14.2438 113.008 21.3093 126.227 31.6844 136.602C42.0596 146.977 55.2783 154.042 69.6691 156.905C84.0599 159.767 98.9763 158.298 112.532 152.683C126.088 147.068 137.674 137.559 145.826 125.36C153.977 113.16 158.328 98.8163 158.328 84.1436C158.306 64.4753 150.483 45.6189 136.575 31.7112C122.668 17.8036 103.811 9.98047 84.143 9.95824Z"
            fill="#1D2C48"
        />
    </svg>
);

export default XTradeLogo;
