import { type OrganizationTypesType } from '@xeris/pages/admin/Organization/types/organizationsTypes';

import { type ConnectionsTabVariantType } from '../types/connectionsTypes';

export const getAvailableOrganizations = (
    connections: { producer: { id: string }; consumer: { id: string } }[],
    organizations: { id: string; name: string; type: OrganizationTypesType }[],
    connectionType: ConnectionsTabVariantType
): { id: string; name: string; label: string }[] => {
    const connectedOrganizationIds = connections.flatMap((connection) => [
        connection.consumer.id,
        connection.producer.id,
    ]);

    return organizations
        .filter((organization) => {
            if (connectionType === 'channels') {
                return ['PRODUCER_CONSUMER', 'CONSUMER'].includes(
                    organization.type
                );
            }

            return ['PRODUCER_CONSUMER', 'PRODUCER'].includes(
                organization.type
            );
        })
        .filter(({ id }) => !connectedOrganizationIds.includes(id))
        .map(({ id, name }) => ({ id, name: name, label: name }));
};
