import { type ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';

import { BrandAccess } from './pages/BrandAccess/BrandAccess';
import { ConnectionDetails } from './pages/ConnectionDetails/ConnectionDetails';
import { ConnectionList } from './pages/ConnectionList/ConnectionList';

export const Connections = (): ReactElement => {
    return (
        <Routes>
            <Route path="" element={<ConnectionList />} />
            <Route path=":tradingPartnerId" element={<ConnectionDetails />} />
            <Route
                path=":tradingPartnerId/Brands/:brandId"
                element={<BrandAccess />}
            />
        </Routes>
    );
};
