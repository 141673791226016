import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Skeleton } from '@mui/material';

import { ErrorPage } from '@xeris/components';
import { useActiveOrganizationId } from '@xeris/pages/admin/hooks';
import { exportJobApi } from '@xeris/pages/exportJob/api/exportJobApi/exportJobApi';

import { ExportsList } from './components/ExportsList';
import { Header } from './components/Header';

export const Exports = (): ReactElement => {
    const { t } = useTranslation('administration');

    const activeOrganizationId = useActiveOrganizationId();
    const { data, isLoading, isError, refetch } =
        exportJobApi.useGetExportsQuery({
            organizationId: activeOrganizationId,
        });

    if (!activeOrganizationId || isLoading) {
        return (
            <>
                <Header exports={[]} />
                <Skeleton width={'100%'} height={100} />
            </>
        );
    }

    if (isError) {
        return (
            <>
                <Header exports={[]} />
                <ErrorPage
                    title={t('common.errors.failedToLoad')}
                    actionText={t('common.errors.tryAgain')}
                    onClick={() => refetch()}
                />
            </>
        );
    }

    if (!data) {
        return (
            <>
                <Header exports={[]} />
                <ErrorPage title={t('common.errors.notFound')} />
            </>
        );
    }

    // Only list your organizations export configurations, as they are the only ones you can edit
    const exports = data.exports.filter(
        (exportData) => exportData.organizationId === activeOrganizationId
    );

    return (
        <>
            <Header exports={exports} />
            <ExportsList exports={exports} />
        </>
    );
};
