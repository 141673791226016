import { type ReactElement, type ReactNode } from 'react';
import { Link, type LinkProps } from 'react-router-dom';

import { Typography, type TypographyProps } from '@mui/material';

type CellLinkProps = TypographyProps & {
    children: ReactNode;
    to: LinkProps['to'];
};
/**
 * For use with DataGrid to make the entire cell a link
 * @example:
 * renderCell: ({ row }): ReactElement => (
 *   <CellLink
 *    to={`/Admin/Brands/${row.id}`}
 *    noWrap
 *    fontWeight={'medium'}
 *  >
 *    {row.name}
 *  </CellLink>
 *),
 */
export const CellLink = ({
    children,
    to,
    ...typographyProps
}: CellLinkProps): ReactElement => {
    return (
        <Link
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
            }}
            to={to}
        >
            <Typography {...typographyProps}>{children}</Typography>
        </Link>
    );
};
