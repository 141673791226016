import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, MenuItem, Select } from '@mui/material';

import { useWhoami } from '@xeris/hooks';
import { sharedDatasetsApi } from '@xeris/pages/dataset/api';
import { type SharingPrivilege } from '@xeris/pages/dataset/types/datasetTypes';
import type { SimpleUserWithOrgType } from '@xeris/types';

type SharedWithMenuProps = {
    privilege: SharingPrivilege;
    datasetId: string;
    user?: SimpleUserWithOrgType;
};

const SharedWithMenu = ({
    privilege,
    datasetId,
    user,
}: SharedWithMenuProps): ReactElement => {
    const { t } = useTranslation('dataset');

    const { organizationId } = useWhoami();

    const [shareDatasetMutation] = sharedDatasetsApi.useShareDatasetMutation({
        fixedCacheKey: 'share-dataset',
    });

    const [unshareDatasetMutation] =
        sharedDatasetsApi.useUnshareDatasetMutation({
            fixedCacheKey: 'unshare-dataset',
        });

    const handleClickRead = (): void => {
        if (!user) return;

        shareDatasetMutation({
            datasetId,
            privilege: 'READ',
            userIds: [user.id],
        });
    };

    const handleClickWrite = (): void => {
        if (!user) return;

        shareDatasetMutation({
            datasetId,
            privilege: 'WRITE',
            userIds: [user.id],
        });
    };

    const handleClickUnshare = (): void => {
        if (!user) return;

        unshareDatasetMutation({
            datasetId,
            userId: user.id,
            removeYourself: false,
        });
    };

    return (
        <Select fullWidth size="small" value={privilege}>
            <MenuItem value={'READ'} onClick={handleClickRead}>
                {t('datasetSharingPopUp.view.header')}
            </MenuItem>
            <MenuItem
                disabled={user?.organization?.id !== organizationId}
                value={'WRITE'}
                onClick={handleClickWrite}
            >
                {t('datasetSharingPopUp.edit.header')}
            </MenuItem>
            <Divider />
            {/*<MenuItem disabled>
                {t("datasetSharingPopUp.transferOwnership")}
            </MenuItem>*/}
            <MenuItem onClick={handleClickUnshare}>
                {t('datasetSharingPopUp.removeAccess')}
            </MenuItem>
        </Select>
    );
};

export default SharedWithMenu;
