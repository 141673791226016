import { type ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Alert, AlertTitle, Button } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';

import { useIsMobile } from '@xeris/hooks';
import { useActiveOrganization } from '@xeris/pages/admin/hooks';
import { sharedDatasetsApi } from '@xeris/pages/dataset/api';
import type { DatasetSharedWith } from '@xeris/pages/dataset/types/datasetTypes';

import { hasOutsideUsers } from './utilities/hasOutsideUsers';
import RoleField from './RoleField';
import { type ShareDatasetFormType, shareSchema } from './schema';
import UserField from './UserField';

import styles from './ShareForm.module.scss';

type ShareFormProps = {
    dataset: {
        id: string;
        sharedWith: DatasetSharedWith[];
    };
};

const ShareForm = ({ dataset }: ShareFormProps): ReactElement => {
    const { t } = useTranslation('dataset');

    const [showEditInfo, setShowEditInfo] = useState(true);

    const { control, handleSubmit, resetField, watch, setValue } =
        useForm<ShareDatasetFormType>({
            resolver: yupResolver(shareSchema),
            defaultValues: { users: [], role: 'READ' },
        });

    const activeOrganization = useActiveOrganization();

    watch((data, { name }) => {
        if (
            name === 'users' &&
            hasOutsideUsers(data.users, activeOrganization?.id)
        ) {
            setValue('role', 'READ');
        }
    });

    const users = watch('users');

    const [shareDatasetMutation, shareDatasetResult] =
        sharedDatasetsApi.useShareDatasetMutation({
            fixedCacheKey: 'share-dataset',
        });

    const [, unshareDatasetResult] =
        sharedDatasetsApi.useUnshareDatasetMutation({
            fixedCacheKey: 'unshare-dataset',
        });

    const isMobile = useIsMobile();

    return (
        <>
            <form
                className={styles.shareWithForm}
                onSubmit={handleSubmit((data) => {
                    shareDatasetMutation({
                        datasetId: dataset.id,
                        userIds: data.users.map((user) => user.id),
                        privilege: data.role,
                    }).then(() => resetField('users'));
                })}
            >
                <UserField
                    fieldName={'users'}
                    control={control}
                    label={t('datasetSharingPopUp.selectUser')}
                    sharedWith={dataset.sharedWith}
                />
                <RoleField fieldName={'role'} control={control} />
                <Button
                    type={'submit'}
                    size={'medium'}
                    variant={'contained'}
                    fullWidth={isMobile}
                >
                    {t('datasetSharingPopUp.share')}
                </Button>
            </form>
            {(!!shareDatasetResult.error || !!unshareDatasetResult.error) && (
                <Alert severity={'error'} className={styles.alert}>
                    <AlertTitle>{t('common.somethingWentWrong')}</AlertTitle>
                </Alert>
            )}
            {activeOrganization &&
                showEditInfo &&
                hasOutsideUsers(users, activeOrganization.id) && (
                    <Alert
                        className={styles.alert}
                        severity={'info'}
                        action={
                            <Button
                                sx={{
                                    textTransform: 'none',
                                }}
                                size={'small'}
                                onClick={(): void => setShowEditInfo(false)}
                            >
                                {t('datasetSharingPopUp.gotIt')}
                            </Button>
                        }
                    >
                        {t('datasetSharingPopUp.roleWarning', {
                            organizationName: activeOrganization.name,
                        })}
                    </Alert>
                )}
        </>
    );
};

export default ShareForm;
