import type { ApiGetMasterProductQuery } from '@xeris/pages/product/api/masterProductApi/masterProductQueries.gql';
import type { MasterProduct } from '@xeris/pages/product/types';

import { transformProductEntity } from './utilities';

export const transformMasterProduct = (
    masterProduct?: ApiGetMasterProductQuery['master_product'] | null
): MasterProduct<
    | 'name'
    | 'description'
    | 'brand'
    | 'lastUpdated'
    | 'images'
    | 'markets'
    | 'idNumbers'
    | 'products'
    | 'groups'
    | 'isConfigurable'
    | 'relatedMasterProducts'
    | 'price'
    | 'prices'
    | 'productData'
> | null => {
    if (!masterProduct) {
        return null;
    }

    return {
        ...transformProductEntity(masterProduct),
        ...masterProduct,
    };
};
