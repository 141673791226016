import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, AlertTitle, Button, Container } from '@mui/material';

import { datasetProductsApi } from '@xeris/pages/dataset/api';

type DeletedProductsWarningProps = {
    dataset?: {
        id: string;
        missingProductCount: number;
    };
};

const DeletedProductsWarning = ({
    dataset,
}: DeletedProductsWarningProps): ReactElement | null => {
    const { t } = useTranslation('dataset');
    const [removeMissingProducts] =
        datasetProductsApi.useRemoveMissingProductsFromDatasetMutation();

    if (!dataset || dataset.missingProductCount === 0) {
        return null;
    }

    const handleDeleteMissingProducts = (): void => {
        removeMissingProducts({
            id: dataset.id,
        });
    };

    return (
        <Container
            maxWidth={false}
            sx={{ display: 'flex', marginBlock: 4, justifyContent: 'center' }}
        >
            <Alert
                severity={'warning'}
                sx={{ maxWidth: '60ch' }}
                action={
                    <Button
                        color="inherit"
                        size="small"
                        onClick={handleDeleteMissingProducts}
                    >
                        {t('datasetList.ok')}
                    </Button>
                }
            >
                <AlertTitle>{t('datasetList.productsRemoved')}</AlertTitle>
                {t('datasetList.numberOfProductsRemovedWarning', {
                    total: dataset.missingProductCount,
                })}
            </Alert>
        </Container>
    );
};

export default DeletedProductsWarning;
