import { verifyUserRtkApi } from '@xeris/reducers/verifyUserApi';

type AcceptInvitationType = {
    name: string;
    phone: string;
    password: string;
    agreement: boolean;
    token: string;
};

type InviteUserType = {
    username: string;
    custom_invite_text?: string;
};

export const userVerificationApi = verifyUserRtkApi.injectEndpoints({
    endpoints: (builder) => ({
        inviteUser: builder.mutation<string, InviteUserType>({
            query: ({ username, custom_invite_text }) => ({
                url: 'invite',
                method: 'POST',
                body: { username, custom_invite_text },
            }),
        }),
        acceptInvitationUser: builder.mutation<string, AcceptInvitationType>({
            query: ({ name, phone, password, agreement, token }) => ({
                url: 'user-info',
                method: 'POST',
                params: { token },
                body: { name, phone, password, agreement },
            }),
        }),
        resetUserPassword: builder.mutation<string, string>({
            query: (username) => ({
                url: 'forgot-password',
                method: 'POST',
                body: { username },
            }),
        }),
        getUserInformationFromToken: builder.query<
            {
                username: string;
                name: string;
                phone: string;
                language: string;
            },
            { token: string }
        >({
            query: ({ token }) => ({
                url: 'user-info',
                params: { token },
            }),
        }),
        getUsernameFromToken: builder.query<
            { username: string },
            { token: string }
        >({
            query: ({ token }) => ({
                url: 'reset-password',
                params: { token },
            }),
        }),
    }),
});
