import { type ReactElement, type ReactNode } from 'react';

import { Box } from '@mui/material';

type BasicFeaturePanelProps = {
    children?: ReactNode;
};

export const BasicFeaturePanel = ({
    children,
}: BasicFeaturePanelProps): ReactElement => {
    return <Box sx={{ marginBottom: '2rem', width: '100%' }}>{children}</Box>;
};
