import type { ApiEcoFilterInput } from '@xeris/types/graphql';

import { type EcoSearchTypes } from './types';

export const transformFilterInputToOutput = (
    filter: EcoSearchTypes.FilterType
): ApiEcoFilterInput => {
    if (filter.type === 'Boolean') {
        return {
            int_value: null,
            range_value: null,
            filter_id: filter.id,
            boolean_value: filter.value ?? false,
        };
    }
    if (filter.type === 'Int') {
        return {
            boolean_value: null,
            range_value: null,
            filter_id: filter.id,
            int_value: filter.value,
        };
    }
    return {
        boolean_value: null,
        int_value: null,
        filter_id: filter.id,
        range_value: {
            from: filter.value?.from ?? 0,
            to: filter.value?.to ?? 999,
        },
    };
};

type Category<Product> = {
    products: Product[] | null;
    subCategories?: Category<Product>[] | null;
};

export const getProductsFromEcoQuery = <Product>(
    category: Category<Product>
): Product[] => {
    if (category.products) {
        return category.products;
    }
    if (category.subCategories) {
        return category.subCategories.flatMap((subCategory) =>
            getProductsFromEcoQuery(subCategory)
        );
    }
    return [];
};
