import { type ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { TextInput } from '@xeris/components/forms';
import { useWhoami } from '@xeris/hooks';

const Profile = (): ReactElement => {
    const { t } = useTranslation('user');

    const whoami = useWhoami();

    const { control, reset } = useForm({
        defaultValues: whoami,
    });

    useEffect(() => {
        reset(whoami);
    }, [whoami, reset]);

    return (
        <Box>
            <TextInput
                control={control}
                fieldName={'name'}
                label={t('profile.name')}
                disabled
            />
            <TextInput
                control={control}
                fieldName={'username'}
                label={t('profile.email')}
                disabled
            />
            <TextInput
                control={control}
                fieldName={'phone'}
                label={t('profile.phone')}
                disabled
            />
            <TextInput
                control={control}
                fieldName={'organizationName'}
                label={t('profile.organization')}
                disabled
            />
        </Box>
    );
};

export default Profile;
