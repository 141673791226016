export const switchOnHover = {
    '& > *': {
        'gridArea': '1 / 1 / 2 / 2',
        '&:first-of-type': {
            display: 'block',
        },
        '&:last-of-type:not(:only-of-type)': {
            display: 'none',
        },
    },
    '&:hover > *': {
        '&:first-of-type:not(:only-of-type)': {
            display: 'none',
        },
        '&:last-of-type:not(:only-of-type)': {
            display: 'block',
        },
    },
} as const;
