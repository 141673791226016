import { useLocation } from 'react-router-dom';

import { type Stage } from '../VariantGeneratorWrapper/VariantGeneratorWrapper';

type URLState = {
    page: Stage;
    featureGroupId: string | null;
};

export const useURLState = (): URLState => {
    const location = useLocation();

    return {
        page: location.state?.page ?? 'configure',
        featureGroupId: location.state?.featureGroup ?? null,
    };
};
