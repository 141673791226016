import { type ReactElement, useCallback } from 'react';

import { MaterialSwatch } from '@xeris/components';
import { type MaterialOption } from '@xeris/pages/product/types';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';

import {
    selectOptions,
    selectPreview,
    variantGeneratorSelectors,
} from '../../../reducer';

type MaterialSwatchWrapperProps = {
    featureId: string;
    option: MaterialOption;
};

export const MaterialSwatchWrapper = ({
    featureId,
    option,
}: MaterialSwatchWrapperProps): ReactElement | null => {
    const dispatch = useAppDispatch();

    const { isVisible, isSelectable, isPreviewable, isSelected, isPreviewed } =
        useAppSelector((state) =>
            variantGeneratorSelectors.optionStatus(state, featureId, option.id)
        );

    const handleToggleSelected = useCallback((): void => {
        dispatch(
            selectOptions({
                featureId: featureId,
                optionIds: [option.id],
                newValue: !isSelected,
            })
        );
    }, [dispatch, featureId, option.id, isSelected]);

    const handleSelectPreview = useCallback((): void => {
        dispatch(
            selectPreview({
                optionId: option.id,
                featureId: featureId,
            })
        );
    }, [dispatch, featureId, option.id]);

    if (!isVisible) {
        return null;
    }

    return (
        <MaterialSwatch
            image={option.image}
            optionName={option.name}
            colorCode={option.colorCode ?? undefined}
            description={option.description ?? undefined}
            onToggleSelected={handleToggleSelected}
            onSelectPreview={handleSelectPreview}
            isSelected={isSelected}
            isPreviewed={isPreviewed}
            isSelectable={isSelectable}
            isPreviewable={isPreviewable}
        />
    );
};
