import { type ReactElement, useState } from 'react';

import { Collapse, Divider, List } from '@mui/material';

import { type MenuElementWithLevelType } from '../types';

import { CollapseElement, Element } from './Element';

type MenuElementProps = {
    element: MenuElementWithLevelType;
};

const MenuElement = ({ element }: MenuElementProps): ReactElement => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            {element.submenu && !element.isAlwaysShown ? (
                <CollapseElement
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    element={element}
                />
            ) : (
                <Element element={element} />
            )}
            {element.submenu && element.isAlwaysShown && (
                <li>
                    <Divider />
                </li>
            )}
            {element.submenu && (
                <li>
                    <Collapse
                        in={isOpen || element.isAlwaysShown}
                        sx={{
                            pl:
                                element.submenu && element.isAlwaysShown
                                    ? 0
                                    : 1.5,
                            ml:
                                element.submenu && element.isAlwaysShown
                                    ? 0
                                    : 2.5,
                            borderLeft:
                                element.submenu && element.isAlwaysShown
                                    ? 0
                                    : '1px solid #d5d9e0',
                        }}
                    >
                        <Menu
                            elements={element.submenu}
                            isAlwaysShown={element.isAlwaysShown}
                            isSubmenu
                        />
                    </Collapse>
                </li>
            )}
        </>
    );
};

type MenuProps = {
    elements: MenuElementWithLevelType[];
    isSubmenu?: boolean;
    isAlwaysShown?: boolean;
};

const Menu = ({
    elements,
    isSubmenu,
    isAlwaysShown,
}: MenuProps): ReactElement | null => {
    return (
        <List
            sx={{
                display: 'flex',
                flexDirection: 'column',
                pt: isSubmenu && !isAlwaysShown ? 0 : 1,
                pb: isSubmenu && !isAlwaysShown ? 0 : 1,
                gap: !isAlwaysShown ? 0 : 0.5,
            }}
        >
            {elements.map((element) => (
                <MenuElement key={element.id} element={element} />
            ))}
        </List>
    );
};

export default Menu;
