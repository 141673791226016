import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { type IdNumberVariantsType } from '@xeris/pages/product/types';

import './index.scss';

type NameAndValueProps = {
    name: string;
    value: string;
};

const NameAndValue = ({ name, value }: NameAndValueProps): ReactElement => (
    <>
        <dt>
            <Typography variant={'label'}>{name}</Typography>
        </dt>
        <dd>
            <Typography
                variant={'body2'}
                sx={{ fontFeatureSettings: "'tnum' on, 'ss01' on, 'ss04' on" }}
            >
                {value}
            </Typography>
        </dd>
    </>
);

type IdNumbersProps = {
    data: IdNumberVariantsType;
};

const IdNumbers = ({ data: idNumbers }: IdNumbersProps): ReactElement => {
    const { t } = useTranslation('product');
    return (
        <div className="datasheet-content-wrapper id-container">
            <div>
                <Typography
                    variant={'subtitle1'}
                    component={'h3'}
                    sx={{ marginBottom: 2 }}
                >
                    {t('datasheet.identification')}
                </Typography>
                <dl className="id-grid">
                    {idNumbers.identifications.map(({ name, value }) => (
                        <NameAndValue name={name} value={value} key={name} />
                    ))}
                </dl>
            </div>
            <div>
                <Typography
                    variant={'subtitle1'}
                    component={'h3'}
                    sx={{ marginBottom: 2 }}
                >
                    {t('datasheet.classification')}
                </Typography>
                <dl className="id-grid">
                    {idNumbers.classifications.map(({ name, value }) => (
                        <NameAndValue name={name} value={value} key={name} />
                    ))}
                </dl>
            </div>
        </div>
    );
};

export default IdNumbers;
