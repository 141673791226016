import { type ReactElement } from 'react';
import { type Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Alert, AlertTitle } from '@mui/material';

import { SelectInput } from '@xeris/components/forms';
import { useWhoami } from '@xeris/hooks';

import { type ExportJobFormData } from '../utilities/exportJobSchema';

type DatasetFieldProps = {
    control: Control<ExportJobFormData>;
    datasets: {
        id: string;
        name: string;
        totalCount: number;
        owner: {
            id: string;
            name: string | null;
        };
    }[];
};

export const DatasetField = ({
    control,
    datasets,
}: DatasetFieldProps): ReactElement => {
    const { t } = useTranslation('exports');
    const { userId } = useWhoami();

    return (
        <SelectInput
            control={control}
            fieldName={'dataset'}
            label={t('common.dataset')}
            options={(datasets ?? []).map((dataset) => ({
                id: dataset.id,
                label: dataset.name,
                secondaryLabel:
                    t('createExportDialog.productCount', {
                        count: dataset.totalCount,
                    }) +
                    (userId !== dataset.owner.id
                        ? t('createExportDialog.by', {
                              usernameOrYou: dataset.owner.name,
                          })
                        : ''),
            }))}
            errorMap={{
                emptyDataset: (
                    <Alert severity="warning">
                        <AlertTitle>
                            {t('createExportDialog.canNotExportEmpty')}
                        </AlertTitle>
                        {t('createExportDialog.pleaseAddProducts')}
                    </Alert>
                ),
            }}
            showError
        />
    );
};
