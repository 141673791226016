import { type ReactElement } from 'react';

import { Box } from '@mui/material';

import ContentHeader from './ContentHeader';

type PageWrapperProps = {
    children?: ReactElement;
};

const PageWrapper = ({ children }: PageWrapperProps): ReactElement => {
    return (
        <Box
            sx={{
                margin: ' 0 auto',
                maxWidth: '1440px',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <ContentHeader />
            <Box sx={{ flexGrow: 1 }}>{children}</Box>
        </Box>
    );
};

export default PageWrapper;
