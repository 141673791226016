import { type ReactElement, type ReactNode } from 'react';

import { Box } from '@mui/material';

type BasicFeatureSummaryOptionListProps = {
    children?: ReactNode;
};

export const BasicFeatureSummaryOptionList = ({
    children,
}: BasicFeatureSummaryOptionListProps): ReactElement => {
    return (
        <Box
            sx={{
                display: 'flex',
                gap: 2,
                flexDirection: 'column',
                width: '100%',
            }}
        >
            {children}
        </Box>
    );
};
