export const loadLocalState = <T>(key: string, initialState: T): T => {
    if (key === undefined) {
        console.warn('No key provided');
        return initialState;
    }

    const data = localStorage.getItem(key);

    if (!data) {
        return storeLocalState(key, initialState);
    }

    return JSON.parse(data);
};

export const storeLocalState = <T>(key: string, newState: T = {} as T): T => {
    if (key === undefined) {
        console.warn('No key provided');
        return newState;
    }

    localStorage.setItem(key, JSON.stringify(newState));

    return loadLocalState(key, newState);
};

export const clearLocalState = (): void => {
    localStorage.clear();
};
