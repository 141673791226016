import type { UncategorizedCardData } from '../types';

export const getChildProducts = (
    elements: UncategorizedCardData[],
    element: UncategorizedCardData
): UncategorizedCardData[] => {
    if (element.type === 'group') {
        return elements.filter(
            (el) => el.type === 'product' && el.path.startsWith(element.path)
        );
    }

    return [element];
};
