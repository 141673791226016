import { type ReactElement, type ReactNode } from 'react';

import { Box, DialogContent } from '@mui/material';

type SplitDialogContentProps = {
    columnOne: ReactNode;
    columnTwo: ReactNode;
};

export const SplitDialogContent = ({
    columnOne,
    columnTwo,
}: SplitDialogContentProps): ReactElement => {
    return (
        <Box sx={{ display: 'flex', flex: '1 1 auto' }}>
            <DialogContent
                sx={{
                    flexBasis: '350px',
                    flexGrow: 0,
                    borderRight: 1,
                    borderColor: 'divider',
                    maxHeight: 'calc(100vh - 68px)',
                    padding: 2,
                    scrollbarGutter: 'stable',
                }}
                dividers
            >
                {columnOne}
            </DialogContent>
            <DialogContent
                sx={{ flexGrow: 1, maxHeight: 'calc(100vh - 68px)' }}
                dividers
            >
                {columnTwo}
            </DialogContent>
        </Box>
    );
};
