import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Container } from '@mui/material';

import { Typography } from '@xeris/components';

export const NoProducts = (): ReactElement => {
    const { t } = useTranslation('dataset');

    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                height: '50vh',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography variant={'h3'}>
                {t('datasetProductList.datasetEmpty')}
            </Typography>
            <Typography variant={'subtitle1'}>
                {t('datasetProductList.addedItemsCanExport')}
            </Typography>
            <Button variant={'contained'} component={Link} to="/Products">
                {t('datasetProductList.browseProducts')}
            </Button>
        </Container>
    );
};
