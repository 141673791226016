export const groupDocuments = <
    T extends { id: string; type: { id: string; label: string } },
>(
    documents: T[]
): {
    id: string;
    label: string;
    documents: T[];
}[] => {
    const documentTypes = Object.fromEntries(
        documents.map(({ type }) => [type.id, type.label])
    );

    const groupedDocuments = Object.entries(documentTypes).map(
        ([typeId, label]) => ({
            id: typeId,
            label: label,
            documents: documents.filter(({ type }) => type.id === typeId),
        })
    );

    groupedDocuments.sort((a, b) => {
        if (a.id === 'Other') {
            return 1;
        }
        if (b.id === 'Other') {
            return -1;
        }

        return a.id.localeCompare(b.id);
    });

    return groupedDocuments;
};
