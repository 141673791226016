import { type ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';

import { FormDrawer, TextInput } from '@xeris/components/forms';
import { datasetsApi } from '@xeris/pages/dataset/api';

import { type DatasetFormData, datasetSchema } from '../schema';

type CreateDatasetFormProps = {
    isOpen: boolean;
    handleClose: () => void;
    datasetNames: string[];
};

export const CreateDatasetForm = ({
    isOpen,
    handleClose,
    datasetNames,
}: CreateDatasetFormProps): ReactElement => {
    const { t } = useTranslation('dataset');

    const [createDatasetMutation, { isLoading }] =
        datasetsApi.useCreateDatasetMutation();

    const { handleSubmit, control, reset, setError } = useForm<DatasetFormData>(
        {
            resolver: yupResolver(datasetSchema),
        }
    );

    return (
        <>
            <FormDrawer
                open={isOpen}
                onClose={handleClose}
                onSubmit={handleSubmit(async (data) => {
                    if (datasetNames.includes(data.name)) {
                        return setError('name', { type: 'datasetUniqueName' });
                    }

                    await createDatasetMutation({
                        name: data.name,
                        description: data.description ?? null,
                        selected_products: null,
                        selected_master_products: null,
                    }).unwrap();

                    handleClose();
                    reset();
                })}
                title={t('datasetList.createDataset')}
                isLoading={isLoading}
                cancelText={t('datasetPopUp.cancel')}
                saveText={t('datasetPopUp.save')}
            >
                <TextInput
                    id="dataset-name"
                    label={t('datasetPopUp.datasetName')}
                    variant="outlined"
                    required
                    fieldName={'name'}
                    control={control}
                    showError
                    autoFocus
                />
                <TextInput
                    id="dataset-description"
                    label={t('datasetPopUp.description')}
                    multiline
                    rows={4}
                    fieldName={'description'}
                    control={control}
                />
            </FormDrawer>
        </>
    );
};
