import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Box, Container, Stack } from '@mui/material';

import {
    BackButton,
    ErrorPage,
    type TabProviderTabType,
    TabsProvider,
    Typography,
} from '@xeris/components';
import { datasetsApi } from '@xeris/pages/dataset/api';
import { CreateExportButton } from '@xeris/pages/exportJob/components/CreateExportButton';

import DatasetStatus from '../DatasetList/components/DatasetStatus';

import { DatasetPageLoader } from './components/DatasetPageLoader';
import DatasetToolbar from './components/DatasetToolbar';
import DatasetDetails from './tabs/DatasetDetails';
import DatasetProducts from './tabs/DatasetProducts';

const Dataset = (): ReactElement => {
    const { datasetId = '' } = useParams();
    const { t } = useTranslation('dataset');

    const { data, isLoading } = datasetsApi.useGetDatasetQuery({
        id: datasetId,
    });

    if (isLoading) {
        return <DatasetPageLoader />;
    }

    if (!data?.dataset) {
        return <ErrorPage title={'404'} />;
    }

    const tabList: TabProviderTabType[] = [
        {
            id: 'products',
            Label: <>{t('datasetList.products')}</>,
            Component: <DatasetProducts dataset={data.dataset} />,
        },
        {
            id: 'details',
            Label: <>{t('datasetList.details')}</>,
            Component: <DatasetDetails dataset={data.dataset} />,
        },
    ];

    return (
        <>
            <Stack
                spacing={4}
                alignItems={'center'}
                marginBottom={1}
                justifyContent={'space-between'}
            >
                <BackButton to={`/Dataset`}>
                    {t('datasetList.allDatasets')}
                </BackButton>
                <Box
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        gap: 2,
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Box sx={{ flexShrink: 0 }}>
                        <DatasetStatus
                            dataset={data.dataset}
                            hideIfUpToDate={true}
                        />
                    </Box>
                    <CreateExportButton datasetId={data.dataset.id} />
                </Box>
            </Stack>
            <Typography variant="h1" gutterBottom>
                {data.dataset.name}
            </Typography>
            <Container disableGutters maxWidth={false}>
                <TabsProvider
                    tabList={tabList}
                    toolbar={<DatasetToolbar dataset={data.dataset} />}
                ></TabsProvider>
            </Container>
        </>
    );
};

export default Dataset;
