import { FormControlLabel, styled } from '@mui/material';

export const InputLabelTop = styled(FormControlLabel)(({ theme }) => ({
    'alignItems': 'flex-start',
    'gap': theme.spacing(0.5),
    'flex': 1,
    'margin': theme.spacing(1.5, 0),
    'width': '100%',
    'fontWeight': 'bold',
    '& .MuiFormControlLabel-label': {
        fontWeight: theme.typography.fontWeightMedium,
    },
}));
