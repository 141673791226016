import { type Dispatch, type SetStateAction } from 'react';

type MasterProduct = {
    id: string;
    products: {
        id: string;
    }[];
};

type Counts = {
    productCount: number;
    selectedCount: number;
    selectedProductIds: string[];
    productIds: string[];
    allSelected: boolean;
    partiallySelected: boolean;
    handleToggle: () => void;
};

export const getProductSelectedCount = (
    masterProduct: MasterProduct,
    selectedProducts: string[],
    setSelectedProducts: Dispatch<SetStateAction<string[]>>
): Counts => {
    const productIds = masterProduct.products.map(({ id }) => id);
    const productIdList =
        productIds.length > 0 ? productIds : [masterProduct.id];

    const selectedProductIds = productIdList.filter((id) =>
        selectedProducts.includes(id)
    );

    const productCount = productIdList.length;
    const selectedCount = selectedProductIds.length;

    return {
        productCount: productCount,
        selectedCount: selectedCount,
        productIds: productIdList,
        selectedProductIds: selectedProductIds,
        allSelected: selectedCount === productCount,
        partiallySelected: selectedCount > 0,
        handleToggle: () => {
            if (selectedCount > 0) {
                setSelectedProducts((current) =>
                    current.filter((id) => !selectedProductIds.includes(id))
                );
            } else {
                setSelectedProducts((p) => [...p, ...productIdList]);
            }
        },
    };
};

export const getGroupSelectedCounts = (
    masterProducts: MasterProduct[],
    selectedProducts: string[],
    setSelectedProducts: Dispatch<SetStateAction<string[]>>
): Counts => {
    const sum = masterProducts
        .map((masterProduct) =>
            getProductSelectedCount(
                masterProduct,
                selectedProducts,
                setSelectedProducts
            )
        )
        .reduce<
            Omit<Counts, 'allSelected' | 'partiallySelected' | 'handleToggle'>
        >(
            (result, productStats) => ({
                productCount: result.productCount + productStats.productCount,
                selectedCount:
                    result.selectedCount + productStats.selectedCount,
                productIds: [...result.productIds, ...productStats.productIds],
                selectedProductIds: [
                    ...result.selectedProductIds,
                    ...productStats.selectedProductIds,
                ],
            }),
            {
                productCount: 0,
                selectedCount: 0,
                productIds: [],
                selectedProductIds: [],
            }
        );

    return {
        ...sum,
        allSelected: sum.selectedCount === sum.productCount,
        partiallySelected: sum.selectedCount > 0,
        handleToggle: () => {
            if (sum.selectedCount > 0) {
                setSelectedProducts((ids) =>
                    ids.filter((id) => !sum.selectedProductIds.includes(id))
                );
            } else {
                setSelectedProducts((ids) => [
                    ...new Set([...ids, ...sum.productIds]),
                ]);
            }
        },
    };
};
