import { type Option } from '../types';

import { type ExportFormData } from './exportSchema';

export const formatDefaultValues = (
    options: Option[]
): ExportFormData['options'] =>
    options.map((option) => {
        switch (option.__typename) {
            case 'LanguageConfigurationOption':
                return {
                    string: null,
                    boolean: null,
                    int: null,
                    select: null,
                    language: {
                        key: option.key,
                        askUser: option.askUser ?? false,
                        required: option.required,
                        label: option.label,
                        languages: null,
                        defaultValue: option.defaultLanguage ?? undefined,
                    },
                } as const;
            case 'SelectConfigurationOption':
                return {
                    string: null,
                    boolean: null,
                    int: null,
                    language: null,
                    select: {
                        key: option.key,
                        askUser: option.askUser ?? false,
                        required: option.required,
                        label: option.label,
                        values: option.values,
                        defaultValue: option.defaultOption ?? '',
                    },
                };
            case 'BooleanConfigurationOption':
                return {
                    string: null,
                    select: null,
                    int: null,
                    language: null,
                    boolean: {
                        key: option.key,
                        askUser: option.askUser ?? false,
                        required: option.required,
                        label: option.label,
                        defaultValue: option.defaultBoolean ?? false,
                    },
                };
            case 'IntConfigurationOption':
                return {
                    string: null,
                    select: null,
                    boolean: null,
                    language: null,
                    int: {
                        key: option.key,
                        askUser: option.askUser ?? false,
                        required: option.required,
                        label: option.label,
                        defaultValue: option.defaultInt ?? undefined,
                    },
                };
            case 'StringConfigurationOption':
                return {
                    int: null,
                    select: null,
                    boolean: null,
                    language: null,
                    string: {
                        key: option.key,
                        askUser: option.askUser ?? false,
                        required: option.required,
                        label: option.label,
                        defaultValue: option.defaultString ?? '',
                    },
                };
        }
    }) ?? [];
