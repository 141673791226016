import { type ReactElement } from 'react';

import { Box, Divider, Skeleton } from '@mui/material';

import CategorizedProducts from './CategorizedProducts';
import Header from './Header';

type CategoriesSectionProps = {
    isFetching: boolean;
};
const CategoriesSection = ({
    isFetching,
}: CategoriesSectionProps): ReactElement => {
    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Header isFetching={isFetching} />
            <Divider />
            <Box sx={{ flexGrow: 1 }}>
                {isFetching ? (
                    <Skeleton height={600} />
                ) : (
                    <CategorizedProducts />
                )}
            </Box>
        </Box>
    );
};

export default CategoriesSection;
