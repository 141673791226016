import type { ApiGetProductQuery } from '@xeris/pages/product/api/productApi/productQueries.gql';
import type { Product } from '@xeris/pages/product/types';

import { transformProductEntity } from './utilities';

export const transformProduct = (
    product?: ApiGetProductQuery['product'] | null
): Product<
    | 'name'
    | 'description'
    | 'brand'
    | 'masterProduct'
    | 'gtin'
    | 'images'
    | 'lastUpdated'
    | 'distinguishingFeatures'
    | 'markets'
    | 'relatedProducts'
    | 'price'
    | 'prices'
    | 'productData'
> | null => {
    if (!product) {
        return null;
    }

    return {
        ...product,
        ...transformProductEntity(product),
    };
};
