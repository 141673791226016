import { brandApi as brand } from './brandApi/brandApi';
import { masterProductApi as masterProduct } from './masterProductApi/masterProductApi';
import { productApi as product } from './productApi/productApi';
import { searchApi as search } from './searchApi/searchApi';

export const productApi = {
    brand,
    product,
    masterProduct,
    search,
};
