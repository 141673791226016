import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { useActiveOrganization } from '@xeris/pages/admin/hooks';

import styles from './AccessInfo.module.scss';

const AccessInfoContent = (): ReactElement => {
    const { t } = useTranslation('dataset');

    const activeOrganization = useActiveOrganization();

    return (
        <div className={styles.detailedTooltipContent}>
            <div>
                <Typography
                    className={styles.first}
                    variant={'h6'}
                    component={'h4'}
                >
                    {t('datasetSharingPopUp.edit.header')}
                </Typography>
                <ul>
                    <li>
                        <Typography>
                            {t(
                                'datasetSharingPopUp.edit.organizationUsersOnly',
                                {
                                    organizationName: activeOrganization?.name,
                                }
                            )}
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            {t('datasetSharingPopUp.edit.addAndRemove')}
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            {t(
                                'datasetSharingPopUp.edit.editDuplicateAndExport'
                            )}
                        </Typography>
                    </li>
                </ul>
            </div>
            <div>
                <Typography variant={'h6'} component={'h3'}>
                    {t('datasetSharingPopUp.view.header')}
                </Typography>
                <ul>
                    <li>
                        <Typography>
                            {t('datasetSharingPopUp.view.duplicateAndExport')}
                        </Typography>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default AccessInfoContent;
