import { type FeatureSelection } from '../types/variantGeneratorSliceTypes';

export const updateFeatureSelection = (
    featureSelection: FeatureSelection,
    featureId: string,
    optionIds: string[],
    newValue: boolean
): FeatureSelection => {
    if (newValue) {
        return {
            ...featureSelection,
            [featureId]: {
                ...featureSelection[featureId],
                ...Object.fromEntries(
                    optionIds.map((optionId) => [optionId, true])
                ),
            },
        };
    }

    const options = Object.fromEntries(
        Object.entries(featureSelection[featureId]).filter(
            ([optionId]) => !optionIds.includes(optionId)
        )
    );

    // If there are no selection option, remove the feature altogether
    if (Object.keys(options).length === 0) {
        return Object.fromEntries(
            Object.entries(featureSelection).filter(
                ([filteredId]) => filteredId !== featureId
            )
        );
    }

    return {
        ...featureSelection,
        [featureId]: options,
    };
};
