import { type ApiGetProductSearchDataQuery } from '@xeris/pages/product/api/searchApi/searchQueries.gql';
import { type ProductSearchDataType } from '@xeris/pages/product/types/search';

export const transformSearchData = (
    data: ApiGetProductSearchDataQuery
): ProductSearchDataType => {
    return {
        masterProductList: data.masterProducts.map((masterProduct) =>
            masterProduct
                ? {
                      ...masterProduct,
                      description: null,
                      markets: [],
                      idNumbers: [],
                      products: [],
                  }
                : null
        ),
        productList: data.products.map((product) =>
            product
                ? {
                      ...product,
                      description: null,
                      markets: [],
                      idNumbers: [],
                      distinguishingFeatures: null,
                      gtin: null,
                  }
                : null
        ),
    };
};
