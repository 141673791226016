import { gql } from 'graphql-request';

import { sharedWithFields } from '../datasetQueryFragments';

export const getUsersToShareDatasetWithQuery = gql`
    query GetUsersToShareDatasetWith($organizationId: String!) {
        connectedUsers(organization: $organizationId) {
            id
            username
            name
            organization {
                id
                name
                type
            }
        }
    }
`;

export const shareDatasetMutation = gql`
    mutation ShareDataset(
        $datasetId: String!
        $userIds: [String!]!
        $privilege: SharingPrivilege
    ) {
        dataset {
            sharedWith: share(
                dataset_id: $datasetId
                user_ids: $userIds
                privilege: $privilege
            ) {
                ...SharedWithFields
            }
        }
    }
    ${sharedWithFields}
`;

export const unshareDatasetMutation = gql`
    mutation UnshareDataset($datasetId: String!, $userId: String!) {
        dataset {
            unshare(dataset_id: $datasetId, user_id: $userId) {
                ...SharedWithFields
            }
        }
    }
    ${sharedWithFields}
`;
