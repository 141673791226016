import { baseRtkApi } from '@xeris/reducers/baseApi';

import {
    createExportJobMutation,
    getExportJobQuery,
    getExportJobsQuery,
    getExportsQuery,
} from './exportJobQueries';
import {
    type ApiCreateExportJobMutation,
    type ApiCreateExportJobMutationVariables,
    type ApiGetExportJobQuery,
    type ApiGetExportJobQueryVariables,
    type ApiGetExportJobsQuery,
    type ApiGetExportJobsQueryVariables,
    type ApiGetExportsQuery,
    type ApiGetExportsQueryVariables,
} from './exportJobQueries.gql';

export const exportJobApi = baseRtkApi.injectEndpoints({
    endpoints: (builder) => ({
        createExportJob: builder.mutation<
            ApiCreateExportJobMutation,
            ApiCreateExportJobMutationVariables
        >({
            query: (variables) => ({
                document: createExportJobMutation,
                variables,
            }),
            invalidatesTags: ['exportJobs'],
        }),
        getExportJob: builder.query<
            ApiGetExportJobQuery,
            ApiGetExportJobQueryVariables
        >({
            query: (variables) => ({
                document: getExportJobQuery,
                variables,
            }),
            providesTags: (data) => [
                { type: 'exportJobs', id: data?.exportJob?.id },
            ],
        }),
        getExportJobs: builder.query<
            ApiGetExportJobsQuery,
            ApiGetExportJobsQueryVariables
        >({
            query: (variables) => ({
                document: getExportJobsQuery,
                variables,
            }),
            providesTags: ['exportJobs'],
        }),
        getExports: builder.query<
            ApiGetExportsQuery,
            ApiGetExportsQueryVariables
        >({
            query: (variables) => ({
                document: getExportsQuery,
                variables,
            }),
            providesTags: ['exports'],
        }),
    }),
});
