import type { TFunction } from 'i18next';

import { supportedLanguages } from '@xeris/i18n';

export const supportedLanguageList = supportedLanguages.map((language) => ({
    code: language.code,
    label: language.name,
    id: language.code,
}));

const roles = ['ADMIN', 'USER'] as const;

export const getRoleList = (
    t: TFunction<'administration'>
): { id: (typeof roles)[number]; label: string }[] => {
    return roles.map((role) => ({
        label: t(`users.securityLevel.${role}`),
        id: role,
    }));
};

export const getRole = (role?: string | null): (typeof roles)[number] => {
    const userRole = roles.find((r) => role === r);

    if (userRole) return userRole;

    return 'USER';
};

export const getDefaultLanguage = (
    languageCode?: string | null
): (typeof supportedLanguageList)[number]['code'] => {
    const languages = supportedLanguageList.filter(
        ({ code }) => code === languageCode
    );

    if (languages.length > 0) return languages[0].code;

    return 'en';
};

export const validateDomain = (
    validDomains: string[],
    domain: string,
    superAdmin: boolean
): boolean => {
    if (superAdmin || !validDomains) {
        return true;
    }

    return validDomains.includes(domain.split('@')[1]);
};
