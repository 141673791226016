import { gql } from 'graphql-request';

export const getDataLanguageQuery = gql`
    query GetDataLanguage {
        languages {
            id
            locale
            language
            flag
            description
        }
    }
`;
