import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from '@mui/material';

import type { SelectedModelsDataType } from '../../../types';
import SectionWrapper from '../SectionWrapper/SectionWrapper';

import './index.scss';

type ModelSectionProps = {
    models: SelectedModelsDataType[];
    setData: (model: SelectedModelsDataType[]) => void;
};

const ModelSection = ({ models, setData }: ModelSectionProps): ReactElement => {
    const { t } = useTranslation('product');

    const selectedCount = models.filter((model) => model.isSelected).length;

    return (
        <SectionWrapper
            totalCount={models.length}
            selectedCount={selectedCount}
            title={t('common.panelHeaders.models')}
            handleToggleAll={() => {
                setData(
                    models.map((model) => ({
                        ...model,
                        isSelected: selectedCount < models.length,
                    }))
                );
            }}
        >
            <div className="model-list">
                {models.map((model) => (
                    <div className="model-list-item" key={model.id}>
                        <Checkbox
                            key={model.id}
                            checked={model.isSelected}
                            onChange={(event, data) => {
                                setData(
                                    models.map((m) =>
                                        model.id === m.id
                                            ? { ...model, isSelected: data }
                                            : model
                                    )
                                );
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        <div className="model-card-wrapper">
                            <div className="model-card">
                                <div className="model-card-info">
                                    {model.type}
                                    <br />
                                    <span className="model-card-title">
                                        {model.title || model.fileName || ''}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </SectionWrapper>
    );
};

export default ModelSection;
