import type { HTMLAttributes, ReactElement, ReactNode } from 'react';
import {
    type Control,
    type FieldValues,
    type Path,
    useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    Autocomplete,
    type AutocompleteProps,
    FormControl,
    FormHelperText,
    TextField,
} from '@mui/material';
import { type AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';

type AutocompleteInputProps<
    T extends FieldValues,
    Option extends { id: string; label: string },
> = {
    fieldName: Path<T>;
    label?: string;
    showError?: boolean;
    control: Control<T>;
    options: Option[];
    size?: AutocompleteProps<T, true, false, false>['size'];

    groupBy?: (options: Option) => string;
    renderOption?: (
        props: HTMLAttributes<HTMLLIElement>,
        option: Option,
        state: AutocompleteRenderOptionState
    ) => ReactNode;
};

export const AutocompleteInput = <
    T extends FieldValues,
    Option extends { id: string; label: string },
>({
    fieldName,
    label,
    showError = false,
    control,
    options,
    size,
    groupBy,
    renderOption,
}: AutocompleteInputProps<T, Option>): ReactElement | null => {
    const { t } = useTranslation('common');

    const {
        field: { ref, onChange, ...field },
        fieldState: { error },
    } = useController({
        name: fieldName,
        control,
    });

    return (
        <FormControl fullWidth>
            <Autocomplete
                disablePortal
                options={options}
                fullWidth
                getOptionLabel={(option): string => option.label}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(_, data): void => onChange(data?.id)}
                size={size}
                groupBy={groupBy}
                renderOption={renderOption}
                renderInput={(params): ReactElement => (
                    <TextField
                        {...params}
                        {...field}
                        error={Boolean(error)}
                        inputRef={ref}
                        label={label}
                    />
                )}
            />
            {showError && (
                <FormHelperText>
                    {error?.message &&
                        t(`formErrors.${error.message}`, {
                            defaultValue: '',
                            fieldName: fieldName,
                        })}
                </FormHelperText>
            )}
        </FormControl>
    );
};
