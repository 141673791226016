import { type FeatureGroup } from '@xeris/pages/product/types';
import { type Features } from '@xeris/pages/product/variantGenerator/types/variantGeneratorSliceTypes';

export const getFeatureList = (featureGroups: FeatureGroup[]): Features =>
    featureGroups.flatMap((featureGroup) =>
        featureGroup.features.map((feature) => ({
            id: feature.id,
            name: feature.name,
            featureGroupId: featureGroup.id,
            optionIds:
                feature.__typename === 'BasicFeature'
                    ? feature.options.map((option) => ({
                          id: option.id,
                          name: option.name,
                      }))
                    : feature.materials.flatMap((material) =>
                          material.options.map((option) => ({
                              id: option.id,
                              name: option.name,
                          }))
                      ),
        }))
    );
