import { baseRtkRestApi } from '@xeris/reducers/baseRestApi';

export const fileUploadApi = baseRtkRestApi.injectEndpoints({
    endpoints: (builder) => ({
        getSignedUploadUrl: builder.mutation<
            { url: string },
            { uploadFormat: string; formatUrl: string }
        >({
            query: ({ uploadFormat, formatUrl }) =>
                `/upload-api/${uploadFormat}/${formatUrl}`,
        }),
        getSignedUploadBrandUrl: builder.mutation<
            { url: string; targetUrl: string },
            { brandId: string; imageName: string }
        >({
            query: ({ brandId, imageName }) =>
                `/upload-brand/${brandId}/${imageName}`,
            transformResponse: (data: { url: string; target_url: string }) => ({
                url: data.url,
                targetUrl: data.target_url,
            }),
        }),
        uploadFile: builder.mutation<
            { [key: string]: never },
            { url: string; file: File }
        >({
            query: ({ url, file }) => ({
                url: url,
                body: file,
                method: 'PUT',
            }),
        }),
    }),
});
