import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Alert } from '@mui/material';

type NoProductsFoundProps = {
    overrideLink?: string;
};

export const NoProductsFound = ({
    overrideLink,
}: NoProductsFoundProps): ReactElement => {
    const { t } = useTranslation('product');
    const link = overrideLink ?? '/Products';

    return (
        <Alert
            severity="info"
            action={<Link to={link}>{t('productList.back')}</Link>}
        >
            {t('productList.noProductsFound')}
        </Alert>
    );
};
