import { createSlice } from '@reduxjs/toolkit';

import { type ProductsSliceType } from '@xeris/pages/product/types/productTypes';

import { productApi } from '../api';

const initialState: ProductsSliceType = {
    activeProduct: undefined,
    activeMasterProduct: undefined,
};

export const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Master products
        builder.addMatcher(
            productApi.masterProduct.endpoints.getMasterProduct.matchFulfilled,
            (state, action) => {
                const masterProduct = action.payload;

                state.activeMasterProduct = masterProduct;
            }
        );

        // Product
        builder.addMatcher(
            productApi.product.endpoints.getProduct.matchFulfilled,
            (state, action) => {
                const product = action.payload;

                state.activeProduct = product;
            }
        );
    },
});
