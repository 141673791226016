import { gql } from 'graphql-request';

export const getImportBatchesQuery = gql`
    query getImportBatches {
        batches {
            id
            source
            status
            startTime: start_time
            endTime: end_time
            count
            summary
            progress
            partial
            next
            duration
            errors
            filename
            downloadLink: download_link
        }
    }
`;

export const getImportsQuery = gql`
    query getImports {
        importers {
            id
            name
            accept
        }
    }
`;
