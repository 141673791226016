import * as yup from 'yup';

export const datasetSchema = yup
    .object({
        name: yup.string().required('required'),
        description: yup.string(),
    })
    .required();

export type DatasetFormData = yup.InferType<typeof datasetSchema>;
