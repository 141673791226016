import { useMemo } from 'react';

import { connectionsApi } from '@xeris/pages/admin/api';

export type DataEntry = {
    id: string;
    name: string;
    label: string;
};

export type FormInputData = {
    exportConfigList: DataEntry[];
};

export const useGetFormData = (
    producerId: string | null | undefined,
    consumerId: string | null | undefined,
    connectionType: string,
    isEditing: boolean
): FormInputData & { isFetching: boolean } => {
    const {
        data: connectionFormData,
        isFetching,
        isError,
    } = connectionsApi.useGetConnectionFormDataQuery(
        {
            organizationId: producerId ?? null,
            partnerType:
                connectionType === 'channels' ? 'CONSUMER' : 'PRODUCER',
            isCreatingChannel: connectionType === 'channels',
            tradingPartnerId: consumerId ?? null,
            isEditing: isEditing,
        },
        { skip: !producerId || (isEditing && !consumerId) }
    );

    return useMemo(() => {
        const exportConfigList =
            connectionFormData?.exportConfigs?.map((config) => ({
                ...config,
                label: config.name,
            })) ?? [];

        return {
            exportConfigList,
            isFetching,
            isError,
        };
    }, [connectionFormData, isFetching, isError]);
};
