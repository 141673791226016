import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Collapse, Typography } from '@mui/material';

import { UpDownChevronIcon } from '@xeris/components';
import { settingsSelectors } from '@xeris/pages/product/reducers/settingsSlice';
import { type ProductDataList } from '@xeris/pages/product/types';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';
import { togglePanel } from '@xeris/utilities/reducers/collapse';

import {
    DescriptionsContent,
    Documentations,
    EnvironMental,
    GpcAttributes,
    IdNumbers,
    Markets,
    Measurements,
    Media,
    Models,
    Packaging,
    Specifications,
    Sustainability,
    Texts,
} from './components';

import './index.scss';

type PanelData = {
    data: ProductDataList[number];
    showMissingData: boolean;
};

const Panel = ({ data, showMissingData }: PanelData): ReactElement => {
    switch (data.type) {
        case 'models':
            return <Models data={data.data} />;
        case 'descriptions':
            return <DescriptionsContent data={data.data} />;
        case 'documentation':
            return <Documentations data={data.data} />;
        case 'idNumbers':
            return <IdNumbers data={data.data} />;
        case 'markets':
            return <Markets data={data.data} />;
        case 'media':
            return <Media data={data.data} />;
        case 'packaging':
            return <Packaging data={data.data} />;
        case 'physicalMeasurements':
            return <Measurements data={data.data} />;
        case 'specifications':
            return <Specifications data={data.data} />;
        case 'gpcAttributes':
            return <GpcAttributes data={data.data} />;
        case 'texts':
            return <Texts data={data.data} />;
        case 'sustainabilityData':
            return <Sustainability data={data.data} />;
        case 'environmental':
            return (
                <EnvironMental
                    data={data.data}
                    showMissingData={showMissingData}
                />
            );
    }
};

type ProductDataFactoryProps = {
    productData: ProductDataList[number];
    panelListId?: string;
};

const ProductDataFactory = ({
    productData,
    panelListId = '',
}: ProductDataFactoryProps): ReactElement | null => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('product');

    const showMissingData = useAppSelector(
        settingsSelectors.selectShowMissingProductData
    );

    const activeFlag = showMissingData || productData.hasData;

    const collapse = useAppSelector((state) => state.collapse);

    const isCollapsed =
        activeFlag && collapse?.[panelListId]?.table[productData.type];

    const handleToggleCollapse = (): void => {
        dispatch(
            togglePanel({ listId: panelListId, panelId: productData.type })
        );
    };

    if (!showMissingData && !productData.hasData) {
        return null;
    }

    if (productData.type === 'environmental') {
        return (
            <EnvironMental
                data={productData.data}
                showMissingData={showMissingData}
            />
        );
    }

    return (
        <div className="content-factory-item-wrapper">
            <Button
                className="content-type-header-button"
                color="primary"
                onClick={handleToggleCollapse}
            >
                <Typography component={'h2'} variant={'h3'}>
                    {t(`common.panelHeaders.${productData.type}`)}
                </Typography>
                <UpDownChevronIcon isCollapsed={isCollapsed} />
            </Button>
            <Collapse in={!isCollapsed} unmountOnExit sx={{ paddingBottom: 4 }}>
                {productData.hasData ? (
                    <Panel
                        data={productData}
                        showMissingData={showMissingData}
                    />
                ) : (
                    <div className="missing-data-panel">
                        {t('datasheet.missingData')}
                    </div>
                )}
            </Collapse>
        </div>
    );
};

export default ProductDataFactory;
