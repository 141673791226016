import { useEffect, useMemo, useState } from 'react';

export type ImageInfoType = {
    width: number;
    height: number;
    isHorizontal: boolean;
};

const useImageNaturalSize = (imageSrc?: string): ImageInfoType => {
    const [info, setInfo] = useState({
        width: 0,
        height: 0,
        isHorizontal: false,
    });

    const img = useMemo(() => new window.Image(), []);

    useEffect(() => {
        if (!img.src) return;

        img.onload = (): void => {
            const { naturalWidth, naturalHeight } = img;

            if (naturalWidth !== info.width || naturalHeight !== info.height) {
                setInfo({
                    width: naturalWidth,
                    height: naturalHeight,
                    isHorizontal: naturalWidth > naturalHeight,
                });
            }
        };

        return (): void => {
            img.onload = null;
        };
    }, [imageSrc, img, info.height, info.width]);

    if (!imageSrc) return { width: 0, height: 0, isHorizontal: false };

    img.src = imageSrc;

    return info;
};

export default useImageNaturalSize;
