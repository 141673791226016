import { type ApiCreateExportMutationVariables } from '../../api/exportsQueries.gql';

import { type ExportFormData } from './exportSchema';

export const formatOptionsForApi = (
    options: ExportFormData['options']
): ApiCreateExportMutationVariables['export']['options'] =>
    options.map((option) => ({
        int: option.int
            ? {
                  key: option.int.key,
                  label: null,
                  askUser: option.int.askUser,
                  defaultValue: option.int.defaultValue ?? null,
              }
            : null,
        select: option.select
            ? {
                  key: option.select.key,
                  label: null,
                  askUser: option.select.askUser,
                  defaultValue: option.select.defaultValue || null,
                  values: null,
              }
            : null,
        boolean: option.boolean
            ? {
                  key: option.boolean.key,
                  label: null,
                  askUser: option.boolean.askUser,
                  defaultValue: option.boolean.defaultValue || null,
              }
            : null,
        language: option.language
            ? {
                  key: option.language.key,
                  label: null,
                  askUser: option.language.askUser,
                  defaultValue: option.language.defaultValue || null,
                  languages: null,
              }
            : null,
        string: option.string
            ? {
                  key: option.string.key,
                  label: null,
                  askUser: option.string.askUser,
                  defaultValue: option.string.defaultValue || null,
              }
            : null,
    }));
