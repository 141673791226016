import { baseRtkApi } from '@xeris/reducers/baseApi';

import { searchProductsDataQuery, searchProductsQuery } from './searchQueries';
import {
    type ApiGetProductSearchDataQuery,
    type ApiGetProductSearchDataQueryVariables,
    type ApiSearchProductsQuery,
    type ApiSearchProductsQueryVariables,
} from './searchQueries.gql';
import { transformSearchData } from './searchTransformers';

export const searchApi = baseRtkApi.injectEndpoints({
    endpoints: (builder) => ({
        searchProducts: builder.query<
            ApiSearchProductsQuery,
            ApiSearchProductsQueryVariables
        >({
            query: (variables) => ({
                document: searchProductsQuery,
                variables,
            }),
        }),
        searchProductData: builder.query<
            ReturnType<typeof transformSearchData>,
            ApiGetProductSearchDataQueryVariables
        >({
            query: (variables) => ({
                document: searchProductsDataQuery,
                variables,
            }),
            transformResponse: (data: ApiGetProductSearchDataQuery) =>
                transformSearchData(data),
        }),
    }),
});
