import { type ReactElement } from 'react';

import { Skeleton } from '@mui/material';

import { Typography } from '@xeris/components';
import { useAppSelector } from '@xeris/reducers';

import { categorizationSelectors } from '../reducers/categorizationSelectors';

type HeaderProps = {
    isFetching: boolean;
};

const Header = ({ isFetching }: HeaderProps): ReactElement => {
    const configurationName = useAppSelector(
        categorizationSelectors.selectConfigurationName
    );
    const { categorizedCount } = useAppSelector(
        categorizationSelectors.selectProductCounts
    );

    if (isFetching) {
        return <Skeleton height={28} width={400} />;
    }

    return (
        <Typography variant={'h2'} sx={{ lineHeight: '38px' }}>
            {`${configurationName} (${categorizedCount})`}
        </Typography>
    );
};

export default Header;
