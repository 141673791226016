import { type MutableRefObject, useEffect, useState } from 'react';

import { useResizeObserver } from '@xeris/hooks';

import type { Model3DType } from '../Model3D';

type UseModel3DEventsType = (
    containerRef: MutableRefObject<HTMLDivElement | null>,
    model3DInstance: Model3DType | undefined,
    model: string | undefined,
    onModelReadyToLoad: (renderStarted: boolean) => Promise<void>,
    onSelectionChange: () => void,
    onResize: () => void,
    dependencies: unknown[]
) => void;

const useModel3DEvents: UseModel3DEventsType = (
    containerRef,
    model3DInstance,
    model,
    onModelReadyToLoad,
    onSelectionChange,
    onResize,
    dependencies
) => {
    const [loadingModel, setLoadingModel] = useState(false);
    const [modelLoaded, setModelLoaded] = useState(false);
    const [renderStarted, setRenderStarted] = useState(false);

    useResizeObserver(containerRef, () => {
        if (!modelLoaded || !renderStarted || !model3DInstance) {
            return;
        }

        onResize();
    });

    // this should run only once when all the props are ready
    useEffect(() => {
        if (modelLoaded || loadingModel || !model3DInstance || !model) return;

        setLoadingModel(true);
    }, [loadingModel, model, model3DInstance, modelLoaded]);

    useEffect(() => {
        if (loadingModel) {
            onModelReadyToLoad(renderStarted).then(() => {
                setModelLoaded(true);
                setLoadingModel(false);

                if (!renderStarted) {
                    setRenderStarted(true);
                }
            });
        }
        // we want to be sure to run this useEffect only once, actually when the model changes and all
        // the needed deps are ready to be used (see the previous useEffect), without splitting this in
        // two useEffect and run this only when loadingModel turns from false to true, it will cause to
        // run two times because of the async way useEffect works
    }, [loadingModel]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (model3DInstance && modelLoaded && renderStarted) {
            onSelectionChange();
        }
        // We want to run this only when removeComponentsByFeature and materialsByFeature
        // change, adding all the other deps will cause to run it at the beginning
        // which would be a mistake, because loadMaterials and toggleComponents already
        // run right after the loadModel method
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
};

export default useModel3DEvents;
