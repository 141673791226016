import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, type TypographyProps } from '@mui/material';

import { FormattedPrice, Typography } from '@xeris/components';
import { useAppSelector } from '@xeris/reducers';

import { variantGeneratorSelectors } from '../../reducer';
import { type Stage } from '../../VariantGeneratorWrapper/VariantGeneratorWrapper';

type PriceColumnProps = {
    value: number;
    title: string;
    className?: string;
    currency?: string | null;
    typographyProps?: TypographyProps;
};

type PriceDetailsProps = {
    stage: Stage;
    basePrice:
        | {
              value: number;
              currency: string | null | undefined;
          }
        | null
        | undefined;
};

const PriceColumn = ({
    value,
    title,
    currency,
    typographyProps,
}: PriceColumnProps): ReactElement => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
            }}
        >
            <FormattedPrice
                value={value}
                currency={currency}
                typographyProps={{ textAlign: 'left', ...typographyProps }}
            />
            <Typography variant={'body2'}>{title}</Typography>
        </Box>
    );
};

const PriceDetails = ({
    stage,
    basePrice,
}: PriceDetailsProps): ReactElement => {
    const { t } = useTranslation('product');

    const price = useAppSelector(variantGeneratorSelectors.selectPrices);

    // There are complications with min/max, and they are not included in the JS library
    // We may add them back later if we choose to
    const minimum = 0;
    const maximum = 0;
    const hideMinMax = true;

    return (
        <Box
            sx={{
                display: 'grid',
                gridAutoColumns: '1fr',
                gridAutoFlow: 'column',
                gap: 1,
                border: 1,
                borderColor: 'divider',
                borderRadius: '6px',
                padding: 2,
            }}
        >
            {!!basePrice && stage !== 'summary' && (
                <PriceColumn
                    title={t('variantGenerator.base')}
                    value={basePrice.value}
                    currency={basePrice.currency}
                />
            )}

            {!hideMinMax && (
                <PriceColumn
                    title={t('variantGenerator.min')}
                    value={minimum}
                    currency={price?.currency}
                />
            )}

            {!hideMinMax && (
                <PriceColumn
                    title={t('variantGenerator.max')}
                    value={maximum}
                    currency={price?.currency}
                />
            )}

            <PriceColumn
                title={t('variantGenerator.previewPriceEXCL')}
                typographyProps={{
                    color: 'primary',
                    variant: 'h3',
                    component: 'p',
                }}
                value={price?.value ?? 0}
                currency={price?.currency}
            />
        </Box>
    );
};

export default PriceDetails;
