import { useEffect, useRef, useState } from 'react';

import type { Model3DModuleType } from '../Model3D';

const useLazyLoadedModel3D = (): Model3DModuleType | undefined => {
    const moduleModel3D = useRef<Model3DModuleType | undefined>();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (loaded) return;
        import('../Model3D').then((_module) => {
            moduleModel3D.current = _module.default;
            setLoaded(true);
        });
    });

    return moduleModel3D.current;
};

export default useLazyLoadedModel3D;
