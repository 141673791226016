import { organizationsApi } from '@xeris/pages/admin/api';

import { type OrganizationType } from '../Organization/types/organizationsTypes';

import { useActiveOrganizationId } from './useActiveOrganizationId';

export const useActiveOrganization = (): OrganizationType | null => {
    const organizationId = useActiveOrganizationId();

    const { organization } = organizationsApi.useGetOrganizationsQuery(
        {},
        {
            selectFromResult: ({ data }) => ({
                organization: data?.organizations.find(
                    ({ id }) => id === organizationId
                ),
            }),
        }
    );

    return organization ?? null;
};
