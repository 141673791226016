export const getSubPaths = (path?: string): string[] => {
    if (!path) return [];

    const elements = path.split('//');

    const subPaths: string[] = [];

    for (let i = 0; i < elements.length; i++) {
        subPaths.push(elements.slice(0, i + 1).join('//'));
    }

    return subPaths;
};
