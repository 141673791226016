import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Chip, type ChipProps } from '@mui/material';

type RegistrationStatusChipsProps = {
    registrationStatus: string;
};

type ChipType = {
    text: string;
    color: ChipProps['color'];
};

const RegistrationStatusChips = ({
    registrationStatus,
}: RegistrationStatusChipsProps): ReactElement => {
    const { t } = useTranslation('administration');

    const chipLookupTable: Record<string, ChipType> = {
        Invited: {
            text: t('users.registrationStatus.invited'),
            color: 'pending',
        },
        Added: {
            text: t('users.registrationStatus.invited'),
            color: 'pending',
        },
        Registered: {
            text: t('users.registrationStatus.active'),
            color: 'primary',
        },
        Unknown: {
            text: t('users.registrationStatus.unknown'),
            color: 'info',
        },
    };

    const chip =
        chipLookupTable[registrationStatus] ?? chipLookupTable['Unknown'];

    return <Chip size={'small'} label={chip.text} color={chip.color} />;
};

export default RegistrationStatusChips;
