/*
 * Convert a string to a deterministic CSS HSL color string with the given saturation and lightness
 */
export const stringToColor = (
    string: string,
    saturation = 42,
    lightness = 50
): string => {
    let hash = 0;

    for (let i = 0; i < (string || '').length; i++) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    const hue = ((hash % 360) + 360) % 360;

    return 'hsl(' + hue + ', ' + saturation + '%, ' + lightness + '%)';
};
