import type { ReactElement } from 'react';

import {
    BasicFeatureSummary,
    BasicFeatureSummaryHeader,
    BasicFeatureSummaryOptionList,
    BasicOptionSummaryCard,
} from '@xeris/components/variantGenerator';
import {
    type BasicFeature,
    type BasicOption,
} from '@xeris/pages/product/types';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';

import { Price } from '../../../Common';
import {
    selectOptions,
    selectPreview,
    variantGeneratorSelectors,
} from '../../../reducer';

type OptionCardProps = {
    option: BasicOption;
    featureId: string;
};

type BasicPanelProps = {
    feature: BasicFeature;
};

const OptionCard = ({
    featureId,
    option,
}: OptionCardProps): ReactElement | null => {
    const dispatch = useAppDispatch();

    const { isSelected, isPreviewed } = useAppSelector((state) =>
        variantGeneratorSelectors.optionStatus(state, featureId, option.id)
    );

    if (!isSelected) {
        return null;
    }

    const handleSelectPreview = (): void => {
        dispatch(
            selectPreview({
                optionId: option.id,
                featureId: featureId,
            })
        );
    };

    const handleDeselectOption = (): void => {
        dispatch(
            selectOptions({
                featureId: featureId,
                optionIds: [option.id],
                newValue: false,
            })
        );
    };

    return (
        <BasicOptionSummaryCard
            name={option.name}
            image={option.image ?? ''}
            isPreviewed={isPreviewed}
            isPreviewable={true}
            handleSelectPreview={handleSelectPreview}
            handleRemoveOption={handleDeselectOption}
        >
            <Price price={option.price} />
        </BasicOptionSummaryCard>
    );
};

const BasicPanel = ({ feature }: BasicPanelProps): ReactElement | null => {
    const { isSelected } = useAppSelector((state) =>
        variantGeneratorSelectors.featureStatus(state, feature.id)
    );

    if (!isSelected || !feature.options || feature.options.length === 0) {
        return null;
    }

    return (
        <BasicFeatureSummary>
            <BasicFeatureSummaryHeader name={feature.name} />
            <BasicFeatureSummaryOptionList>
                {feature.options.map(
                    (option): ReactElement => (
                        <OptionCard
                            key={option.id}
                            featureId={feature.id}
                            option={option}
                        />
                    )
                )}
            </BasicFeatureSummaryOptionList>
        </BasicFeatureSummary>
    );
};

export default BasicPanel;
