import { type ReactElement } from 'react';

import { Box } from '@mui/material';

import Menu from './Menu/Menu';
import { type MenuElementWithLevelType } from './types';

type NavigationProps = {
    menuElements: MenuElementWithLevelType[];
};

export const Navigation = ({ menuElements }: NavigationProps): ReactElement => {
    return (
        <Box component="nav" id="main-nav" sx={{ flexGrow: 1 }}>
            <Menu elements={menuElements} />
        </Box>
    );
};
