import { type MutableRefObject, useEffect, useState } from 'react';

import type { Model3DType } from '../Model3D';

import useLazyLoadedModel3D from './useLazyLoadedModel3D';

const useModel3DInstance = (
    canvas: MutableRefObject<HTMLCanvasElement | null>
): Model3DType | undefined => {
    const [initialized, setInitialized] = useState(false);
    const [instance, setInstance] = useState<Model3DType>();

    const Model3DModule = useLazyLoadedModel3D();

    useEffect(() => {
        if (!canvas.current || initialized || !Model3DModule) return;

        const _instance = new Model3DModule(canvas.current);

        setInstance(_instance);
        setInitialized(true);
    }, [canvas.current, initialized, Model3DModule]); // eslint-disable-line react-hooks/exhaustive-deps

    return instance;
};

export default useModel3DInstance;
