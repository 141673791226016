import { memo, type ReactElement } from 'react';

import {
    BasicFeatureHeader,
    BasicFeaturePanel,
} from '@xeris/components/variantGenerator';
import { type BasicFeature } from '@xeris/pages/product/types';
import { useAppSelector } from '@xeris/reducers';

import { variantGeneratorSelectors } from '../../../reducer';

import FeatureOptionList from './FeatureOptionList';

type BasicPanelProps = {
    feature: BasicFeature;
};

const BasicPanel = ({ feature }: BasicPanelProps): ReactElement | null => {
    const { isVisible } = useAppSelector((state) =>
        variantGeneratorSelectors.featureStatus(state, feature.id)
    );

    const { available, selected } = useAppSelector((state) =>
        variantGeneratorSelectors.selectedOptionCount(state, feature.id)
    );

    if (!isVisible) {
        return null;
    }

    return (
        <BasicFeaturePanel>
            <BasicFeatureHeader
                hash={feature.id}
                name={feature.name}
                description={feature.description}
                selectedCount={selected}
                availableCount={available}
            />
            <FeatureOptionList feature={feature} />
        </BasicFeaturePanel>
    );
};

export default memo(BasicPanel);
