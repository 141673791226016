import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
    FormControl,
    MenuItem,
    Select,
    type SxProps,
    Typography,
} from '@mui/material';

import { supportedLanguages } from '@xeris/i18n/supportedLanguages';
import { useChangeLanguage } from '@xeris/i18n/useChangeLanguage';

type PlatformLanguageSelectorProps = {
    sx?: SxProps;
};
const PlatformLanguageSelector = ({
    sx,
}: PlatformLanguageSelectorProps): ReactElement => {
    const { i18n, t } = useTranslation('common');
    const handleChangeLanguage = useChangeLanguage();

    const currentLanguage = supportedLanguages.find(
        (language) => language.code === i18n.language
    );

    return (
        <FormControl>
            <Select
                value={currentLanguage?.code ?? ''}
                size={'small'}
                sx={sx}
                aria-label={t(
                    'components.languageSelector.selectPlatformLanguage'
                )}
                renderValue={(): ReactElement => (
                    <Typography variant="inherit" noWrap>
                        {currentLanguage?.name}
                    </Typography>
                )}
                onChange={(event) => handleChangeLanguage(event.target.value)}
            >
                {supportedLanguages.map((language) => (
                    <MenuItem key={language.code} value={language.code}>
                        <Typography variant="inherit" noWrap>
                            {language.name}
                        </Typography>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default PlatformLanguageSelector;
