import { baseRtkApi } from '@xeris/reducers/baseApi';

import {
    createExportMutation,
    deleteExportMutation,
    editExportMutation,
    getExportTypesQuery,
} from './exportsQueries';
import {
    type ApiCreateExportMutation,
    type ApiCreateExportMutationVariables,
    type ApiDeleteExportMutation,
    type ApiDeleteExportMutationVariables,
    type ApiEditExportMutation,
    type ApiEditExportMutationVariables,
    type ApiGetExportTypesQuery,
    type ApiGetExportTypesQueryVariables,
} from './exportsQueries.gql';

export const exportsApi = baseRtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getExportTypes: builder.query<
            ApiGetExportTypesQuery,
            ApiGetExportTypesQueryVariables
        >({
            query: (variables) => ({
                document: getExportTypesQuery,
                variables,
            }),
        }),
        createExport: builder.mutation<
            ApiCreateExportMutation,
            ApiCreateExportMutationVariables
        >({
            query: (variables) => ({
                document: createExportMutation,
                variables,
            }),
            invalidatesTags: ['exports'],
        }),
        editExport: builder.mutation<
            ApiEditExportMutation,
            ApiEditExportMutationVariables
        >({
            query: (variables) => ({
                document: editExportMutation,
                variables,
            }),
            invalidatesTags: ['exports'],
        }),
        deleteExport: builder.mutation<
            ApiDeleteExportMutation,
            ApiDeleteExportMutationVariables
        >({
            query: (variables) => ({
                document: deleteExportMutation,
                variables,
            }),
            invalidatesTags: ['exports'],
        }),
    }),
});
