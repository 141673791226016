import type { ReactElement } from 'react';

import { Typography } from '@mui/material';

import { type MasterProduct } from '@xeris/pages/product/types';

import { VariantsCounter } from '../Common';
import { type Stage } from '../VariantGeneratorWrapper/VariantGeneratorWrapper';

import ImageStack from './ImageStack/ImageStack';
import PriceDetails from './PriceDetails/PriceDetails';
import Viewer3D from './Viewer3D/Viewer3D';

import styles from './Preview.module.scss';

type PreviewProps = {
    stage: Stage;
    masterProduct: MasterProduct<
        'name' | 'brand' | 'images' | 'featureConfiguration'
    >;
};

const Preview = ({ stage, masterProduct }: PreviewProps): ReactElement => {
    const footerClassNames = [styles.footer];

    if (stage === 'summary') {
        footerClassNames.push(styles.grid);
    }

    const preview = masterProduct.featureConfiguration?.preview;

    return (
        <div className={styles.previewBoxContainer}>
            <div className={styles.previewBox}>
                <div>
                    {masterProduct.brand && (
                        <Typography variant={'subtitle2'} component={'span'}>
                            {masterProduct.brand.name}
                        </Typography>
                    )}
                    {masterProduct.name && (
                        <Typography variant={'h1'}>
                            {masterProduct.name}
                        </Typography>
                    )}
                </div>
                {!preview && (
                    <ImageStack
                        productImage={masterProduct.images?.[0]}
                        preview={null}
                    />
                )}
                {preview?.__typename == 'PreviewConfigurationImage' && (
                    <ImageStack
                        productImage={masterProduct.images?.[0]}
                        preview={preview}
                    />
                )}
                {preview?.__typename === 'PreviewConfiguration3D' && (
                    <Viewer3D
                        options={
                            masterProduct.featureConfiguration?.featureGroups.flatMap(
                                (featureGroup) =>
                                    featureGroup.features.flatMap((feature) =>
                                        feature.__typename === 'BasicFeature'
                                            ? feature.options.map(
                                                  ({ id, image }) => ({
                                                      id,
                                                      image,
                                                  })
                                              )
                                            : feature.materials.flatMap(
                                                  (material) =>
                                                      material.options.map(
                                                          ({ id, image }) => ({
                                                              id,
                                                              image,
                                                          })
                                                      )
                                              )
                                    )
                            ) ?? []
                        }
                        preview={preview}
                    />
                )}

                <div className={footerClassNames.join(' ')}>
                    {stage !== 'configure' && (
                        <VariantsCounter
                            totalVariantCount={
                                masterProduct.featureConfiguration
                                    ?.variantCount ?? null
                            }
                        />
                    )}

                    {stage !== 'selectData' && (
                        <PriceDetails
                            stage={stage}
                            basePrice={
                                masterProduct.featureConfiguration?.basePrice ??
                                null
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Preview;
