import { type ApiConfigurationSetInput } from '@xeris/types/graphql';

import { type SelectedDataType } from '../types';

const moveIdToFront = <T extends { id: string }>(
    array: T[],
    idToMove: string | null
): T[] => {
    if (!idToMove || array.length <= 1) {
        return array;
    }

    const element = array.find(({ id }) => id === idToMove);

    if (!element) {
        return array;
    }

    return [element, ...array.filter(({ id }) => id !== idToMove)];
};

export const getOptionalProductData = (
    data: Omit<SelectedDataType, 'isSelected'>
): Pick<ApiConfigurationSetInput, 'images' | 'documents' | 'models'> => {
    const optionalProductData = {
        images: data.images.list
            .filter((image) => image.isSelected)
            .map(({ id }) => ({ id })),
        documents: data.documents
            .filter((document) => document.isSelected)
            .map(({ id }) => ({ id })),
        models: data.models
            .filter((model) => model.isSelected)
            .map(({ id }) => ({ id })),
    };

    return {
        images:
            optionalProductData.images.length === data.images.list.length
                ? null
                : moveIdToFront(
                      optionalProductData.images,
                      data.images.primaryImageId
                  ),
        documents:
            optionalProductData.documents.length === data.documents.length
                ? null
                : optionalProductData.documents,
        models:
            optionalProductData.models.length === data.models.length
                ? null
                : optionalProductData.models,
    };
};
