import { type ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';

import { MutationSnackbars } from '@xeris/components';
import { FormDrawer, SelectInput, TextInput } from '@xeris/components/forms';

import { exportsApi } from '../api/exportsApi';

import { Configuration } from './components/Configuration';
import { OptionField } from './fields/OptionField';
import { type ExportFormData, exportSchema } from './utilities/exportSchema';
import { formatDefaultValues } from './utilities/formatDefaultValues';
import { formatOptionsForApi } from './utilities/formatOptionsForApi';
import { type ExportData } from './types';

type CreateExportDialogProps = {
    isOpen: boolean;
    handleCloseDialog: () => void;
    exportNames: string[];
    exportData?: ExportData;
};

export const EditExport = ({
    isOpen,
    handleCloseDialog,
    exportNames,
    exportData,
}: CreateExportDialogProps): ReactElement => {
    const { t } = useTranslation('administration');

    const { handleSubmit, control, reset, setError } = useForm<ExportFormData>({
        resolver: yupResolver(exportSchema),
        defaultValues: {
            name: '',
            description: '',
            type: '',
            options: [],
        },
    });

    const [editExport, { isSuccess, isError, isLoading }] =
        exportsApi.useEditExportMutation();

    useEffect(() => {
        reset({
            name: exportData?.name ?? '',
            description: exportData?.description ?? '',
            type: exportData?.type.id ?? '',
            options: formatDefaultValues(exportData?.options ?? []),
        });
    }, [exportData, reset]);

    const handleSubmitExport = async (data: ExportFormData): Promise<void> => {
        if (exportNames.includes(data.name)) {
            return setError('name', { type: 'unique', message: data.name });
        }

        await editExport({
            exportId: exportData?.id ?? '',
            export: {
                name: data.name,
                description: data.description ?? null,
                type: data.type,
                options: formatOptionsForApi(data.options),
            },
        }).unwrap();

        reset();
        handleCloseDialog();
    };

    return (
        <>
            <FormDrawer
                open={isOpen}
                onClose={handleCloseDialog}
                onSubmit={handleSubmit(handleSubmitExport)}
                isLoading={isLoading}
                title={t('exports.edit.editExport')}
                saveText={t('exports.edit.save')}
                cancelText={t('common.cancel')}
            >
                <TextInput
                    control={control}
                    fieldName={'name'}
                    label={t('exports.form.name')}
                    showError
                />
                <TextInput
                    control={control}
                    fieldName={'description'}
                    label={t('exports.form.description')}
                    multiline
                />
                <SelectInput
                    control={control}
                    fieldName={'type'}
                    label={t('exports.form.type')}
                    showError
                    optionLabelField={'name'}
                    disabled
                    options={exportData ? [exportData.type] : []}
                />
                <Configuration show={!!exportData}>
                    {exportData?.options?.map((option, index) => (
                        <OptionField
                            key={option.key}
                            control={control}
                            option={option}
                            index={index}
                        />
                    ))}
                </Configuration>
            </FormDrawer>
            <MutationSnackbars
                isSuccess={isSuccess}
                successText={t('exports.edit.success')}
                isError={isError}
                errorText={t('exports.edit.error')}
            />
        </>
    );
};
