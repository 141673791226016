import * as yup from 'yup';

const objectArray = yup
    .object({
        id: yup.string().required('required'),
        name: yup.string().required('required'),
        label: yup.string().required('required'),
    })
    .required('required');

export const connectionSchema = yup
    .object({
        tradingPartner: yup.string().required('required'),
        configs: yup.array().of(objectArray).required('required'),
        giveAccess: yup.mixed<'allProducts' | 'none'>(),
    })
    .required();

export type FormData = yup.InferType<typeof connectionSchema>;
