import { baseRtkApi } from '@xeris/reducers/baseApi';
import type { ApiDataError, ApiSourceData } from '@xeris/types/graphql';

import { productTransformers } from '../productTransformers';

import {
    getProductDataErrorsQuery,
    getProductQuery,
    getProductSourceDataQuery,
    getRelatedProductsQuery,
} from './productQueries';
import {
    type ApiGetProductDataErrorsQuery,
    type ApiGetProductDataErrorsQueryVariables,
    type ApiGetProductQuery,
    type ApiGetProductQueryVariables,
    type ApiGetProductSourceDataQuery,
    type ApiGetProductSourceDataQueryVariables,
    type ApiGetRelatedProductsQuery,
    type ApiGetRelatedProductsQueryVariables,
} from './productQueries.gql';

export const productApi = baseRtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getProduct: builder.query<
            ReturnType<typeof productTransformers.product> | null,
            ApiGetProductQueryVariables
        >({
            query: (variables) => ({
                document: getProductQuery,
                variables,
            }),
            transformResponse: (data: ApiGetProductQuery) =>
                productTransformers.product(data.product),
        }),
        getProductSourceData: builder.query<
            ApiSourceData[],
            ApiGetProductSourceDataQueryVariables
        >({
            query: (variables) => ({
                document: getProductSourceDataQuery,
                variables,
            }),
            transformResponse: (data: ApiGetProductSourceDataQuery) =>
                data.product?.source_data ?? [],
        }),
        getProductDataErrors: builder.query<
            (ApiDataError | null)[],
            ApiGetProductDataErrorsQueryVariables
        >({
            query: (variables) => ({
                document: getProductDataErrorsQuery,
                variables,
            }),
            transformResponse: (data: ApiGetProductDataErrorsQuery) =>
                data.product?.data_errors ?? [],
        }),
        getRelatedProducts: builder.query<
            NonNullable<
                ApiGetRelatedProductsQuery['product']
            >['relatedProducts'],
            ApiGetRelatedProductsQueryVariables
        >({
            query: (variables) => ({
                document: getRelatedProductsQuery,
                variables,
            }),
            transformResponse: (data: ApiGetRelatedProductsQuery) =>
                data.product?.relatedProducts ?? [],
        }),
    }),
});
