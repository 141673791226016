export const isUpdatedSinceLastExport = (dataset: {
    lastExported: string | null;
    lastProductUpdate: string | null;
}): boolean => {
    if (!dataset.lastExported || !dataset.lastProductUpdate) {
        return false;
    }

    const lastProductUpdate = new Date(dataset.lastProductUpdate);
    const lastExported = new Date(dataset.lastExported);

    return lastExported < lastProductUpdate;
};
