import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, type ButtonProps } from '@mui/material';

import CreateExportJob from '../forms/CreateExportJob';

type CreateExportButtonProps = ButtonProps & {
    datasetId?: string;
};

export const CreateExportButton = ({
    datasetId,
    ...buttonProps
}: CreateExportButtonProps): ReactElement => {
    const { t } = useTranslation('exports');

    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Button
                onClick={() => setIsOpen(true)}
                data-testid={`exportConfigurationButton:${datasetId}`}
                variant={'contained'}
                {...buttonProps}
            >
                {t('exportButton.export')}
            </Button>
            <CreateExportJob
                isOpen={isOpen}
                datasetId={datasetId}
                handleCloseDialog={() => setIsOpen(false)}
            />
        </>
    );
};
