export const marketSortFunction = (
    a: Record<'name', string>,
    b: Record<'name', string>
): 1 | -1 => {
    if (a.name.toLowerCase() === 'global') {
        return -1;
    } else if (b.name.toLowerCase() === 'global') {
        return 1;
    } else {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    }
};
