type DataType<
    T extends {
        id: string;
        isSelected: boolean;
    },
> = {
    primaryImageId: string | null;
    list: T[];
};

export const setSelectedStatusForAll = <
    T extends {
        id: string;
        isSelected: boolean;
    },
>(
    data: DataType<T>,
    newValue: boolean
): DataType<T> => {
    const newArray = data.list.map((d) => ({ ...d, isSelected: newValue }));

    // Selecting all images
    if (newValue) {
        if (!data.primaryImageId) {
            return {
                list: newArray,
                primaryImageId: newArray[0]?.id ?? null,
            };
        }

        return {
            list: newArray,
            primaryImageId: data.primaryImageId,
        };
    }

    // Deselecting all images
    return {
        list: newArray,
        primaryImageId: null,
    };
};

export const setSelectedStatusForOne = <
    T extends {
        id: string;
        isSelected: boolean;
    },
>(
    data: DataType<T>,
    id: string,
    newValue: boolean
): DataType<T> => {
    const newArray = data.list.map((d) => ({
        ...d,
        isSelected: d.id === id ? newValue : d.isSelected,
    }));

    // If there currently is no primary image, due to no images being selected, set this newly selected image as primary
    if (newValue && !data.primaryImageId) {
        return {
            list: newArray,
            primaryImageId: id,
        };
    }

    // If you are deselecting the primary image, select the first selected image as primary or null if none
    if (!newValue && id === data.primaryImageId) {
        const firstSelectedId = newArray.find((d) => d.isSelected)?.id ?? null;

        return {
            list: newArray,
            primaryImageId: firstSelectedId,
        };
    }

    // If none of the above, simply update the array of images with the selection
    return {
        list: newArray,
        primaryImageId: data.primaryImageId,
    };
};
