import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, type SxProps } from '@mui/material';

import { EditDatasetForm } from './EditDatasetForm';

type EditDatasetProps = {
    buttonText?: string;
    dataset: {
        id: string;
        name: string;
        description: string | null;
    };
    handleClose?: () => void;
    showButton?: boolean;
    datasetNames: string[];
    sx?: SxProps;
};

export const EditDataset = ({
    buttonText,
    dataset,
    showButton,
    sx,
    datasetNames,
    handleClose: handleExternalClose,
}: EditDatasetProps): ReactElement => {
    const { t } = useTranslation('dataset');

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleClose = (): void => {
        setIsDialogOpen(false);
        handleExternalClose && handleExternalClose();
    };

    return (
        <>
            {showButton && (
                <Button
                    variant="text"
                    color={'secondary'}
                    onClick={() => setIsDialogOpen(true)}
                    sx={sx}
                >
                    {buttonText ?? t('datasetPopUp.edit')}
                </Button>
            )}
            <EditDatasetForm
                key={dataset.id}
                dataset={dataset}
                datasetNames={datasetNames}
                isOpen={isDialogOpen}
                handleClose={handleClose}
            />
        </>
    );
};
