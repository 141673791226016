import { type ReactElement, type ReactNode } from 'react';

import { List } from '@mui/material';

type FilterProps = {
    children: ReactNode;
};

export const Filter = ({ children }: FilterProps): ReactElement => {
    return <List disablePadding>{children}</List>;
};
