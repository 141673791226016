import {
    createSelector,
    createSlice,
    type PayloadAction,
} from '@reduxjs/toolkit';

import type { StateType } from '@xeris/types';

type TableType = {
    [key: string]: boolean;
};

export type CollapseSliceType = {
    [key: string]: {
        table: TableType;
    };
};

export const initialState: CollapseSliceType = {};

const collapse = createSlice({
    name: 'collapse',
    initialState: initialState,
    reducers: {
        addPanelList(
            state,
            action: PayloadAction<{
                listId: string;
                panelIds: string[];
                collapsed: boolean;
            }>
        ) {
            const { listId, panelIds, collapsed } = action.payload;

            state[listId] = {
                table: Object.fromEntries(
                    panelIds?.map((panel) => [panel, collapsed]) ?? []
                ),
            };
        },
        togglePanel(
            state,
            action: PayloadAction<{ listId: string; panelId: string }>
        ) {
            const { listId, panelId } = action.payload;

            state[listId].table[panelId] = !state[listId].table[panelId];
        },
        toggleAllPanels(
            state,
            action: PayloadAction<{ listId: string; collapsed: boolean }>
        ) {
            const { listId, collapsed } = action.payload;

            Object.keys(state[listId].table).forEach((panelId) => {
                state[listId].table[panelId] = collapsed;
            });
        },
    },
});

export const { addPanelList, togglePanel, toggleAllPanels } = collapse.actions;
export default collapse.reducer;

const selectIsAnyPanelOpen = createSelector(
    (state: StateType, tableId: string) => state.collapse[tableId]?.table ?? {},
    (table) => {
        return Object.values(table).some((isCollapsed) => !isCollapsed);
    }
);

export const collapseSelectors = { selectIsAnyPanelOpen };
