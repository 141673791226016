import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import PlatformLanguage from './PlatformLanguage';
import Profile from './Profile';
import ResetPassword from './ResetPassword';

const Settings = (): ReactElement => {
    const { t } = useTranslation('user');
    return (
        <Box
            sx={{
                maxWidth: '600px',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
            }}
        >
            <header>
                <Typography variant={'h1'} gutterBottom>
                    {t('settings.myProfile')}
                </Typography>
                <Typography>{t('settings.viewAndUpdate')}</Typography>
            </header>
            <Profile />
            <PlatformLanguage />
            <ResetPassword />
        </Box>
    );
};

export default Settings;
