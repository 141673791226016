import {
    type AccessStatus,
    type SharedWithType,
    type SharingPrivilege,
} from '../types/datasetTypes';

type Dataset = {
    privilege: SharingPrivilege | null;
    sharedWith: SharedWithType[];
};

export const getAccessStatus = (dataset?: Dataset | null): AccessStatus => {
    if (!dataset) return 'PRIVATE';

    if (dataset.privilege) {
        return dataset.privilege;
    }
    return dataset.sharedWith.length > 0 ? 'SHARED' : 'PRIVATE';
};
