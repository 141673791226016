import { ecoSearchApi } from '../api';

/*
const useGetFilteredProductsQueryHook = (input: any): void => {
    const correctInput = transformInput(input)
    ecoSearchApi.useGetFilteredProductsQuery(correctInput)
}
*/

export const ecoSearchHooks = {
    api: {
        queries: {
            useGetSegmentsQuery: ecoSearchApi.useGetSegmentsQuery,
            useGetFiltersQuery: ecoSearchApi.useGetFiltersQuery,
            useGetFilteredProductsQuery:
                ecoSearchApi.useGetFilteredProductsQuery, //replace with useGetFilteredProductsQueryHook
        },
    },
};
