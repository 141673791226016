import { type ReactElement } from 'react';

import { Typography } from '@mui/material';

import { type GpcAttributeType } from '@xeris/pages/product/types';

import '../index.scss';

type GpcAttributesProps = {
    data: GpcAttributeType[];
};

const GpcAttributes = ({
    data: gpcAttributeList,
}: GpcAttributesProps): ReactElement => (
    <dl className="datasheet-list-col">
        {gpcAttributeList.map((gpcAttribute) => (
            <div key={gpcAttribute.key}>
                <dt>
                    <Typography variant={'label'}>
                        {gpcAttribute.key}
                    </Typography>
                </dt>
                <dd>
                    <Typography variant={'body2'}>
                        {gpcAttribute.value}
                    </Typography>
                </dd>
            </div>
        ))}
    </dl>
);

export default GpcAttributes;
