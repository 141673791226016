import {
    type ConnectionsTabVariantType,
    type UiConnectionType,
} from '@xeris/pages/admin/Connections/types/connectionsTypes';
import { type ApiOrganizationType } from '@xeris/types';

export const getConnectionType = (
    selectedConnectionsTab: ConnectionsTabVariantType,
    organizationType: ApiOrganizationType = 'PRODUCER'
): ConnectionsTabVariantType =>
    (
        ({
            PRODUCER_CONSUMER: selectedConnectionsTab,
            PRODUCER: 'channels',
            CONSUMER: 'feeds',
        }) as const
    )[organizationType];

export const getUiConnectionType = (
    selectedConnectionsTab: ConnectionsTabVariantType,
    organizationType: ApiOrganizationType = 'PRODUCER'
): UiConnectionType =>
    (
        ({
            PRODUCER_CONSUMER: selectedConnectionsTab,
            PRODUCER: 'connections',
            CONSUMER: 'connections',
        }) as const
    )[organizationType];
