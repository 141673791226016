import { isRejectedWithValue, type Middleware } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

export const errorLogger: Middleware = () => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        Sentry.withScope((scope) => {
            scope.setExtra('payload', action.payload);
            scope.setExtra('requestId', action.meta.requestId);
            scope.setExtra('arg', action.meta.arg);

            if (action.meta.arg && typeof action.meta.arg === 'object') {
                if (
                    'type' in action.meta.arg &&
                    typeof action.meta.arg.type === 'string'
                ) {
                    scope.setTag('Type', action.meta.arg.type);
                }

                if (
                    'endpointName' in action.meta.arg &&
                    typeof action.meta.arg.endpointName === 'string'
                ) {
                    scope.setTag('Endpoint Name', action.meta.arg.endpointName);
                }
            }

            const payloadMessage =
                action.payload &&
                typeof action.payload === 'object' &&
                'message' in action.payload &&
                typeof action.payload.message === 'string'
                    ? action.payload.message
                    : null;

            Sentry.captureException(
                payloadMessage ? new Error(payloadMessage) : action.error
            );
        });
    }

    return next(action);
};
