import { createSelector } from '@reduxjs/toolkit';

import { type StateType } from '@xeris/types';

const selectProducts = createSelector(
    (state: StateType) => state.export.categorization,
    (categorization) => categorization.computedProductsList
);
const selectCategorizedProducts = createSelector(
    (state: StateType) => state.export.categorization,
    (categorization) => categorization.computedCategorizedProductsList
);
const selectProductCategorization = createSelector(
    (state: StateType) => state.export.categorization.categorized,
    (state: StateType) => state.export.categorization.productsMap,
    (categorized, products) =>
        Object.keys(products).map((product) => ({
            product,
            categories: categorized[product] ?? [],
        }))
);
const selectDatasetName = createSelector(
    (state: StateType) => state.export.categorization.dataset,
    (dataset) => dataset?.name ?? ''
);
const selectConfigurationName = createSelector(
    (state: StateType) => state.export.categorization.exportConfiguration,
    (configuration) => configuration?.name ?? ''
);
const selectProductCounts = createSelector(
    (state: StateType) => state.export.categorization.dataset?.totalCount,
    (state: StateType) => state.export.categorization.categorized,
    (totalCount = 0, categorized) => {
        const categorizedCount = Object.keys(categorized).length;

        return {
            totalCount,
            categorizedCount,
            uncategorizedCount: totalCount - categorizedCount,
        };
    }
);
const selectShowAllProducts = createSelector(
    (state: StateType) => state.export.categorization,
    (categorization) => categorization.showAllProducts
);
export const categorizationSelectors = {
    selectProducts,
    selectDatasetName,
    selectConfigurationName,
    selectCategorizedProducts,
    selectProductCounts,
    selectProductCategorization,
    selectShowAllProducts,
};
