import { baseRtkApi } from '@xeris/reducers/baseApi';

import {
    addUserMutation,
    deleteUserMutation,
    getUsersQuery,
} from './userQueries';
import {
    type ApiAddUserMutation,
    type ApiAddUserMutationVariables,
    type ApiDeleteUserMutation,
    type ApiDeleteUserMutationVariables,
    type ApiGetUsersQuery,
    type ApiGetUsersQueryVariables,
} from './userQueries.gql';

export const userApi = baseRtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getUsers: builder.query<ApiGetUsersQuery, ApiGetUsersQueryVariables>({
            query: (variables) => ({
                document: getUsersQuery,
                variables,
            }),
            providesTags: ['users'],
        }),
        addUser: builder.mutation<
            ApiAddUserMutation,
            ApiAddUserMutationVariables
        >({
            query: (variables) => ({
                document: addUserMutation,
                variables,
            }),
            invalidatesTags: ['users'],
        }),
        deleteUser: builder.mutation<
            ApiDeleteUserMutation,
            ApiDeleteUserMutationVariables
        >({
            query: (variables) => ({
                document: deleteUserMutation,
                variables,
            }),
            invalidatesTags: ['users'],
        }),
    }),
});
