import { type ReactElement, type ReactNode } from 'react';

import { Box } from '@mui/material';

type InlineDialogWrapperProps = {
    children?: ReactNode;
    footer?: ReactNode;
};

export const InlineDialogWrapper = ({
    children,
    footer,
}: InlineDialogWrapperProps): ReactElement => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <Box sx={{ flexGrow: 1 }}>{children}</Box>
            <Box
                sx={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'background.default',
                    boxShadow: '0px -4px 8px rgba(29, 44, 72, 0.08);',
                }}
            >
                {footer}
            </Box>
        </Box>
    );
};
