import { type MutableRefObject, useCallback, useRef } from 'react';

export const useFocus = (): [
    MutableRefObject<HTMLElement | null>,
    () => void,
] => {
    const htmlElementRef = useRef<HTMLElement | null>(null);
    const setFocus = useCallback((): void => {
        htmlElementRef.current?.focus();
    }, [htmlElementRef]);

    return [htmlElementRef, setFocus];
};
