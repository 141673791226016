import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Stack, Typography } from '@mui/material';

type SectionHeaderProps = {
    selectedCount: number;
    totalCount: number;
    handleToggleAll: () => void;
    title: string;
};

const SectionHeader = ({
    selectedCount,
    totalCount,
    handleToggleAll,
    title,
}: SectionHeaderProps): ReactElement => {
    const { t } = useTranslation('product');

    return (
        <Stack direction={'row'} alignItems={'flex-end'} spacing={2}>
            <Checkbox
                indeterminate={selectedCount > 0 && selectedCount < totalCount}
                checked={selectedCount === totalCount}
                onClick={(event) => {
                    event.stopPropagation();
                    handleToggleAll();
                }}
                sx={{ padding: 0 }}
            />
            <Typography variant={'subtitle1'}>{title} </Typography>
            <Typography variant={'caption'}>
                {t('dataSelector.numberOfNumberSelected', {
                    numSelected: selectedCount,
                    total: totalCount,
                })}
            </Typography>
        </Stack>
    );
};

export default SectionHeader;
