import { type ReactElement } from 'react';

import { Typography } from '@mui/material';

import { type TextType } from '@xeris/pages/product/types';

type TextsProps = {
    data: TextType[];
};

const Texts = ({ data: textList }: TextsProps): ReactElement => {
    return (
        <>
            {textList.map((text) => (
                <div key={text.id}>
                    <Typography variant={'label'}>{text.name}</Typography>
                    <Typography variant={'body2'}>{text.text}</Typography>
                </div>
            ))}
        </>
    );
};

export default Texts;
