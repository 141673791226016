import { gql } from 'graphql-request';

const organizationFields = gql`
    fragment OrganizationFields on FullOrganization {
        id
        name
        type
        domains
        vatNumber: vat_number
        brands {
            id
        }
        feeds {
            id
        }
        channels {
            id
        }
        subscriptionPlan: subscription_plan {
            totalConnectionsCount: number_of_connections
            name
            subscriptionType: subscription_type
        }
    }
`;

export const getOrganizationsQuery = gql`
    query GetOrganizations {
        organizations: all_organizations {
            ...OrganizationFields
        }
    }
    ${organizationFields}
`;

export const getUserOrganizationQuery = gql`
    query GetUserOrganization($id: String) {
        organization(id: $id) {
            ...OrganizationFields
        }
    }
    ${organizationFields}
`;

export const addOrganizationQuery = gql`
    mutation AddOrganization(
        $id: String!
        $name: String!
        $type: OrganizationType!
        $domains: [String!]
        $vatNumber: String
    ) {
        organization {
            addOrganization: add_organization(
                organization: {
                    id: $id
                    name: $name
                    type: $type
                    domains: $domains
                    vat_number: $vatNumber
                }
            ) {
                ...OrganizationFields
            }
        }
    }
    ${organizationFields}
`;

export const editOrganizationQuery = gql`
    mutation EditOrganization(
        $id: String!
        $name: String!
        $type: OrganizationType!
        $domains: [String!]
        $vatNumber: String
    ) {
        organization {
            editOrganization: edit_organization(
                organization: {
                    id: $id
                    name: $name
                    type: $type
                    domains: $domains
                    vat_number: $vatNumber
                }
            ) {
                ...OrganizationFields
            }
        }
    }
    ${organizationFields}
`;

export const deleteOrganizationQuery = gql`
    mutation DeleteOrganization($id: String!) {
        organization {
            deleteOrganization: delete_organization(id: $id) {
                id
                deleted
            }
        }
    }
`;
