import type { ConfigurationExportCategory, FlatCategory } from '../../types';

export const getFlatCategories = (
    categories: ConfigurationExportCategory[],
    index = 0,
    level = 0,
    path = ''
): FlatCategory[] => {
    let currentIndex = index;

    return categories.flatMap((category) => {
        const { children } = category;
        const newPath = `${path}//b_${currentIndex}`;

        if (!children || children.length === 0) {
            currentIndex += 1;

            return {
                ...category,
                indentationLevel: level,
                index: currentIndex - 1,
                path: newPath,
                hasChildren: false,
            };
        }

        const flatChildren = getFlatCategories(
            children,
            currentIndex + 1,
            level + 1,
            newPath
        );

        currentIndex += flatChildren.length;

        return [
            {
                ...category,
                indentationLevel: level,
                index: currentIndex - flatChildren.length,
                path: newPath,
                hasChildren: true,
            },
            ...flatChildren,
        ];
    });
};
