import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import type { TFunction } from 'i18next';

import { type TabProviderTabType, TabsProvider } from '@xeris/components';
import { useQueryParams } from '@xeris/hooks';

import Brands from './Brands/Brands';
import Search from './Search/Search';

const getTabs = (t: TFunction<'products'>): TabProviderTabType[] => [
    {
        id: 'brands',
        Label: <span>{t('common.findByBrands')}</span>,
        Component: <Brands />,
    },
    {
        id: 'search',
        Label: <span>{t('common.findBySearch')}</span>,
        Component: <Search />,
    },
];

const Tabs = (): ReactElement => {
    const { t } = useTranslation('products');
    const activeTabId = useQueryParams('tab');
    const navigate = useNavigate();

    return (
        <TabsProvider
            activeTabId={activeTabId ?? 'brands'}
            tabList={getTabs(t)}
            handleChangeTabId={(newActiveTabId): void => {
                navigate(`/Products?tab=${newActiveTabId}`);
            }}
        ></TabsProvider>
    );
};

export default Tabs;
