import * as yup from 'yup';

import { supportedLanguages } from '@xeris/i18n';

const supportedLanguageCodes = supportedLanguages.map(({ code }) => code);

export const inviteUserSchema = yup
    .object({
        username: yup.string().email().required('required'),
        securityLevel: yup
            .string()
            .oneOf(['ADMIN', 'USER'])
            .required('required'),
        invitationLanguage: yup
            .string()
            .oneOf(supportedLanguageCodes)
            .required('required'),
        message: yup.string(),
    })
    .required();

export type InviteUserFormData = yup.InferType<typeof inviteUserSchema>;
