type UserWithOrganizationId = {
    organizationId: string;
};

/**
 * Check if the users array have users belonging to a different organization
 */
export const hasOutsideUsers = (
    users?: (Partial<UserWithOrganizationId> | undefined)[],
    organisationId?: string | null
): boolean => {
    if (!organisationId) return false;

    if (!users) return false;

    return users
        .filter(
            (user): user is UserWithOrganizationId => !!user?.organizationId
        )
        .some((user) => user.organizationId !== organisationId);
};
