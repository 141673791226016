import { type ReactElement } from 'react';
import {
    type Control,
    type FieldValues,
    type Path,
    useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectInput } from '@xeris/components/forms';
import { useActiveOrganizationId } from '@xeris/pages/admin/hooks';

import { hasOutsideUsers } from './utilities/hasOutsideUsers';
import type { ShareDatasetFormType } from './schema';

type RoleFieldProps<T extends FieldValues> = {
    fieldName: Path<T>;
    label?: string;
    control: Control<T>;
};

const RoleField = ({
    fieldName,
    label,
    control,
}: RoleFieldProps<ShareDatasetFormType>): ReactElement => {
    const { t } = useTranslation('dataset');

    const activeOrganizationId = useActiveOrganizationId();

    const selectedUsers = useWatch({ control, name: 'users' });

    const hasUsersOutsideOrganization = hasOutsideUsers(
        selectedUsers,
        activeOrganizationId
    );

    const roleOptions = [
        {
            id: 'READ',
            label: t('datasetSharingPopUp.view.header'),
        },
        {
            id: 'WRITE',
            label: t('datasetSharingPopUp.edit.header'),
            disabled: hasUsersOutsideOrganization,
        },
    ];

    return (
        <SelectInput
            label={label}
            fieldName={fieldName}
            control={control}
            size={'small'}
            options={roleOptions}
        />
    );
};

export default RoleField;
