import { type ReactElement, type ReactNode } from 'react';

import { Box } from '@mui/material';

type FeatureOptionsProps = {
    children?: ReactNode;
};

export const BasicFeatureOptionList = ({
    children,
}: FeatureOptionsProps): ReactElement => {
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))',
                    gridGap: '1rem',
                    width: '100%',
                }}
            >
                {children}
            </Box>
        </Box>
    );
};
