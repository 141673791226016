import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Skeleton, Switch } from '@mui/material';

import { Typography } from '@xeris/components';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';

import { categorizationSelectors } from '../reducers/categorizationSelectors';
import { toggleShowAllProducts } from '../reducers/categorizationSlice';

type HeaderProps = {
    isFetching: boolean;
};

const Header = ({ isFetching }: HeaderProps): ReactElement => {
    const { t } = useTranslation('dataset');
    const dispatch = useAppDispatch();
    const datasetName = useAppSelector(
        categorizationSelectors.selectDatasetName
    );
    const showAllProducts = useAppSelector(
        categorizationSelectors.selectShowAllProducts
    );
    const { uncategorizedCount } = useAppSelector(
        categorizationSelectors.selectProductCounts
    );

    if (isFetching) {
        return <Skeleton height={28} width={400} />;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <Typography variant={'h2'} sx={{ lineHeight: '38px' }}>
                {`${datasetName} (${uncategorizedCount})`}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Switch
                    value={showAllProducts}
                    onClick={(): void => {
                        dispatch(toggleShowAllProducts());
                    }}
                />
                <Typography>{t('categorization.showCategorized')}</Typography>
            </Box>
        </Box>
    );
};

export default Header;
