import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { type Locale, setDefaultOptions } from 'date-fns';
import { de, enGB } from 'date-fns/locale';

import { userApi } from '@xeris/api/user/userApi';
import { loginSelectors } from '@xeris/pages/login/reducers';
import { useAppSelector } from '@xeris/reducers';

const locales: { [key: string]: Locale } = {
    en: enGB,
    de: de,
};

export const useChangeLanguage = (): ((
    language: string | undefined
) => void) => {
    const { i18n } = useTranslation();
    const [updateUserLanguageMutation] =
        userApi.useUpdateUserLanguageMutation();
    const isLoggedIn = useAppSelector(loginSelectors.selectIsLoggedIn);

    return useCallback(
        (language: string | undefined): void => {
            if (language) {
                // Set language locale
                if (language in locales) {
                    setDefaultOptions({ locale: locales[language] });
                }

                // Set translations locale
                if (i18n.language !== language) {
                    i18n.changeLanguage(language, (err) => {
                        if (err) console.error(err);
                    });
                    if (isLoggedIn) {
                        updateUserLanguageMutation({ language });
                    }
                }
            }
        },
        [i18n, isLoggedIn, updateUserLanguageMutation]
    );
};
