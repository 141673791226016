import { type ReactElement, type ReactNode } from 'react';

import { Box } from '@mui/material';

type BasicFeatureSummaryProps = {
    children?: ReactNode;
};

export const BasicFeatureSummary = ({
    children,
}: BasicFeatureSummaryProps): ReactElement | null => {
    return <Box sx={{ marginBottom: 4, width: '100%' }}>{children}</Box>;
};
