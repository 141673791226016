import { type ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';

import { OrganizationList } from './OrganizationList/OrganizationList';

const Organization = (): ReactElement => {
    return (
        <Routes>
            <Route path={'*'} element={<OrganizationList />} />
        </Routes>
    );
};

export default Organization;
